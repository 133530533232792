import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import useStyles from './TouristDetailsDialog.styles';
import {
    ADDRESS, CITIZENSHIP, DATE_OF_BIRTH_FIELD, DATE_TIME_OF_DEPARTURE,
    NE, TOURIST_NOT_CHECKED_OUT, FACILITY_ID, GENDER_FIELD, KONTAKT, NOTE,
    STAY_FROM_FIELD, TOURIST_CHECKOUT_OUT_STRING, TOURIST_SURNAME_NAME, id, RECORDS,
    TRAVEL_DOCUMENT_TYPE, ID, QR_TOURIST_DATA_SUBTITLE, QR_TOURIST_DATA_TITLE,
    DATE_OF_BIRTH_LABEL, QR_TOURIST, QR_GENDER, QR_NATIONATLITY, QR_CONTACT, QR_ADDRESS,
    QR_DOCUMENT, QR_ACCOMODATION_INFO, QR_OBJECT, QR_STAY_FROM, QR_FORSEEN_STAY_TO,
    QR_STAY_TO, QR_CATEGORY, QR_ARRIVAL_ORG, QR_TYPE_OF_SERVICE, EVISITOR_GUEST_DETAILS_URL
} from '../../constants/strings-and-fields';
import QRCode from 'qrcode.react';
import { getDateFromAspNetFormatToFormatted } from '../../helpers/DateHelper';
import { getTouristObject, getUrlForQrByID, isJSON } from '../../helpers/Utils';
import { useTranslation } from 'react-i18next';
import { fetchTourist } from '../../api/incheckinservice';

const TouristDetailsDialog = ({ evaccounts, tourist, eVisitorPinId, open, setOpen }) => {
    const classes = useStyles();
    const [guest, setGuest] = useState(null);
    const { t } = useTranslation();
    const close = () => {
        setOpen(false);
    }

    useEffect(() => {
        // when used from Checkin view
        if (open && tourist && !isJSON(tourist) && eVisitorPinId) {
            const executeComand = async () => {
                const touristRes = await fetchTourist(eVisitorPinId, 1, 1, tourist[id]);
                if (touristRes) {
                    const touristData = touristRes[RECORDS][0];
                    console.log(touristData);
                    setGuest(touristData);
                }
            }
            executeComand();
        };
    }, [tourist, open]); // eslint-disable-line react-hooks/exhaustive-deps

    let touristMap = {};
    if (guest) {
        touristMap = guest;
    } else {
        // when used from Tourist view
        touristMap = (tourist && isJSON(tourist) && JSON.parse(tourist)) ?? {};
    }

    const touristObject = getTouristObject(eVisitorPinId, touristMap[FACILITY_ID], evaccounts);
    const dateOfBirth = touristMap?.[DATE_OF_BIRTH_FIELD]?.split(' ')[0] ?? "";
    const stayFromTime = getDateFromAspNetFormatToFormatted(touristMap?.[STAY_FROM_FIELD]) ?? "";
    const departureTime = getDateFromAspNetFormatToFormatted(touristMap?.[DATE_TIME_OF_DEPARTURE]) ?? "";
    const forseenDeparture = (touristMap?.[TOURIST_CHECKOUT_OUT_STRING] === NE) ? TOURIST_NOT_CHECKED_OUT : (getDateFromAspNetFormatToFormatted(touristMap?.[DATE_TIME_OF_DEPARTURE] ?? ""));
    const touristEvisitorId = `${EVISITOR_GUEST_DETAILS_URL}${getUrlForQrByID(touristMap?.[ID] ?? "")}`;

    return <Dialog
        open={open}
        onClose={close}
        aria-labelledby="details-dialog"
    >

        <DialogContent>
            <div className={classes.root}>
                <div id="inner-content" className={classes.contentFrame}>
                    <h1>{t(QR_TOURIST_DATA_TITLE)}</h1>
                    <h2>{t(QR_TOURIST_DATA_SUBTITLE)}</h2>
                    <dl className={classes.dlHorizontal}>
                        <dt>
                            {t(QR_TOURIST)}:
                        </dt>

                        <dd>
                            {touristMap[TOURIST_SURNAME_NAME]}
                        </dd>

                        <dt>
                            {t(QR_GENDER)}:
                        </dt>

                        <dd>
                            {touristMap[GENDER_FIELD]}
                        </dd>

                        <dt>
                            {t(DATE_OF_BIRTH_LABEL)}:
                        </dt>

                        <dd>
                            {touristMap[dateOfBirth]}
                        </dd>

                        <dt>
                            {t(QR_NATIONATLITY)}:
                        </dt>

                        <dd>
                            {touristMap[CITIZENSHIP]}
                        </dd>

                        <dt>
                            {t(QR_CONTACT)}
                        </dt>

                        <dd>
                            {touristMap[KONTAKT] ?? ''}
                        </dd>

                        <dt>
                            {t(QR_ADDRESS)}
                        </dt>

                        <dd>
                            {touristMap[ADDRESS]}
                        </dd>

                        <dt>
                            {t(QR_DOCUMENT)}
                        </dt>

                        <dd>
                            {touristMap[TRAVEL_DOCUMENT_TYPE]}
                        </dd>
                    </dl>
                    <h2>{t(QR_ACCOMODATION_INFO)}</h2>
                    <dl className={classes.dlHorizontal}>
                        <dt>
                            {t(QR_OBJECT)}:
                        </dt>

                        <dd>
                            {touristObject}
                        </dd>

                        <dt>
                            {t(QR_STAY_FROM)}:
                        </dt>

                        <dd>
                            {stayFromTime}
                        </dd>

                        <dt>
                            {t(QR_FORSEEN_STAY_TO)}:
                        </dt>

                        <dd>
                            {departureTime}
                        </dd>

                        <dt>
                            {t(QR_STAY_TO)}:
                        </dt>

                        <dd>
                            {forseenDeparture}
                        </dd>

                        <dt>
                            {t(QR_CATEGORY)}:
                        </dt>

                        <dd>
                            {touristMap[NOTE]}
                        </dd>

                        <dt>
                            {t(QR_ARRIVAL_ORG)}:
                        </dt>

                        <dd>
                            {touristMap["Org dolaska:"] ?? ''}
                        </dd>

                        <dt>
                            {t(QR_TYPE_OF_SERVICE)}:
                        </dt>

                        <dd>
                            {touristMap["Vrsta usluge:"] ?? ''}
                        </dd>
                    </dl>
                    <QRCode className={classes.qrcode} level="M" value={touristEvisitorId} />
                </div>
            </div>
        </DialogContent>
    </Dialog>
}


export default TouristDetailsDialog;