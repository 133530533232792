import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {
    PRIVOLA_TITLE, TERMS, PRIVACY_POLICY, PRIVOLA_2,
    PRIVOLA_3, PRIVOLA_4, PRIVOLA_5, AGREE, AND, INCHECKIN_TERMS_URL,
    INCHECKIN_PRIVACY_URL,
    PRIVOLA_CONTENT_TITLE
} from '../../constants/strings-and-fields';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import useStyles from './ConfirmWithCheckboxConsent.styles';
import { DialogTitle, Divider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const ConfirmWithCheckboxConsent = ({ children, consentAccepted }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [state, setState] = useState({
        privolaOne: false,
        privolaTwo: false,
        privolaThree: false,
        privolaFour: false,
        privolaFive: false,
    });
    const [confirmPrivolaOpen, setConfirmPrivolaOpen] = useState(false);

    const { privolaOne, privolaTwo, privolaThree, privolaFour, privolaFive } = state;
    const error = [privolaOne, privolaTwo, privolaThree, privolaFour, privolaFive].filter((v) => v).length !== 5;

    const handleChange = (event) => {
        const checked = event.target.checked;
        const privola = event.target.name;
        if (privola === "privolaOne") {
            if (checked) {
                setState({ ...state, [privola]: checked });
                setConfirmPrivolaOpen(true);
            } else {
                setState({ privolaTwo: false, privolaThree: false, privolaFour: false, privolaFive: false });
            }
        } else {
            setState({ ...state, [privola]: checked });
        }
    };

    const onRejectPrivola = () => {
        setState({ ...state, privolaOne: false });
    }

    const closeAndReject = () => {
        setConfirmPrivolaOpen(false);
        onRejectPrivola();
    }

    const closeAndAccept = () => {
        setConfirmPrivolaOpen(false);
        consentAccepted(true);
    }

    useEffect(() => {
        const { privolaOne, privolaTwo, privolaThree, privolaFour, privolaFive } = state;
        const consentNotAccepted = [privolaOne, privolaTwo, privolaThree, privolaFour, privolaFive].filter((v) => v).length !== 5;
        // eslint-disable-next-line no-unused-vars
        const _ = (consentNotAccepted) ? consentAccepted(false) : closeAndAccept();
    }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

    return <>
        <FormControl component="fieldset" className={classes.formControl} error={error}>
            <FormLabel component="legend">{t(PRIVOLA_TITLE)}</FormLabel>
            <FormGroup className={classes.formControlFirstLabel}>
                <FormControlLabel
                    control={<Checkbox checked={privolaOne} onChange={handleChange} name={"privolaOne"} />}
                    label={
                        <div>
                            <span>{t(AGREE)} </span>
                            <a href={t(INCHECKIN_TERMS_URL)}>{t(TERMS)}</a>
                            <span> {t(AND)} </span>
                            <a href={t(INCHECKIN_PRIVACY_URL)}>{t(PRIVACY_POLICY)}</a>
                        </div>
                    }
                />
            </FormGroup>
        </FormControl>

        <Dialog
            open={confirmPrivolaOpen}
            onClose={closeAndReject}
            aria-labelledby="confirm-dialog">
            <DialogTitle id="confirm-dialog-title">{t(PRIVOLA_CONTENT_TITLE)}</DialogTitle>
            <DialogContent>
                <div>
                    <FormControl component="fieldset" error={error}>
                        <FormLabel component="legend">{t(PRIVOLA_TITLE)}</FormLabel>
                        <FormGroup className={classes.formControlFirstLabelDialog}>
                            <Divider />
                            <FormControlLabel
                                control={<Checkbox checked={privolaTwo} onChange={handleChange} name={"privolaTwo"} />}
                                label={t(PRIVOLA_2)}
                            />
                            <Divider />
                            <FormControlLabel
                                control={<Checkbox checked={privolaThree} onChange={handleChange} name={"privolaThree"} />}
                                label={t(PRIVOLA_3)}
                            />
                            <Divider />
                            <FormControlLabel
                                control={<Checkbox checked={privolaFour} onChange={handleChange} name={"privolaFour"} />}
                                label={t(PRIVOLA_4)}
                            />
                            <Divider />
                            <FormControlLabel
                                control={<Checkbox checked={privolaFive} onChange={handleChange} name={"privolaFive"} />}
                                label={t(PRIVOLA_5)}
                            />
                            <Divider />
                        </FormGroup>
                    </FormControl>
                    <Typography gutterBottom variant="body2" className={classes.formText}> {children} </Typography>
                </div>
            </DialogContent>
        </Dialog>
    </>
}

export default ConfirmWithCheckboxConsent;