import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ADD_EVISITOR_DIALOG_TITLE, EVISITOR_CONNECT, EVISITOR_PASS, EVISITOR_USERNAME } from '../../constants/strings-and-fields';
import { useTranslation } from 'react-i18next';

const AddEvisitorDialog = (props) => {
    const { open, setOpen, onConnect } = props;
    const { t } = useTranslation();
    const [user, setUser] = useState();
    const [password, setPassword] = useState();
    const [disableConfirm, setDisableConfirm] = useState(false);

    const handleClose = () => {
        setOpen(false);
        setUser();
        setPassword();
    };

    const onConfirmClick = () => {
        onConnect({ pin: user, pin_password: password });
    }

    useEffect(() => {
        if (user && user.length > 1 && password && password.length > 1) {
            setDisableConfirm(false);
        } else {
            setDisableConfirm(true);
        }
    }, [user, password]);

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{t(ADD_EVISITOR_DIALOG_TITLE)}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label={t(EVISITOR_USERNAME)}
                        type="text"
                        fullWidth
                        onChange={e => setUser(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="password"
                        label={t(EVISITOR_PASS)}
                        type="password"
                        fullWidth
                        onChange={e => setPassword(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onConfirmClick} color="primary" disabled={disableConfirm}>
                        {t(EVISITOR_CONNECT)}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AddEvisitorDialog;