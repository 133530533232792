import axios from 'axios';
import { STORAGE_COMM_KEY, STORAGE_REFRESH_TOKEN, STORAGE_USER, STORAGE_USER_ACCESS_TOKEN } from '../constants/strings-and-fields';
import { getAppAccessToken, getUserAccessToken, getRefreshToken, checkAppToken } from '../helpers/Utils';

//export const BASE_URL = 'http://localhost:4446';
export const BASE_URL = process.env.REACT_APP_BASE_URL ?? '';
export const API_VER = process.env.REACT_APP_API_VER ?? 'v2';
export const JWT_SECRET = process.env.REACT_APP_JWT_SECRET ?? 'super-secret-development';
export const ICI_NAME = process.env.REACT_APP_ICI_NAME ?? 'inCheckIn-development';

const XDEVICE = 'self-checkin-device';
const LOGIN_ROUTE = '/auth';

const EVISITOR_USER_ROUTE = '/users/me/evisitor';
const USER_ROUTES = [EVISITOR_USER_ROUTE, '/checkin/all', '/guest/', '/guest/all', '/checkin/', '/AccommodationUnitFacilityType', '/FacilityTouristCheckInLookup'];
const APP_AUTH_ROUTES = ['/users', '/auth', '/all-settlements', '/TTPaymentCategoryLookup', '/guest'];
const REFRESH_ROUTE = '/auth/tokens/refresh';

const restClient = axios.create({
    baseURL: BASE_URL,
});

const refreshAuthLogic = failedRequest =>
    restClient.post(`${BASE_URL}/${API_VER}/auth/tokens/refresh`).then(res => {
        localStorage.setItem(STORAGE_REFRESH_TOKEN, res?.refresh_token);
        localStorage.setItem(STORAGE_USER_ACCESS_TOKEN, res?.access_token);
        failedRequest.response.config.headers['Authorization'] = `JWT ${res?.access_token}`;
        console.log('refreshAuthLogic Authorization header after refresh:');
        console.log(failedRequest.response.config.headers['Authorization']);
        return Promise.resolve();
    })

const isUserRoute = (reqUrl, reqMethod) => {
    // single checkin route
    if (reqUrl.includes('/checkin/') && reqMethod === 'get' && !reqUrl.endsWith('all')) return false; 
    for (let url of USER_ROUTES) {
        if (reqUrl.includes(url)) return true;
    }
    return false;
}

const isAppRoute = (reqUrl, reqMethod) => {
    // single checkin route
    if (reqUrl.includes('/checkin/') && reqMethod === 'get' ) return true;
    for (let url of APP_AUTH_ROUTES) {
        if (reqUrl.endsWith(url)) return true
        if (reqUrl.includes(url) &&
            !reqUrl.endsWith('all') &&
            (['get', 'put'].includes(reqMethod))) {
            return true
        }
    }
    return false;
}

const isRefreshCall = (reqUrl) => reqUrl.includes(REFRESH_ROUTE);

const resolveToken = (reqUrl, reqMethod) => {
    if (isRefreshCall(reqUrl)) return getRefreshToken();
    if (isUserRoute(reqUrl, reqMethod)) return getUserAccessToken();
    if (isAppRoute(reqUrl, reqMethod)) return getAppAccessToken();
    return getUserAccessToken();
}

restClient.interceptors.request.use(async (request) => {
    console.log(`${request.method} ${request.url}`);
    await checkAppToken();
    request.headers['Authorization'] = `JWT ${resolveToken(request.url, request.method)}`;
    const userToken = getUserAccessToken();
    if (userToken) {
        request.headers['X-Authorization'] = `JWT ${userToken}`;
    }
    request.headers['X-Device'] = XDEVICE;
    return request;
});

restClient.interceptors.response.use(
    resp => resp.data,

    async error => {
        /* refresh token and retry request once more on 401
           else log user out
        */
        const { config: originalReq, response } = error;
        console.log('interceptors.response error');
        console.log(error);

        // skip refresh token request, retry attempts to avoid infinite loops
        if (!originalReq.url.endsWith(LOGIN_ROUTE) && !originalReq.url.endsWith(EVISITOR_USER_ROUTE) && !originalReq.url.includes(`/${API_VER}/auth/tokens/refresh`) && !originalReq.isRetryAttempt && response && response.status === 401) {
            try {
                await refreshToken();
                originalReq.isRetryAttempt = true;
                originalReq.headers['Authorization'] = restClient.defaults.headers.common['Authorization'];
                console.log('Retrying request');
                return await restClient.request(originalReq);
            } catch (e) {
                // log user out if fail to refresh (due to expired or missing token) or persistent 401 errors from original requests
                if (e === 'user has not logged in' || (e.response && e.response.status === 401)) {
                    console.log('User has not logged in');
                }
                // suppress original error to throw the new one to get new information
                throw e
            }
        } else {
            throw error
        }
    }
)

async function refreshToken() {
    console.log('refreshToken call');
    const refreshToken = getUserAccessToken();

    if (!refreshToken) {
        throw new Error('user has not logged in');
    }

    // use private variable to keep 1 active JWT refresh request at any time.
    this.refreshPromise = this.refreshPromise || refreshAuthLogic;

    // get new access token
    try {
        await this.refreshPromise;
    } finally {
        this.refreshPromise = null;
    }
}

export const storeResponseData = (res, user = null) => {
    if (res) {
        localStorage.setItem(STORAGE_REFRESH_TOKEN, res?.refresh_token);
        localStorage.setItem(STORAGE_USER_ACCESS_TOKEN, res?.access_token);
        localStorage.setItem(STORAGE_COMM_KEY, res?.comm_key);
    }
    if (user) {
        localStorage.setItem(STORAGE_USER, user?.email);
    }
}

export default restClient;