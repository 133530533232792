import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import HomeIcon from '@material-ui/icons/Home';
import HotelIcon from '@material-ui/icons/Hotel';
import PeopleIcon from '@material-ui/icons/People';
import useStyles from './CheckinItem.styles';
import Typography from '@material-ui/core/Typography';
import CalendarIcon from '../components/CalendarIcon';
import Box from '@material-ui/core/Box';
import { getDate } from './../../helpers/DateHelper';
import {
    ADDITIONAL_FIELDS, EVISITOR, NAME_CODE, id,
    FACILITY_FIELD, NAME,
} from '../../constants/strings-and-fields';
import { useTranslation } from 'react-i18next';

const CheckinItem = props => {
    const { checkin, numberOfGuests = 0, history } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const stayFromDate = getDate(checkin.StayFrom);
    const stayToDate = getDate(checkin.ForeseenStayUntil);

    const handleClick = (id) => history.push(`/checkin/${id}`);

    return (
        <List
            component="nav"
            className={classes.root}
            key={checkin[id]}>
            <ListItem button onClick={() => handleClick(checkin[id])}>
                <div style={{ width: '100%' }}>
                    <Box display="flex" flexDirection="row" >
                        <Box flexGrow={3} >
                            <Typography gutterBottom variant="body2" color="textSecondary">
                                {checkin?.[ADDITIONAL_FIELDS]?.[EVISITOR]?.[NAME]}
                            </Typography>
                            <Typography gutterBottom variant="body2">
                                <HomeIcon className={classes.icon} /> {checkin?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD]?.[NAME]}
                            </Typography>
                            <Typography gutterBottom variant="body2" color="textSecondary">
                                <HotelIcon className={classes.icon} /> {checkin?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD]?.[NAME_CODE]}
                            </Typography>
                        </Box>
                        <Box display="flex" flexDirection="column">
                            <Box flexGrow={2} >
                                <CalendarIcon size="small" secondary={true} date={stayFromDate} />
                            </Box>
                            <Box>
                                <Typography gutterBottom variant="body2" color="textSecondary">
                                    
                                </Typography>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="column" justifyContent="space-between">
                            <Box flexGrow={2} >
                                <CalendarIcon size="big" date={stayToDate} />
                            </Box>
                            <Box >
                                <Typography gutterBottom variant="body2" color="textSecondary">
                                     {numberOfGuests} <PeopleIcon className={classes.icon} />
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </div>
            </ListItem>
        </List>
    );
}

export default CheckinItem;