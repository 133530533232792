import React, { useEffect, useState } from 'react';
import useStyles from './Tourists.styles';
import { useWindowDimensions } from '../../wrappers/withWindowDimensions';
import { VariableSizeList as List } from 'react-window';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import {
    CHECKOUT_OUT, DATE_TIME_OF_ARRIVAL, DATE_TIME_OF_DEPARTURE,
    DOWNLOAD_IN_PROGRESS, FACILITY_NAME, ID, PIN_ID, RECORDS,
    TOURIST_SURNAME_NAME
} from '../../../constants/strings-and-fields';
import CustomizedSnackbar from '../../components/Snackbar';
import { isLoggedIn } from '../../../helpers/Utils';
import { getDateFromAspNetFormat } from '../../../helpers/DateHelper';
import TouristItem from '../../lists/TouristItem';
import PrimaryListSearchNavBar from '../../navbars/PrimaryListSearchNavBar';
import EVChooserDialog from '../../components/EVChooserDialog';
import { fetchTourists } from '../../../api/incheckinservice';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TouristDetailsDialog from '../../components/TouristDetailsDialog';
import { useTranslation } from 'react-i18next';

const TOOLBAR_HEIGHT = 56;
const API_MESSAGE_TIMEOUT = 5000;
const TOURIST_PAGE_COUNT = 40;

const Tourists = props => {
    const classes = useStyles();
    const { history, location, evaccounts, apiErrors, apiMessages } = props;
    const { t } = useTranslation();
    const [tourists, setTourists] = useState([]);
    const { windowHeight, windowWidth } = useWindowDimensions();
    const [openMessage, setOpenMessage] = useState(false);
    const [openChooser, setOpenChooser] = useState(false);
    const [openDetails, setOpenDetails] = useState(false);
    const [tourist, setTourist] = useState();
    const [searchText, setSearchText] = useState();
    const [evAccount, setEvAccount] = useState('');
    const [openProgress, setOpenProgress] = useState(false);
    const listWindow = windowHeight - TOOLBAR_HEIGHT;
    const listRef = React.createRef();
    let unlisten = null;

    const checkinId = location.checkinId;

    const getSize = i => 90;

    const generateMessage = () => {
        if (apiErrors) {
            return `${apiErrors?.signal}: ${apiErrors?.message}`
        }
        else if (apiMessages) {
            return `${apiMessages?.signal}: ${apiMessages?.message}`
        };
    }

    const handleToggle = () => {
        setOpenProgress(!openProgress);
    };

    const triggerSearch = (text, evAccount) => {
        setSearchText(text);
        if (evAccount[PIN_ID]) {
            onChoosedEVAccountSearch(evAccount, text).then(_ => { });
        } else {
            // deprecated because of select
            setOpenChooser(true);
        }
    }

    const populateTourists = (fetchedTourists) => {
        const touristsRecords = fetchedTourists[RECORDS];
        const touristList = [];
        for (let tourist of touristsRecords) {
            const name = tourist[TOURIST_SURNAME_NAME];
            const arrival = getDateFromAspNetFormat(tourist[DATE_TIME_OF_ARRIVAL]);
            const departure = getDateFromAspNetFormat(tourist[DATE_TIME_OF_DEPARTURE]);
            const isCheckedOut = tourist[CHECKOUT_OUT];
            const facility = tourist[FACILITY_NAME];
            const id = tourist[ID];
            touristList.push({ id, name, arrival, departure, isCheckedOut, facility, ...tourist })
        }
        touristList.sort((a, b) => b.arrival - a.arrival);
        setTourists(touristList);
    }

    const onChoosedEVAccount = async (evAccount) => onChoosedEVAccountSearch(evAccount, searchText);

    const onChoosedEVAccountSearch = async (evAccount, search) => {
        const pinId = evAccount[PIN_ID];
        try {
            setOpenProgress(true);
            const fetchedTourists = await fetchTourists(pinId, TOURIST_PAGE_COUNT, 1, search);
            if (fetchedTourists) {
                populateTourists(fetchedTourists);
            } else {
                setTourists([]);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setOpenProgress(false);
        }
    }

    const showDetails = (tourist) => {
        const touristPrint = JSON.stringify(tourist, null, 2);
        setTourist(touristPrint);
        setOpenDetails(true);
    }

    useEffect(() => {
        isLoggedIn().then(loggedIn => {
            if (!loggedIn) history.replace('/login');
        })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        unlisten = history.listen((location, action) => {
            if (action === 'POP') {
                history.replace('/');
            }
        });
        return () => { unlisten(); };
    });

    useEffect(() => {
        apiErrors && setOpenMessage(true);
        apiMessages && setOpenMessage(true);
    }, [apiMessages, apiErrors]);

    useEffect(() => {
        const eVisitorPinId = location.eVisitorPinId;
        if (eVisitorPinId && evaccounts) {
            const foundEvAccount = evaccounts.find(evAccount => evAccount[PIN_ID] === eVisitorPinId);
            setEvAccount(foundEvAccount);
        } else {
            setEvAccount(evaccounts[0])
        }
    }, [evaccounts]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const fetchInit = async () => {
            if (evAccount) {
                const fetchedTourists = await fetchTourists(evAccount[PIN_ID], TOURIST_PAGE_COUNT, 1, null);
                if (fetchedTourists) {
                    populateTourists(fetchedTourists);
                }
            }
        }
        fetchInit();
    }, [evAccount]);

    if (evaccounts) {
        return (
            <>
                <PrimaryListSearchNavBar {...props} evaccounts={evaccounts} setEvAccount={setEvAccount} evAccount={evAccount} triggerSearch={triggerSearch} />
                <div className={classes.root}>
                    <List className="List"
                        ref={listRef}
                        height={listWindow}
                        itemCount={tourists.length}
                        itemSize={getSize}
                        width={windowWidth}>
                        {({ index, style }) =>
                            <div style={style}>
                                <Divider />
                                <TouristItem
                                    tourist={tourists[index]}
                                    checkinId={checkinId}
                                    touristIndex={index}
                                    history={history}
                                    showDetails={showDetails}
                                />
                                <Divider />
                            </div>
                        }
                    </List>
                    <Backdrop className={classes.backdrop} open={openProgress} onClick={handleToggle}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <CustomizedSnackbar message={generateMessage()} isError={apiErrors} open={openMessage} handleOpen={setOpenMessage} timeout={API_MESSAGE_TIMEOUT} />
                    <EVChooserDialog open={openChooser} handleOpen={setOpenChooser} evaccounts={evaccounts} onChoosedEVAccount={onChoosedEVAccount} />
                    <TouristDetailsDialog eVisitorPinId={evAccount?.[PIN_ID]} evaccounts={evaccounts} tourist={tourist} open={openDetails} setOpen={setOpenDetails} />
                </div>
            </>
        );
    } else return (
        <>
            <PrimaryListSearchNavBar {...props} evaccounts={evaccounts} setEvAccount={setEvAccount} evAccount={evAccount} triggerSearch={triggerSearch} />
            <div className={classes.root}>
                <Typography variant="h6" color="inherit" noWrap>
                    {DOWNLOAD_IN_PROGRESS}
                </Typography>
                <CustomizedSnackbar message={generateMessage()} isError={apiErrors} open={openMessage} handleOpen={setOpenMessage} timeout={API_MESSAGE_TIMEOUT} />
                <EVChooserDialog open={openChooser} handleOpen={setOpenChooser} evaccounts={evaccounts} onChoosedEVAccount={onChoosedEVAccount}></EVChooserDialog>
            </div>
        </>
    );
}

export default Tourists;
