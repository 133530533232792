import React from 'react';
import HomeIcon from '@material-ui/icons/Home';
import HotelIcon from '@material-ui/icons/Hotel';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import useStyles from './SharedGuestHeader.styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Divider } from '@material-ui/core';
import {
    ADDITIONAL_FIELDS, EVISITOR, SHARED_GUEST_TEXT_INTRO,
    NAME, FACILITY_FIELD, NAME_CODE, SHARED_GUEST_TITLE, STAY_FROM_FIELD, FORESEEN_STAY_UNTIL_FIELD, 
    CURRENT_GUESTS_COUNT, CURRENT_GUEST_COUNT, MAX_GUEST_NUMBER, SHARED_GUEST_TEXT, SHARED_GUEST_TEXT_2,
} from '../../constants/strings-and-fields';
import { removeParanthesisText } from '../../helpers/Utils';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { getFormattedDateFromDateString } from '../../helpers/DateHelper';
import { useTranslation } from 'react-i18next';
import ConfirmWithCheckboxConsent from '../components/ConfirmWithCheckboxConsent';

const SharedGuestHeader = props => {
    const { checkin, consentAccepted } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    const facility = checkin?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD]?.[NAME];
    const currentGuestsCount = checkin?.[ADDITIONAL_FIELDS]?.[CURRENT_GUESTS_COUNT] ?? 0;
    const maxGuestsCount = checkin?.[ADDITIONAL_FIELDS]?.[MAX_GUEST_NUMBER] ?? 0;
    const facilityText = checkin ? removeParanthesisText(facility) : "";
    const dateRangeText = checkin ?
        `${getFormattedDateFromDateString(checkin?.[STAY_FROM_FIELD])} - ${getFormattedDateFromDateString(checkin?.[FORESEEN_STAY_UNTIL_FIELD])}` : "";

    return (
        <div className={classes.root}>
            <Box display="flex" flexDirection="column" >
                <Box className={classes.headerBox} >
                    <Typography gutterBottom variant="body1" className={classes.headerText}>
                        {t(SHARED_GUEST_TEXT_INTRO)}
                    </Typography>
                    <Typography gutterBottom variant="body1" className={classes.headerText2}>
                        {t(SHARED_GUEST_TEXT)}
                    </Typography>
                    <Typography gutterBottom variant="body1" className={classes.headerText3}>
                        {t(SHARED_GUEST_TEXT_2)}
                    </Typography>
                    <Divider />
                    <Typography gutterBottom variant="body1" className={classes.headerTextTitle}>
                        {t(SHARED_GUEST_TITLE)}
                    </Typography>
                    <Typography gutterBottom variant="body2">
                        <PersonIcon className={classes.icon} /> {checkin?.[ADDITIONAL_FIELDS]?.[EVISITOR]?.[NAME]}
                    </Typography>
                    <Typography gutterBottom variant="body2">
                        <HomeIcon className={classes.icon} /> {facilityText}
                    </Typography>
                    <Typography gutterBottom variant="body2" >
                        <HotelIcon className={classes.icon} /> {checkin?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD]?.[NAME_CODE]}
                    </Typography>
                    <Typography gutterBottom variant="body2" >
                        <DateRangeIcon className={classes.icon} /> {dateRangeText}
                    </Typography>
                    <Typography gutterBottom variant="body2" >
                        <PeopleIcon className={classes.icon} /> {t(CURRENT_GUEST_COUNT)} {currentGuestsCount}/{maxGuestsCount}
                    </Typography>
                </Box>
                <Divider />
                <ConfirmWithCheckboxConsent
                    consentAccepted={consentAccepted}>
                </ConfirmWithCheckboxConsent>
            </Box>
        </div>
    );
}

export default SharedGuestHeader;