import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { OK, NO } from '../../constants/strings-and-fields';

const ConfirmWithCheckboxDialog = ({ title, children, open, setOpen, onConfirm }) => {
    const accept = () => {
        setOpen(false);
        onConfirm();
    }

    const decline = () => {
        setOpen(false);
    }

    return <>
        <Dialog
            open={open}
            onClose={decline}
            aria-labelledby="confirm-dialog"
        >
            <DialogTitle id="confirm-dialog">{title}</DialogTitle>
            <DialogContent>
                <div>
                    {children}
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={decline}
                    color="default"
                >
                    {NO}
                </Button>
                <Button
                    variant="contained"
                    onClick={accept}
                    color="primary"
                >
                    {OK}
                </Button>
            </DialogActions>
        </Dialog>
    </>
}

export default ConfirmWithCheckboxDialog;