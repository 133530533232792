import * as yup from 'yup';
import { getDateFromDateString } from '../../helpers/DateHelper';
import { STAY_FROM_FIELD, FORESEEN_STAY_UNTIL_FIELD, TIME_ESTIMATED_STAY_UNTIL_FIELD, TIME_STAY_FROM_FIELD } from '../../constants/strings-and-fields';

export const schema = yup.object().shape({
    [TIME_ESTIMATED_STAY_UNTIL_FIELD]: yup.string().required(),
    [TIME_STAY_FROM_FIELD]: yup.string().required(),
    [STAY_FROM_FIELD]: yup.string()
        .test({
            name: 'dateBefore',
            exclusive: false,
            params: {},
            /* eslint-disable no-template-curly-in-string */
            message: '${path} must be before the stay until date',
            test: function (value) {
                const fromDate = getDateFromDateString(value);
                const toDate = getDateFromDateString(this.parent[FORESEEN_STAY_UNTIL_FIELD]);
                return (fromDate < toDate) ? true : false;
            },
        }),
    [FORESEEN_STAY_UNTIL_FIELD]: yup.string()
        .test({
            name: 'dateAfter',
            exclusive: false,
            params: {},
            message: '${path} must be after the stay from date',
            test: function (value) {
                const fromDate = getDateFromDateString(this.parent[STAY_FROM_FIELD]);
                const toDate = getDateFromDateString(value);
                return (toDate > fromDate) ? true : false;
            },
        }),
}, [STAY_FROM_FIELD, FORESEEN_STAY_UNTIL_FIELD]);

export default schema;
