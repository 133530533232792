import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ACCEPT, DECLINE, YES, NO } from '../../constants/strings-and-fields';
import { useTranslation } from 'react-i18next';

const ConfirmDialog = ({ title, children, open, setOpen, onConfirm, isDelete = false, onReject = null, showButtons = true }) => {
    const { t } = useTranslation();
    const close = () => {
        setOpen(false);
        onReject && onReject();
    }

    const confirm = () => {
        setOpen(false);
        onConfirm();
    }

    return <Dialog
        open={open}
        onClose={close}
        aria-labelledby="confirm-dialog"
    >
        <DialogTitle id="confirm-dialog">{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        {showButtons &&
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={close}
                    color={isDelete ? "secondary" : "default"}
                >
                    {isDelete ? t(NO) : t(DECLINE)}
                </Button>
                <Button
                    variant="contained"
                    onClick={confirm}
                    color={isDelete ? "default" : "primary"}
                >
                    {isDelete ? t(YES) : t(ACCEPT)}
                </Button>
            </DialogActions>
        }
    </Dialog>
}


export default ConfirmDialog;