import React, { useState, useEffect } from 'react';
import ListItem from '@material-ui/core/ListItem';
import HotelIcon from '@material-ui/icons/Hotel';
import useStyles from './EVisitorItem.styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {
    NAME_CODE, ID,
    NAME, ACCOMODATION_OBJECT,
    ACCOMODATION_UNIT, CODE, FACILITY_CODE,
    AUTO_TOURIST_CHECKOUT, AUTO_CHECKOUT
} from '../../constants/strings-and-fields';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import PeopleIcon from '@material-ui/icons/People';
import HomeIcon from '@material-ui/icons/Home';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { updateAccomodationObjectCharacetistics } from '../../api/incheckinservice';
import { Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { isString } from '../../helpers/Utils';

const EVisitorItem = props => {
    const { evaccount, onDelete, setOpenProgress, idx } = props;
    const { t } = useTranslation();
    const [isAutoCheckout, setIsAutoCheckout] = useState(false);
    const classes = useStyles();

    const handleDeleteClick = () => {
        onDelete(evaccount);
    }

    const handleAutoCheckoutChange = async (event) => {
        setOpenProgress(true);
        try {
            await updateAccomodationObjectCharacetistics(evaccount, event.target.checked);
        } catch (e) {
            console.log(e);
        }
        setOpenProgress(false);
    };

    useEffect(() => {
        if (evaccount) {
            const accUnit = evaccount?.[ACCOMODATION_UNIT]?.[0];
            if (accUnit) {
                const autoCheckout = accUnit?.[AUTO_TOURIST_CHECKOUT];
                const autoCheckoutVal = (autoCheckout === true || (isString(autoCheckout) && autoCheckout.startsWith("DA"))) ? true : false;
                setIsAutoCheckout(autoCheckoutVal);
            }
        }
    }, [evaccount, ...evaccount?.[ACCOMODATION_UNIT].map(u => u[AUTO_TOURIST_CHECKOUT])]);

    const getAccomodationObjects = (code) => {
        const accObjects = evaccount?.[ACCOMODATION_OBJECT]?.filter(accObject => accObject[FACILITY_CODE] === code);
        return (
            <Typography gutterBottom variant="body2" color="textSecondary" key={code}>
                {accObjects?.map(accObject => {
                    return (<><HotelIcon className={classes.icon} /> {accObject?.[NAME_CODE]}     </>);
                })}
            </Typography>
        )
    }

    const getAccomodationUnits = () => evaccount?.[ACCOMODATION_UNIT]?.map(unit => {
        return (
            <>
                <Typography gutterBottom variant="body2" color="textSecondary" key={unit?.[ID]}>
                    <HomeIcon className={classes.icon} /> {unit?.[NAME]}
                </Typography>
                {getAccomodationObjects(unit?.[CODE])}
            </>)
    })


    // <ListItem  onClick={() => handleClick(evaccount[ID])} button >
    return (
        <React.Fragment >
            <ListItem key={idx}>
                <div style={{ width: '100%' }} >
                    <Box display="flex" flexDirection="row" >
                        <Box display="flex" flexDirection="column">
                            <Typography gutterBottom variant="body2" >
                                <PeopleIcon className={classes.icon} /> {evaccount?.[NAME]}
                            </Typography>
                            {getAccomodationUnits()}
                        </Box>
                        <Box display="flex" alignSelf="flex-end" className={classes.flexJustifyEnd} >
                            <IconButton onClick={handleDeleteClick}>
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Divider />
                    <Box display="flex" flexDirection="row" className={classes.switch}>
                        <FormGroup >
                            <FormControlLabel
                                control={<Switch checked={isAutoCheckout} size="small" onChange={handleAutoCheckoutChange} name={evaccount[ID]} />}
                                label={t(AUTO_CHECKOUT)}
                            />
                        </FormGroup>
                    </Box>
                </div>
            </ListItem>
            <Divider />
        </React.Fragment>
    );
}

export default EVisitorItem;