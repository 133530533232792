import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        flexGrow: 1,
        height: '100vh',
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '30ch',
        },
        '& .MuiTextField-root .datePicker': {
            width: 100,
        },
        '& .MuiSelect-select': {
            margin: theme.spacing(1),
            width: '25ch',
            minWidth: 120,
            maxWidth: 300,
        },
    },
    datePicker: {
        width: '38vw!important',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    paper: {
        paddingBottom: 100,
        flexGrow: 1,
        height: '100%'
    },
    speedDial: {
        position: 'fixed',
        bottom: theme.spacing(1),
        right: theme.spacing(3),
        left: 'auto',
        top: 'auto',
        transform: 'scale(0.8)'
    },
    label: {
        paddingTop: 14,
        fontFamily: 'Menlo,Monaco,Monospace',
        fontWeight: 'bold',
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    grow: {
        flexGrow: 1,
    },
    appBar: {
        top: 'auto',
        bottom: 0,
    },
    moreButton: {
        color: theme.palette.getContrastText(theme.palette.primary.main),
        backgroundColor: theme.palette.secondary.main,
    },
    saveButton: {
        color: theme.palette.getContrastText(theme.palette.primary.main),
        backgroundColor: theme.palette.secondary.main,
        marginRight: theme.spacing(1),
    },
    icon: {
        position: "relative",
        top: theme.spacing(0.3),
        width: theme.typography.body2.fontSize,
        height: theme.typography.body2.fontSize,
        color: theme.palette.text.secondary
    },
    guestCount: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    guestForm: {
        marginTop: theme.spacing(-1),
    },
    formControlInline: {
        margin: 0,
    },
    formControlLabelInline: {
        margin: 0,
        paddingTop: theme.spacing(2),
        marginBottom: theme.spacing(-2),
        marginLeft: theme.spacing(1),
        color: theme.palette.common.white
    },
    selectLangInline: {
        margin: 0,
        marginLeft: theme.spacing(1),
        width: '15ch',
        color: theme.palette.common.white
    },
}));

export default useStyles;