import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import LinkIcon from '@material-ui/icons/Link';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
    EMAIL_BODY, id, SHARE_TITLE, SHARE_DIALOG_TITLE,
    SHARE_BUTTON_COPY_TEXT, SHARE_BUTTON_TEXT, LANGUAGE, 
    LANGUAGE_HR, LANGUAGE_EN, LANGUAGE_EN_CODE, LANGUAGE_HR_CODE, CHECKIN_LINK
} from '../../constants/strings-and-fields';
import { getPort, share } from '../../helpers/Utils';
import TextField from '@material-ui/core/TextField';
import useStyles from './LinkDialog.styles';
import { useTranslation } from 'react-i18next';

const LinkDialog = ({ isOpen, setOpenShare, checkin }) => {
    const [lang, setLang] = useState(LANGUAGE_EN_CODE);
    const { t } = useTranslation();
    const [openSelect, setOpenSelect] = useState(false);
    const [checkinUrl, setCheckinUrl] = useState(`${window.location.protocol}//${window.location.hostname}${getPort()}/shared-guest/${checkin?.[id]}?lang=${lang}`);
    const classes = useStyles();

    const handleClose = () => {
        setOpenShare(false);
    };

    const onCopy = async (_) => {
        try {
            await navigator.clipboard.writeText(checkinUrl);
        } catch (err) {
            console.log(err);
        }
        finally {
            handleClose();
        }
    };

    const onShare = () => {
        share(SHARE_TITLE, EMAIL_BODY, checkinUrl);
        handleClose();
    }

    const handleChangeSelect = (event) => {
        setLang(event.target.value);
        setCheckinUrl(`${window.location.protocol}//${window.location.hostname}${getPort()}/shared-guest/${checkin?.[id]}?lang=${event.target.value}`);
    };

    const handleCloseSelect = () => {
        setOpenSelect(false);
    };

    const handleOpenSelect = () => {
        setOpenSelect(true);
    };

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <IconButton aria-label="shareicon" className={classes.iconButton} color="primary">
                    <LinkIcon />
                </IconButton>
                {t(SHARE_DIALOG_TITLE)}
            </DialogTitle>
            <DialogContent>
                <FormControl className={classes.formControl}>
                    <InputLabel id="demo-controlled-open-select-label">{t(LANGUAGE)}</InputLabel>
                    <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        open={openSelect}
                        onClose={handleCloseSelect}
                        onOpen={handleOpenSelect}
                        value={lang}
                        onChange={handleChangeSelect}
                    >
                        <MenuItem value={LANGUAGE_EN_CODE}>{LANGUAGE_EN}</MenuItem>
                        <MenuItem value={LANGUAGE_HR_CODE}>{LANGUAGE_HR}</MenuItem>
                    </Select>
                </FormControl>
                <form noValidate autoComplete="off">
                    <TextField id="standard-basic" label={t(CHECKIN_LINK)} value={checkinUrl} disabled />
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={onShare} color="primary">
                    {t(SHARE_BUTTON_TEXT)}
                </Button>
                <Button onClick={onCopy} variant="contained" color="primary" autoFocus>
                    {t(SHARE_BUTTON_COPY_TEXT)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default LinkDialog;