import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { sideList } from './DrawerSideList';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  }
});

const Drawer = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { drawertrigger, opendrawer, history } = props;
  const [opened, setOpened] = useState(false);

  if (opened !== opendrawer) {
    setOpened(opendrawer);
  }

  const toggleDrawer = (side, open, drawertrigger) => () => {
    setOpened(false);
    drawertrigger();
  };

  if (opened) {
    return (
      <SwipeableDrawer
        open={opened}
        onClose={toggleDrawer('left', false, drawertrigger)}
        onOpen={toggleDrawer('left', true, drawertrigger)}
      >
        <div
          tabIndex={0}
          role="button"
          onClick={toggleDrawer('left', false, drawertrigger)}
          onKeyDown={toggleDrawer('left', false, drawertrigger)}
        >
          {sideList({ classes, history, t })}
        </div>
      </SwipeableDrawer>
    );
  }
  else {
    return (null);
  }
}

export default Drawer;