import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    PlayLists: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        //borderBottom: '1px solid #EEE'
    },
    root: {
        width: '100%',
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    title: {
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    speedDial: {
        position: 'fixed !important',
        bottom: theme.spacing(1),
        right: theme.spacing(3),
        left: 'auto',
        top: 'auto',
        transform: 'scale(0.8)',
        marginRight: theme.spacing(1),
    },
}));

export default useStyles;
