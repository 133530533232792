import React from 'react';
import { DataConsumer } from './data-context';
import MainAppPathRouter from '../MainAppPathRouter'

// this context contains all props which will be passed to all components
export default function MainContext() {
  return (
    <DataConsumer>
      {({ fetchAndSetData, fetchInitData, evaccounts,
        checkins, guests, onEditGuest, associateEvisitor,
        deleteEVisitor, eVisitorStaticData, signUp, login,
        appStatus, setApiErrors, apiErrors, apiMessages, 
        refreshCheckins, onUpdateGuests, onUpdateCheckin,
        onDeleteGuest, onAddCheckin, onEditCheckin,
        setLoggedUser, onDeleteCheckin, onReCreateGuest, theme, onThemeChange }) => (
        <MainAppPathRouter
          fetchAndSetData={fetchAndSetData}
          fetchInitData={fetchInitData}
          evaccounts={evaccounts}
          checkins={checkins}
          guests={guests}
          eVisitorStaticData={eVisitorStaticData}
          signUp={signUp}
          login={login}
          associateEvisitor={associateEvisitor}
          deleteEVisitor={deleteEVisitor}
          setLoggedUser={setLoggedUser}
          appStatus={appStatus}
          setApiErrors={setApiErrors}
          apiErrors={apiErrors}
          apiMessages={apiMessages}
          refreshCheckins={refreshCheckins}
          onEditGuest={onEditGuest}
          onDeleteGuest={onDeleteGuest}
          onAddCheckin={onAddCheckin}
          onUpdateCheckin={onUpdateCheckin}
          onUpdateGuests={onUpdateGuests}
          onEditCheckin={onEditCheckin}
          onDeleteCheckin={onDeleteCheckin}
          onReCreateGuest={onReCreateGuest}
          theme={theme}
          onThemeChange={onThemeChange}
        />
      )}
    </DataConsumer>
  );
}