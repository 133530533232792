import React, { useEffect, useState } from 'react';
import useStyles from './EvAccounts.styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {
    ADD_EVISITOR, CONFIRM_DELETE_EV_DIALOG,
    CONFIRM_DELETE_EV_TITLE, TOOLBAR_EVISITOR_TITLE
} from '../../../constants/strings-and-fields';
import CustomizedSnackbar from '../../components/Snackbar';
import { isLoggedIn } from '../../../helpers/Utils';
import PrimarySearchAppBar from '../../navbars/PrimaryListNavBar';
import AddEvisitorDialog from '../../components/AddEvisitorDialog';
import ConfirmDialog from '../../components/ConfirmDialog';
import EVisitorItem from '../../lists/EVisitorItem';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import List from '@material-ui/core/List';

const API_MESSAGE_TIMEOUT = 5000;

const EvAccounts = props => {
    const classes = useStyles();
    const { history, evaccounts, associateEvisitor, deleteEVisitor, apiErrors, apiMessages } = props;
    const { t } = useTranslation();
    const [openMessage, setOpenMessage] = useState(false);
    const [openProgress, setOpenProgress] = useState(false);
    const [openFab, setOpenFab] = useState(false);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [forDeleteAccount, setForDeleteAccount] = useState();
    let unlisten = null;

    const handleOpenFab = () => {
        setOpenFab(!openFab);
    };

    const generateMessage = () => {
        if (apiErrors) {
            return `${apiErrors?.signal}: ${apiErrors?.message}`
        }
        else if (apiMessages) {
            return `${apiMessages?.signal}: ${apiMessages?.message}`
        };
    }

    const onConnect = (eVisitorCredentials) => {
        setOpenProgress(true);
        setOpenFab(false);
        associateEvisitor(eVisitorCredentials).then(eVisitorRes => {
            setOpenProgress(false);
        }).catch(e => {
            console.log(e);
            setOpenProgress(false);
        })
    }

    const openDeleteDialog = (eVisitorAccount) => {
        setForDeleteAccount(eVisitorAccount);
        setConfirmDeleteOpen(true);
    }

    const onDelete = () => {
        if (forDeleteAccount) {
            setOpenProgress(true);
            deleteEVisitor(forDeleteAccount).then(_ => {
                setOpenProgress(false);
            }).catch(e => {
                console.log(e);
                setOpenProgress(false);
            })
        }
    }

    const handleProgressToggle = () => {
        setOpenProgress(!openProgress);
    };

    useEffect(() => {
        apiErrors && setOpenMessage(true);
        apiMessages && setOpenMessage(true);
    }, [apiMessages, apiErrors]);

    useEffect(() => {
        isLoggedIn().then(loggedIn => {
            if (!loggedIn) history.replace('/login');
        })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        unlisten = history.listen((location, action) => {
            if (action === 'POP') {
                history.replace('/');
            }
        });
        return () => { unlisten(); };
    });

    return (
        <div className={classes.root}>
            <PrimarySearchAppBar {...props} headerTitle={t(TOOLBAR_EVISITOR_TITLE)} />
            <List >
                {evaccounts.map((evaccount, idx) => {
                    return <EVisitorItem
                        evaccount={evaccount}
                        onDelete={openDeleteDialog}
                        idx={idx}
                        setOpenProgress={setOpenProgress}
                    />
                })
                }
            </List>
            <Fab color="primary" variant="extended" className={classes.speedDial} aria-label="add" onClick={handleOpenFab}>
                <AddIcon /> {t(ADD_EVISITOR)}
            </Fab>
            <AddEvisitorDialog open={openFab} setOpen={setOpenFab} onConnect={onConnect} />
            <ConfirmDialog
                title={t(CONFIRM_DELETE_EV_TITLE)}
                open={confirmDeleteOpen}
                isDelete={true}
                setOpen={setConfirmDeleteOpen}
                onConfirm={onDelete}>
                {t(CONFIRM_DELETE_EV_DIALOG)}
            </ConfirmDialog>
            <Backdrop className={classes.backdrop} open={openProgress} onClick={handleProgressToggle}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <CustomizedSnackbar message={generateMessage()} isError={apiErrors} open={openMessage} handleOpen={setOpenMessage} timeout={API_MESSAGE_TIMEOUT} />
        </div>);
}


export default EvAccounts;
