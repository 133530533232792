import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        //maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        '& .MuiTextField-root .datePicker': {
            width: 100,
        },
        '& .MuiBox-root': {
            width: '100%',
        },
        '& .MuiTypography-body2': {
            paddingLeft: 8,
        },
        '& .MuiGrid-item': {
            paddingLeft: 8,
        },

    },
    datePicker: {
        width: '38vw!important',
        marginRight: 16
    },
    headerBox: {
        paddingTop: 8,
    },
    headerDateBox: {
        paddingTop: 0,
    },
    headerText: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    headerText2: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    headerText3: {
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    headerTextTitle: {
        paddingLeft: theme.spacing(1),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    icon: {
        position: "relative",
        top: theme.spacing(0.3),
        width: theme.typography.body2.fontSize,
        height: theme.typography.body2.fontSize,
        color: theme.palette.text.secondary
    },
    fromDateIcon: {
        width: 24,
        height: 24
    },
    toDateIcon: {
        width: 48,
        height: 48
    },
}));

export default useStyles;