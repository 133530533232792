import React, { useEffect } from 'react';
import useStyles from './Settings.styles';
import { isLoggedIn } from './../../../helpers/Utils';
import { SETTINGS, } from '../../../constants/strings-and-fields';
import { BackAndSaveNavBar } from '../../navbars/BackAndSaveNavBar';
import { useTranslation } from 'react-i18next';
import LangChanger from '../../components/LangChanger';

const Settings = (props) => {
    const { history } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    const onBack = () => history.goBack();

    useEffect(() => {
        isLoggedIn().then(loggedIn => {
            if (!loggedIn) history.replace('/login');
        })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={classes.root}>
            <BackAndSaveNavBar classes={classes} onBack={onBack} title={t(SETTINGS)} />
            <LangChanger />
        </div>
    );
};

export default Settings;