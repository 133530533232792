
import jwt from 'jsonwebtoken';
import { JWT_SECRET, ICI_NAME } from '../api/restClient';
import {
    STORAGE_APP_ACCESS_TOKEN, STORAGE_COMM_KEY, STORAGE_REFRESH_TOKEN, STORAGE_USER_ACCESS_TOKEN,
    ADDRESS, CITIZENSHIP, CITY_OF_RESIDENCE, CODE, COUNTRY_CODE, COUNTRY_OF_BIRTH, DATE_OF_BIRTH_FIELD,
    DATE_PLACE_OF_BIRTH, DOCUMENT_NUMBER, DOCUMENT_TYPE, GENDER_FIELD, NAME, NAME_CITIZENSHIP, NAME_NATIONAL,
    TOURIST_NAME, PIN_ID, FACILITY_ID, NAME_CODE, ACCOMODATION_OBJECT, ACCOMODATION_UNIT,
    TOURIST_SURNAME, TOURIST_SURNAME_NAME, TRAVEL_DOCUMENT_TYPE, COUNTRY_OF_RESIDENCE, ID,
    CROATIA_COUNTRY_CODE, SETTLEMENT_NAME, STORAGE_USER
} from '../constants/strings-and-fields';
import { diffDateBirthYears, getDateFromDateString } from './DateHelper';
// email share onClick link email:
export const emailLink = (url, { subject, body, separator }) =>
    'mailto:' + objectToGetParams({ subject, body: body ? body + separator + url : url });

export const onClickEmail = (link) => {
    window.location.href = link;
}

export const objectToGetParams = (object) => {
    const params = Object.entries(object)
        .filter(([, value]) => value !== undefined && value !== null)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);
    return params.length > 0 ? `?${params.join('&')}` : '';
}

// Authentication
export const isLoggedIn = async () => {
    const userToken = localStorage.getItem(STORAGE_USER_ACCESS_TOKEN);
    if (userToken) {
        try {
            await jwt.verify(userToken, JWT_SECRET);
            console.log("Logged in")
            return true;
        } catch (e) {
            if (e.name === 'TokenExpiredError') {
                console.log("Token expired!!!");
            }
            console.log(e);
            clearUserData();
            return false;
        }
    } else {
        console.log("Is not logged in")
        return false;
    }
}

export const checkAppToken = async () => {
    const appToken = localStorage.getItem(STORAGE_APP_ACCESS_TOKEN);
    if (appToken) {
        try {
            await jwt.verify(appToken, JWT_SECRET);
        } catch (e) {
            if (e.name === 'TokenExpiredError') {
                console.log("Token expired!!!");
            }
            console.log(e);
            generateAndSaveAppToken();
        }
    } else {
        generateAndSaveAppToken();
    }
}

export const generateAndSaveAppToken = () => localStorage.setItem(STORAGE_APP_ACCESS_TOKEN, generateAppToken(ICI_NAME, JWT_SECRET));

export const getAppAccessToken = () => localStorage.getItem(STORAGE_APP_ACCESS_TOKEN);
export const getUserAccessToken = () => localStorage.getItem(STORAGE_USER_ACCESS_TOKEN);
export const getRefreshToken = () => localStorage.getItem(STORAGE_REFRESH_TOKEN);

export const clearUserData = () => {
    localStorage.removeItem(STORAGE_APP_ACCESS_TOKEN);
    localStorage.removeItem(STORAGE_USER_ACCESS_TOKEN);
    localStorage.removeItem(STORAGE_REFRESH_TOKEN);
    localStorage.removeItem(STORAGE_COMM_KEY);
    localStorage.removeItem(STORAGE_USER);
}

export const generateAppToken = (name, secret) => jwt.sign({
    app_token: name
}, secret, {
    algorithm: 'HS256',
    expiresIn: '1d',
    notBefore: 0
});

export const getPort = () => (window.location.port !== 443 && window.location.port !== '') ? ':' + window.location.port : '';

export const share = (title, text, url) => {
    if (navigator.share) {
        shareNative(title, text, url);
    }
    else {
        sendEmail(title, text + ' ' + url)
    }
}

export const shareNative = (title, text, url) => {
    navigator.share({
        title: title,
        text: text,
        url: url
    })
        .then(() => { console.log('Share success'); })
        .catch(console.error);
}

export const sendEmail = (subject, body) => {
    const link = "mailto:?to="
        + "&subject=" + encodeURIComponent(subject)
        + "&body=" + encodeURIComponent(body);
    window.open(link);
}

export const removeParanthesisText = (text) => {
    const regExp = /\(([^)]+)\)/;
    const matches = regExp.exec(text);
    if (matches && matches[1]) {
        return text.replace(matches[1], "").replace("()", "");
    } else return text;
}

export const mapTouristToGuest = (tourist, countries, citiesSettlements, documents) => {
    const guest = {};
    guest[GENDER_FIELD] = tourist[GENDER_FIELD];
    const datePlaceBirth = tourist[DATE_PLACE_OF_BIRTH];

    for (let country of countries) {
        if (tourist[CITIZENSHIP] === country[NAME_CITIZENSHIP]) {
            // "Citizenship": "GUM", 
            guest[CITIZENSHIP] = country[COUNTRY_CODE];

            // "Address": " Nepoznato Guam",
            if (tourist[ADDRESS].endsWith(country[NAME_NATIONAL])) {
                const city = tourist[ADDRESS].replace(country[NAME_NATIONAL], '').trim();
                guest[CITY_OF_RESIDENCE] = city;
                guest[COUNTRY_OF_RESIDENCE] = country[COUNTRY_CODE];
                if (country[COUNTRY_CODE] === CROATIA_COUNTRY_CODE) {
                    if (citiesSettlements) {
                        for (let citySettlement of citiesSettlements) {
                            if (guest[CITY_OF_RESIDENCE] === citySettlement[SETTLEMENT_NAME]) {
                                guest[CITY_OF_RESIDENCE] = citySettlement[NAME];
                                break;
                            }
                        }
                    }
                }
            }

            // "DateOfBirth": "20030626",  "DatePlaceOfBirth": "26.06.2003 (18)  Guam",
            const countryBirth = datePlaceBirth.substring(datePlaceBirth.indexOf(')') + 1).trim();
            if (countryBirth === country[NAME_NATIONAL]) {
                guest[COUNTRY_OF_BIRTH] = country[COUNTRY_CODE];
            }
            break;
        }
    }

    // "DateOfBirth": "20030626",  "DatePlaceOfBirth": "26.06.2003 (18)  Guam",
    const dateOfBirth = datePlaceBirth.substring(0, datePlaceBirth.indexOf('(') + -1).trim();
    const dateOfBirthParts = dateOfBirth.split('.');
    const dateOfBirthFormatted = dateOfBirthParts[2] + dateOfBirthParts[1] + dateOfBirthParts[0];
    guest[DATE_OF_BIRTH_FIELD] = dateOfBirthFormatted;

    // "SurnameAndName": "Ivi\u0107 Ivan (18)", TouristName, TouristSurname
    const surnameAndName = tourist[TOURIST_SURNAME_NAME];
    const surnameAndNameClean = surnameAndName.substring(0, surnameAndName.indexOf('(')).trim();
    const surnameAndNameParts = surnameAndNameClean.split(' ');
    guest[TOURIST_NAME] = surnameAndNameParts[1];
    guest[TOURIST_SURNAME] = surnameAndNameParts[0];

    // "TravelDocumentTypeNumber": "Ostalo 12345"
    const travelDocument = tourist[TRAVEL_DOCUMENT_TYPE];
    const documentParts = travelDocument.split(' ');
    const documentNumber = documentParts.pop().trim();
    guest[DOCUMENT_NUMBER] = documentNumber;
    const documentPartsWithoutLastNumber = documentParts.join(' ').trim();

    // "DocumentType": "039"
    for (let document of documents) {
        if (document[NAME] === documentPartsWithoutLastNumber) {
            guest[DOCUMENT_TYPE] = document[CODE];
            break;
        }
    }
    return guest;
}

export const getPaymentCategory = (birth) => {
    const dateBirth = getDateFromDateString(birth);
    const age = diffDateBirthYears(dateBirth);
    if (age < 12) {
        return "1";
    }
    else if (age > 12 && age < 18) {
        return "2";
    }
    else {
        return "14";
    };
}

export const isEmpty = (empty) => Object.keys(empty).length === 0 && empty.constructor === Object

export const getUrlForQrByID = (guestID) => {
    let changedGuestID = guestID.replaceAll("-", "I");
    changedGuestID = changedGuestID.replaceAll("a", "J");
    changedGuestID = changedGuestID.replaceAll("1", "L");
    changedGuestID = changedGuestID.replaceAll("f", "K");
    return changedGuestID;
}

export const isJSON = (str) => {
    try {
        return (JSON.parse(str) && !!str);
    } catch (e) {
        return false;
    }
}

export const getTouristObject = (pinId, facilityID, evaccounts) => {
    for (let evaccount of evaccounts) {
        let objectsDetails = "";
        if (evaccount[PIN_ID] === pinId) {
            objectsDetails = objectsDetails + evaccount[NAME] + " - ";
            for (let accObj of evaccount[ACCOMODATION_OBJECT]) {
                if (accObj[FACILITY_ID] === facilityID) {
                    objectsDetails = objectsDetails + accObj[NAME_CODE] + ", ";
                    break;
                }
            }
            for (let accUnit of evaccount[ACCOMODATION_UNIT]) {
                if (accUnit[ID] === facilityID) {
                    objectsDetails = objectsDetails + removeParanthesisText(accUnit[NAME]);
                    break;
                }
            }
            return objectsDetails;
        }
    }
    return "";
}

export const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') == -1 &&
    navigator.userAgent.indexOf('FxiOS') == -1;

export const isString = (data) => typeof data === 'string' || data instanceof String