import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import HomeIcon from '@material-ui/icons/Home';
import HotelIcon from '@material-ui/icons/Hotel';
import PeopleIcon from '@material-ui/icons/People';
import useStyles from './TouristItem.styles';
import Typography from '@material-ui/core/Typography';
import CalendarIcon from '../components/CalendarIcon';
import Box from '@material-ui/core/Box';
import { CHECKED_OUT_LABEL, FACILITY_FIELD, id, IS_CHECKED_OUT, NAME_FIELD, NOT_CHECKED_OUT_LABEL } from '../../constants/strings-and-fields';

const TouristItem = props => {
    const { history, tourist, checkinId, showDetails } = props;
    const classes = useStyles();
    const stayFromDate = tourist['arrival'];
    const stayToDate = tourist['departure'];

    const handleClick = (tourist) => {
        console.log(tourist);
        if (checkinId) {
            history.replace({
                pathname: `/checkin/${checkinId}`,
                tourist: tourist,
            });
        } else {
            // show dialog with tourist data
            showDetails(tourist);
        }
    }

    return (
        <List
            component="nav"
            className={classes.root}
            key={tourist[id]}>
            <ListItem button onClick={(e) => handleClick(tourist)}>
                <div style={{ width: '100%' }}>
                    <Box display="flex" flexDirection="row" >
                        <Box flexGrow={3} >
                            <Typography gutterBottom variant="body2" color="textSecondary">
                                {tourist?.[NAME_FIELD]}
                            </Typography>
                            <Typography gutterBottom variant="body2">
                                <HomeIcon className={classes.icon} /> {tourist?.[FACILITY_FIELD]}
                            </Typography>
                            <Typography gutterBottom variant="body2" color="textSecondary">
                                <HotelIcon className={classes.icon} /> {tourist?.[IS_CHECKED_OUT] ? CHECKED_OUT_LABEL : NOT_CHECKED_OUT_LABEL}
                            </Typography>
                        </Box>
                        <Box display="flex" flexDirection="column">
                            <Box flexGrow={2} >
                                <CalendarIcon size="small" secondary={true} date={stayFromDate} />
                            </Box>
                            <Box hidden>
                                <Typography gutterBottom variant="body2" color="textSecondary">
                                    <PeopleIcon className={classes.icon} />
                                </Typography>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="column">
                            <Box flexGrow={2}>
                                <CalendarIcon size="big" date={stayToDate} />
                            </Box>
                            <Box>
                                <Typography gutterBottom variant="body2" color="textSecondary">
                                    {stayToDate.getFullYear()}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </div>
            </ListItem>
        </List>
    );
}

export default TouristItem;