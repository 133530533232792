import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';

export const BackAndSaveNavBar = props => {
    const { classes, title = null, onBack, onSave, onDelete = null, isAdmin = false, children = null} = props;
    const showBackButton = (onBack) ? true : false;
    const showSaveButton = (onSave) ? true : false;
    return (
        <AppBar position="sticky">
            <Toolbar>
                {showBackButton &&
                    <IconButton className={classes.menuButton} color="inherit" aria-label="Back" onClick={e => onBack()}>
                        <ArrowBackIosIcon />
                    </IconButton>
                }
                {title && <Typography className={classes.title} variant="h6" color="inherit" noWrap>{title}</Typography>}
                {children && children}
                <div className={classes.grow} />
                <div className={classes.customAppBar}>
                    {isAdmin && onDelete &&
                        <IconButton color="inherit"
                            onClick={e => onDelete()}>
                            <DeleteIcon />
                        </IconButton>
                    }
                    {showSaveButton &&
                        <IconButton
                            color="inherit"
                            onClick={e => onSave()}>
                            <CheckIcon />
                        </IconButton>
                    }
                </div>
            </Toolbar>
        </AppBar>);
}
