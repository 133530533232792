// Dummy
export const LOREM_IPSUM = "LOREM_IPSUM";
// Enums
export const GENDERS = { 'Male': 'Muški', 'Female': 'Ženski' };

// Static data fields
export const COUNTRY_DATA = "CountryLookup";
export const RECORDS = "Records";
export const COUNTRY_NAME = "AlternativeName";
export const COUNTRY_CODE = "CodeThreeLetters";
export const NAME_NATIONAL = "NameNational";
export const NAME_CITIZENSHIP = "NameCitizenships";
export const ADDRESS = "Address";
export const CROATIA_COUNTRY_CODE = "HRV";
export const SETTLEMENTS_DATA = "SettlementLookup";
export const NAME = "Name";
export const DOCUMENT_TYPE_DATA = "DocumentTtypeLookup";
export const CODE = "Code";
export const CODE_MI = "CodeMI";
export const SERVICE_TYPE_DATA = "OfferedServiceTypeLookup";
export const PAYMENT_CATEGORY_DATA = "TTPaymentCategoryLookup";
export const ARRIVAL_ORGANIZATION_DATA = "ArrivalOrganisationLookup";
export const PIN_ID = "pin_id";
export const PIN = "Pin";
export const ACCOMODATION_OBJECT = "ACCOMODATION_OBJECT";
export const ACCOMODATION_UNIT = "ACCOMODATION_UNIT";
export const OBJECT = "OBJECT";
export const guests = "guests";
export const content = "content";
export const status = "status";
export const EV_STATIC_DATA = "EV_STATIC_DATA";
export const ACTIVE = "Active";
export const SEQUENCE = "Sequence";
export const TOURIST_NOT_CHECKED_OUT = "turist još nije odjavljen";
export const NE = "Ne";

// Checkin in status
export const STATUS = "status";
export const NOT_CHECKED_IN_STATUS = "Not Checked-In";
export const CHECKED_IN_STATUS = "Checked-In";
export const CHECKED_IN_UPDATED_STATUS = "Checked-In Updated";
export const CHECKED_OUT_STATUS = "Checkout-Out";
export const CANCELED_STATUS = "Canceled";
export const UPDATED_STATUS = "Updated";
export const CREATED_STATUS = "Created";

// Actions
export const CHECKIN_ACTION = "checkin";
export const CHECKOUT_ACTION = "checkout";
export const CANCEL_ACTION = "cancel";

// Fields
export const GENDER_FIELD = "Gender";
export const TIME_STAY_FROM_FIELD = "TimeStayFrom";
export const STAY_FROM_FIELD = "StayFrom";
export const FORESEEN_STAY_UNTIL_FIELD = "ForeseenStayUntil";
export const TIME_ESTIMATED_STAY_UNTIL_FIELD = "TimeEstimatedStayUntil";
export const DATE_OF_BIRTH_FIELD = "DateOfBirth";
export const COUNTRY_OF_BIRTH = "CountryOfBirth";
export const COUNTRY_OF_RESIDENCE = "CountryOfResidence";
export const CITIZENSHIP = "Citizenship";
export const KONTAKT = "Kontakt";
export const DATE_PLACE_OF_BIRTH = "DatePlaceOfBirth";
export const CITY_OF_RESIDENCE = "CityOfResidence";
export const DOCUMENT_TYPE = "DocumentType";
export const PAYMENT_CATEGORY = "TTPaymentCategory";
export const OFFERED_SERVICE_TYPE = "OfferedServiceType";
export const ARRIVAL_ORGANIZATION = "ArrivalOrganisation";
export const FACILITY = "Facility";
export const TOURIST_NAME = "TouristName";
export const TOURIST_SURNAME = "TouristSurname";
export const TOURIST_MIDDLE_NAME = "TouristMiddleName";
export const TOURIST_SURNAME_NAME = "SurnameAndName";
export const TRAVEL_DOCUMENT_TYPE = "TravelDocumentTypeNumber";
export const DOCUMENT_NUMBER = "DocumentNumber";
export const DATE_TIME_OF_ARRIVAL = "DateTimeOfArrival";
export const DATE_TIME_OF_DEPARTURE = "DateTimeOfDeparture";
export const CHECKOUT_OUT = "CheckedOutTourist";
export const FACILITY_NAME = "FacilityName";
export const FACILITY_ID = "FacilityID";
export const ID = "ID";
export const id = "id";
export const ADDITIONAL_FIELDS = "additional_info";
export const CURRENT_GUESTS_COUNT = "current_guests_count";
export const CHECKIN_ID = "checkin_id";
export const DATE_CREATED = "dateCreated";
export const EVISITOR = "evisitor";
export const FACILITY_FIELD = "facility";
export const ACCOMODATION = "accommodation";
export const NAME_CODE = "NameCode";
export const EV_ACCOUNT = "evAccount";
export const EV_ACCOUNT_ID = "ev_account_id";
export const FACILITY_CODE = "FacilityCode";
export const CHECKOUT_DATE = "CheckOutDate";
export const CHECKOUT_TIME = "CheckOutTime";
export const SETTLEMENT_NAME = "SettlementName";
export const CREATED_AT = "created_at";
export const DEFAULT_SERVICE_TYPE = "Noćenje";
export const DEFAULT_ARRIVAL_ORGANIZATION = "I";
export const NEPOZNATO = "Nepoznato";
export const MAX_GUEST_NUMBER = "max_guests";
export const IS_CHECKED_OUT = "isCheckedOut";
export const NAME_FIELD = "name";
export const TOURIST_CHECKOUT_OUT_STRING = "TouristCheckedOutString";
export const NOTE = "Note";
export const AUTO_TOURIST_CHECKOUT = "AutomaticTouristCheckout";
export const CHECKIN_CHECKOUT = "CheckInOut";
export const EMAIL_FIELD = "Email";
export const CHECKIN_FIELD = "CheckIn";
export const CHECKOUT_FIELD = "CheckOut";

// Buttons
export const ADD_GUEST = "ADD_GUEST";
export const ADD_CHECKIN = "ADD_CHECKIN";
export const ADD_EVISITOR = "ADD_EVISITOR";
export const SHARE_BUTTON_TEXT = "SHARE_BUTTON_TEXT";
export const SHARE_BUTTON_COPY_TEXT = "SHARE_BUTTON_COPY_TEXT";
export const CHECKIN_BUTTON = "CHECKIN_BUTTON";
export const EVISITOR_CONNECT = "EVISITOR_CONNECT";
export const ACCEPT = "ACCEPT";
export const DECLINE = "DECLINE";

// Messages
export const ALERT_DIALOG_TITLE = "ALERT_DIALOG_TITLE";
export const ERROR_ENTER_NUMBER_OF_GUESTS = "ERROR_ENTER_NUMBER_OF_GUESTS";
export const GUESTS = "GUESTS";
export const EMAIL_BODY = "Here is the guest checkin link: \n";
export const STATUS_GET_EVISITOR = "Syncing eVisitor accounts...";
export const STATUS_GET_CHECKINS = "Syncing checkins...";
export const STATUS_GET_STATIC = "Getting static data...";
export const STATUS_UPDATE_GUEST = "Updating guest...";
export const STATUS_DELETE_GUEST = "Deleting guest...";
export const STATUS_SAVE_CHECKIN = "Saving checkin...";
export const STATUS_UPDATE_CHECKIN = "Updating checkin...";
export const STATUS_UPDATE_CHECKIN_GUESTS = "Updating checkin guests...";
export const STATUS_RECREATE_CHECKIN_GUESTS = "Recreating checkin guests...";
export const STATUS_EDIT_CHECKIN = "Editing checkin...";
export const STATUS_DELETE_CHECKIN = "Deleting checkin...";
export const CONFIRM_CANCEL_DIALOG = "CONFIRM_CANCEL_DIALOG";
export const CONFIRM_DELETE_EV_DIALOG = "CONFIRM_DELETE_EV_DIALOG"
export const CHECKIN_UNKNOWN = "CHECKIN_UNKNOWN";
//export const GUEST_EDIT_WARNING = "After saving guest data you will not be able to edit it anymore! For eventual changes please contact the renter."
export const GUEST_EDIT_WARNING = "GUEST_EDIT_WARNING"
export const TERMS_CHECKBOX = "TERMS_CHECKBOX";
export const PRIVOLA_PRIKUPLJANJE = "PRIVOLA_PRIKUPLJANJE";
export const PRIVOLA_USTUPANJE = "PRIVOLA_USTUPANJE";
export const PRIVOLA_SPREMANJE = "PRIVOLA_SPREMANJE";
export const PRIVOLA_1 = "PRIVOLA_1";
export const PRIVOLA_2 = "PRIVOLA_2";
export const PRIVOLA_3 = "PRIVOLA_3";
export const PRIVOLA_4 = "PRIVOLA_4";
export const PRIVOLA_5 = "PRIVOLA_5";
export const CANCEL_TEXT = "CANCEL_TEXT";
export const SHARED_GUEST_TEXT_INTRO = "SHARED_GUEST_TEXT_INTRO";
export const SHARED_GUEST_TEXT = "SHARED_GUEST_TEXT";
export const SHARED_GUEST_TEXT_2 = "SHARED_GUEST_TEXT_2";
export const SHARED_GUEST_TITLE = "SHARED_GUEST_TITLE";
export const CURRENT_GUEST_COUNT = "CURRENT_GUEST_COUNT";
//export const MAX_GUEST_NUMBER_REACHED = "Max guest number reached. Thank you for completing self-checkin!";
export const MAX_GUEST_NUMBER_REACHED = "MAX_GUEST_NUMBER_REACHED";
export const GUEST_SAVED = "GUEST_SAVED";
export const PASSWORDS = "PASSWORDS";
export const PASSWORD_NOT_MATCHED = "PASSWORD_NOT_MATCHED";
export const CONFIRM_TERMS_CONTENT = LOREM_IPSUM;
export const ERROR = "ERROR";
export const USER_CREDS_INVALID = "USER_CREDS_INVALID";

// Titles
export const CHOOSE_FACILITY_TITLE = "CHOOSE_FACILITY_TITLE";
export const CHOOSE_EVACCOUNT_TITLE = "CHOOSE_EVACCOUNT_TITLE";
export const TITLE_NUMBER_OF_GUESTS = "TITLE_NUMBER_OF_GUESTS";
export const TOOLBAR_CHECKIN_TITLE = "Checkin";
export const TOOLBAR_SIGNUP_TITLE = "Sign Up";
export const TOOLBAR_EVISITOR_TITLE = "TOOLBAR_EVISITOR_TITLE";
export const TOOLBAR_GUEST_TITLE = "TOOLBAR_GUEST_TITLE";
export const DOWNLOAD_IN_PROGRESS = "Downloading data";
export const SHARE_TITLE = "InCheckin Self-Checkin";
export const SHARE_DIALOG_TITLE = "SHARE_DIALOG_TITLE";
export const LOGIN_TITLE = "InCheckin Login";
export const SIGNUP_TITLE = "InCheckin Sign Up";
export const REMEMBER_LABEL = "Remember me";
export const SIGN_IN = "SIGN_IN";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const SIGN_UP_BUTTON_TITLE = "SIGN_UP_BUTTON_TITLE";
export const SIGN_UP_TITLE = "SIGN_UP_TITLE";
export const INCHECKIN = "InCheckin";
export const FOREACH = "4EACH";
export const CONFIRM_CANCEL_TITLE = "CONFIRM_CANCEL_TITLE";
export const CONFIRM_GUEST_TITLE = "CONFIRM_GUEST_TITLE";
export const CONFIRM_DELETE_EV_TITLE = "CONFIRM_DELETE_EV_TITLE";
export const CONFIRM_GUEST_DELETE_TITLE = "CONFIRM_GUEST_DELETE_TITLE";
export const CONFIRM_GUEST_DELETE_DIALOG = "CONFIRM_GUEST_DELETE_DIALOG";
export const CONFIRM_CHECKIN_DELETE_TITLE = "Brisanje prijave";
export const CONFIRM_CHECKIN_DELETE_DIALOG = "Jeste li sigurni da želite izbrisati prijavu?";
export const PRIVOLA_TITLE = "PRIVOLA_TITLE";
export const PRIVOLA_CONTENT_TITLE = "PRIVOLA_CONTENT_TITLE";
export const AGREE = "AGREE";
export const AND = "AND";
export const TERMS = "TERMS";
export const PRIVACY_POLICY = "PRIVACY_POLICY";
export const TOURIST_DETAILS_TITLE = "TOURIST_DETAILS_TITLE";
export const ADD_EVISITOR_DIALOG_TITLE = "ADD_EVISITOR_DIALOG_TITLE";
export const CONFIRM_TERMS_TITLE = "CONFIRM_TERMS_TITLE";
export const QR_TOURIST_DATA_TITLE = "QR_TOURIST_DATA_TITLE";
export const QR_TOURIST_DATA_SUBTITLE = "QR_TOURIST_DATA_SUBTITLE";
export const QR_TOURIST = "QR_TOURIST"
export const QR_GENDER = "QR_GENDER"
export const QR_NATIONATLITY = "QR_NATIONATLITY"
export const QR_CONTACT = "QR_CONTACT"
export const QR_ADDRESS = "QR_ADDRESS"
export const QR_DOCUMENT = "QR_DOCUMENT"
export const QR_ACCOMODATION_INFO = "QR_ACCOMODATION_INFO"
export const QR_OBJECT = "QR_OBJECT"
export const QR_STAY_FROM = "QR_STAY_FROM"
export const QR_FORSEEN_STAY_TO = "QR_FORSEEN_STAY_TO"
export const QR_STAY_TO = "QR_STAY_TO"
export const QR_CATEGORY = "QR_CATEGORY"
export const QR_ARRIVAL_ORG = "QR_ARRIVAL_ORG"
export const QR_TYPE_OF_SERVICE = "QR_TYPE_OF_SERVICE"

// Labels
export const GENDER_LABEL = "GENDER_LABEL";
export const CHECKIN_LINK = "CHECKIN_LINK";
export const ARRIVAL_DATE_LABEL = "ARRIVAL_DATE_LABEL";
export const ARRIVAL_TIME_LABEL = "ARRIVAL_TIME_LABEL";
export const DEPARTURE_DATE_LABEL = "DEPARTURE_DATE_LABEL";
export const DEPARTURE_TIME_LABEL = "DEPARTURE_TIME_LABEL";
export const FIRST_NAME_LABEL = "FIRST_NAME_LABEL";
export const LAST_NAME_LABEL = "LAST_NAME_LABEL";
export const DATE_OF_BIRTH_LABEL = "DATE_OF_BIRTH_LABEL";
export const COUNTRY_OF_BIRTH_LABEL = "COUNTRY_OF_BIRTH_LABEL";
export const CITIZENSHIP_LABEL = "CITIZENSHIP_LABEL";
export const RESIDENCE_COUNTRY_LABEL = "RESIDENCE_COUNTRY_LABEL";
export const RESIDENCE_CITY_LABEL = "RESIDENCE_CITY_LABEL";
export const DOCUMENT_TYPE_LABEL = "DOCUMENT_TYPE_LABEL";
export const DOCUMENT_CODE_LABEL = "DOCUMENT_CODE_LABEL";
export const PAYMENT_CATEGORY_LABEL = "PAYMENT_CATEGORY_LABEL";
export const SERVICE_TYPE_LABEL = "SERVICE_TYPE_LABEL";
export const TRAVEL_ARRANGEMENTS_LABEL = "TRAVEL_ARRANGEMENTS_LABEL";
export const YES = "YES";
export const NO = "NO";
export const SEARCH_TOURISTS_LABEL = "SEARCH_TOURISTS_LABEL";
export const OK = "OK";
export const NEW_GUEST = "NEW_GUEST";
export const EXISTING_GUEST = "EXISTING_GUEST";
export const EVISITOR_LABEL = "EVisitor";
export const CHECKINS_LABEL = "Checkins";
export const CHECKIN = "Checkin";
export const CHECKOUT = "Checkout";
export const CANCEL = "Cancel";
export const CHECKIN_ACTIONS = "CHECKIN_ACTIONS";
export const CHECKIN_ALL = "CHECKIN_ALL";
export const CHECKOUT_ALL = "CHECKOUT_ALL";
export const CANCEL_ALL = "CANCEL_ALL";
export const CHECKED_OUT_LABEL = "Checked out";
export const NOT_CHECKED_OUT_LABEL = "Not Checked out";
export const ENTER_DATA = "ENTER_DATA";
export const SAVE_DATA = "SAVE_DATA";
export const INPUT_NEXT_DATA = "INPUT_NEXT_DATA";
export const EVISITOR_USERNAME = "EVISITOR_USERNAME";
export const EVISITOR_PASS = "EVISITOR_PASS";
export const FORWARD_DATA = "FORWARD_DATA";
export const SELF_CHECKIN = "Self-Checkin";
export const ALL = "All";
export const LANGUAGE = "LANGUAGE";
export const LANGUAGE_EN = "English";
export const LANGUAGE_HR = "Hrvatski";
export const LANGUAGE_EN_CODE = "en";
export const LANGUAGE_HR_CODE = "hr";
export const SETTINGS = "SETTINGS";
export const CHECKINS = "CHECKINS";
export const TOURISTS = "TOURISTS";
export const EVACCOUNTS = "EVACCOUNTS";
export const HELP = "HELP";
export const LOGOUT = "LOGOUT";
export const EMAIL = "EMAIL";
export const AUTO_CHECKOUT = "AUTO_CHECKOUT";

// Api urls
export const EV_ACCOUNTS_DATA_URL = "/evaccounts";
export const CHECKINS_DATA_URL = "/checkins";
export const GUESTS_DATA_URL = "/guests";
export const FACILITIES_DATA_URL = "/facilities";
export const ACCOMODATIONS_DATA_URL = "/accomodations";
export const STATIC_DATA_URL = "/evisitor_static_data";
export const LOGIN_URL = "/login";
export const MAIL_URL = "/emails";

// Query keys
export const CHECKINS_DATA = "CHECKINS_DATA";
export const EV_ACCOUNTS_DATA = "EV_ACCOUNTS_DATA";
export const GUESTS_DATA = "GUESTS_DATA";
export const FACILITIES_DATA = "FACILITIES_DATA";
export const ACCOMODATIONS_DATA = "ACCOMODATIONS_DATA";
export const STATIC_DATA = "STATIC_DATA";

// Rest fields
export const STATIC_DATA_FIELD = "evisitor_static_data_type";

// Local storage
export const STORAGE_APP_ACCESS_TOKEN = 'app_access_token';
export const STORAGE_USER_ACCESS_TOKEN = 'user_access_token';
export const STORAGE_REFRESH_TOKEN = 'refresh_token';
export const STORAGE_COMM_KEY = 'comm_key';
export const STORAGE_USER = 'user';
export const STORAGE_CHOSEN_EV_ACCOUNT = 'chosen_ev_account';
export const STORAGE_LANGUAGE = 'lang';

// Urls
export const INCHECKIN_URL = "https://incheckin.hr";
export const EVISITOR_GUEST_DETAILS_URL = "https://www.evisitor.hr/eVisitor/hr-HR/IspisDetalja/Details?encCode=";
export const INCHECKIN_TERMS_URL = "INCHECKIN_TERMS_URL";
export const INCHECKIN_PRIVACY_URL = "INCHECKIN_PRIVACY_URL";