import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import useStyles from './Login.styles';
import Container from '@material-ui/core/Container';
import CustomizedSnackbar from '../../components/Snackbar';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { isLoggedIn } from './../../../helpers/Utils';
import {
    FOREACH, SIGN_UP_TITLE, EVISITOR_PASS, EMAIL,
    FORGOT_PASSWORD, LOGIN_TITLE, SIGN_IN, USER_CREDS_INVALID, ERROR,
} from '../../../constants/strings-and-fields';
import { useTranslation } from 'react-i18next';
import ConfirmWithCheckboxConsent from '../../components/ConfirmWithCheckboxConsent';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

const API_MESSAGE_TIMEOUT = 5000;

const Login = (props) => {
    const { history, fetchInitData, login, setLoggedUser, apiErrors, apiMessages } = props;
    const { t } = useTranslation();
    const [openMessage, setOpenMessage] = useState(false);
    const [openProgress, setOpenProgress] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [checkedTerms, setCheckedTerms] = useState(false);
    const [disableButton, setDisableButton] = useState(false);

    const classes = useStyles();

    const handleSubmit = (event) => {
        const formData = new FormData(event.target);
        event.preventDefault();
        const user = {};
        for (let [key, value] of formData.entries()) {
            console.log(key, value);
            user[key] = value;
            if (["email", "password"].includes(key) && value === "") {
                setOpenAlert(true);
                return;
            }
        }
        setOpenProgress(true);
        login(user).then(userRes => {
            console.log("Login success");
            fetchInitData().then(_ => {
                setLoggedUser({ ...userRes });
                setOpenProgress(false);
                history.replace('/');
            })
                .catch(e => {
                    console.log("Login init data failed failed")
                    console.log(e);
                    setOpenProgress(false);
                })
        })
            .catch(e => {
                console.log("Login fail");
                console.log(e);
                setOpenProgress(false);
            })
    }

    const handleProgressToggle = () => {
        setOpenProgress(!openProgress);
    };

    const consentAccepted = (isAccepted) => {
        setCheckedTerms(isAccepted);
    }

    const generateMessage = () => {
        if (apiErrors) {
            return `${apiErrors?.signal}: ${apiErrors?.message}`;
        }
        if (apiMessages) {
            return `${apiMessages?.signal}: ${apiMessages?.message}`;
        }
    }

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    useEffect(() => {
        apiErrors && setOpenMessage(true);
        apiMessages && setOpenMessage(true);
    }, [apiErrors, apiMessages]);

    useEffect(() => {
        // clear token
        isLoggedIn().then(_ => { });
    }, []);

    useEffect(() => {
        setDisableButton(!checkedTerms);
    }, [checkedTerms]);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {LOGIN_TITLE}
                </Typography>
                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={t(EMAIL)}
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={t(EVISITOR_PASS)}
                        id="password"
                        autoComplete="current-password"
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <ConfirmWithCheckboxConsent
                        consentAccepted={consentAccepted}>
                    </ConfirmWithCheckboxConsent>
                    <Button
                        disabled={disableButton}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        {t(SIGN_IN)}
                    </Button>
                    <Grid container >
                        <Grid item xs hidden>
                            <Link href="#" variant="body2">
                                {t(FORGOT_PASSWORD)}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="/sign-up" variant="body2">
                                {t(SIGN_UP_TITLE)}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <div hidden={!openAlert}>
                <Alert severity="error" >
                    <AlertTitle>{t(ERROR)}</AlertTitle>
                    <strong>{t(USER_CREDS_INVALID)}</strong>
                </Alert>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>

            <Backdrop className={classes.backdrop} open={openProgress} onClick={handleProgressToggle}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <CustomizedSnackbar message={generateMessage()} isError={apiErrors} open={openMessage} handleOpen={setOpenMessage} timeout={API_MESSAGE_TIMEOUT} />
        </Container>
    );
}

const Copyright = () => (
    <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://4each.io">
            {FOREACH}
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
    </Typography>
);

export default Login;