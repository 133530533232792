import React, { useState } from 'react';
import {
    LANGUAGE, LANGUAGE_HR,
    LANGUAGE_EN, LANGUAGE_EN_CODE,
    LANGUAGE_HR_CODE, STORAGE_LANGUAGE
} from '../../constants/strings-and-fields';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import useStyles from './LangChanger.styles';
import { useTranslation } from 'react-i18next';

const LangChanger = (props) => {
    const { styleClasses } = props;
    const { t, i18n } = useTranslation();
    const [lang, setLang] = useState(LANGUAGE_EN_CODE);
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const handleChange = (event) => {
        setLang(event.target.value);
        i18n.changeLanguage(event.target.value);
        localStorage.setItem(STORAGE_LANGUAGE, event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <FormControl className={styleClasses?.formControlInline ?? classes.formControlInline}>
            <InputLabel id="demo-controlled-open-select-label" className={styleClasses?.formControlLabelInline ?? classes.formControlLabelInline}>{t(LANGUAGE)}</InputLabel>
            <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={lang}
                onChange={handleChange}
                disableUnderline={styleClasses ?? false}
                className={styleClasses?.selectLangInline ?? classes.selectLangInline}
            >
                <MenuItem value={LANGUAGE_EN_CODE}>{LANGUAGE_EN}</MenuItem>
                <MenuItem value={LANGUAGE_HR_CODE}>{LANGUAGE_HR}</MenuItem>
            </Select>
        </FormControl>
    );
};

export default LangChanger;