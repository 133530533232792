import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

const AUTO_HIDE_DURATION = 2000;
const ERROR_AUTO_HIDE_DURATION = 4000;

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

const CustomizedSnackbar = (props) => {
    const { message, isError = false, timeout = AUTO_HIDE_DURATION, open, handleOpen } = props;
    const classes = useStyles();
    const snackTimeout = isError ? ERROR_AUTO_HIDE_DURATION : timeout;

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        handleOpen(!open);
    };

    const isOpen = (open && message) ? true : false;
    return (
        <div className={classes.root}>
            <Snackbar open={isOpen} autoHideDuration={snackTimeout} onClose={handleClose}>
                <Alert onClose={handleClose} severity={isError ? "error" : "success"}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default CustomizedSnackbar;