import React from 'react';
import { useStyles, prepareStyles } from './CalendarIcon.styles';
import './CalendarIcon.css';
import { getCalendarDate } from './../../helpers/DateHelper';
import Box from '@material-ui/core/Box';

const CalendarIcon = props => {
    const { date, size = 'big', secondary = false } = props;
    const { month, day } = getCalendarDate(date);
    const classes = useStyles(prepareStyles(size, secondary));

    return (
        <Box display="flex" className={`calendarDate ${classes.root}`}>
            <div>
                <strong className='monthName'>{month}</strong>
                <span className='monthDay'>{day}</span>
            </div>
        </Box>
    );
}

export default CalendarIcon;