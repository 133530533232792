import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    guestNumberLabel: {
        width: 160,
    },
    guestNumberInput: {
        width: 112,
    },
}));

export default useStyles;