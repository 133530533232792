import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CHOOSE_EVACCOUNT_TITLE, ID, NAME, OK } from '../../constants/strings-and-fields';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import { useTranslation } from 'react-i18next';

const EVChooserDialog = (props) => {
    const { t } = useTranslation();
    const { evaccounts, title = t(CHOOSE_EVACCOUNT_TITLE), open, handleOpen, onChoosedEVAccount } = props;
    const handleClose = () => { handleOpen(!open); };

    const onClickEvAccountItem = (data) => {
        onChoosedEVAccount(data);
        handleClose();
    }

    return (
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="chooser-dialog-title"
                aria-describedby="chooser-dialog-description"
            >
                <DialogTitle id="chooser-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="chooser-dialog-description">
                        {evaccounts.map(data =>
                            <ListItem key={data?.[ID]} button onClick={_ => onClickEvAccountItem(data)}>
                                <ListItemIcon>
                                    <HomeIcon />
                                </ListItemIcon>
                                <ListItemText primary={data?.[NAME]} />
                            </ListItem>)
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        {OK}
                    </Button>
                </DialogActions>
            </Dialog>
    );
}

export default EVChooserDialog;