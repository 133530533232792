import React, { useContext } from 'react'
import DataContext from './context/data-context'

// custom components
import './App.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import CheckIns from './components/screens/checkins/CheckIns';
import CheckIn from './components/screens/checkin/CheckIn';
import Guest from './components/screens/guest/Guest';
import SharedGuest from './components/screens/shared-guest/SharedGuest';
import Login from './components/screens/login/Login';
import SignUp from './components/screens/sign-up/SignUp';
import Tourists from './components/screens/tourists/Tourists';
import EvAccounts from './components/screens/ev-accounts/EvAccounts';
import Settings from './components/screens/settings/Settings';
import { MuiThemeProvider } from '@material-ui/core/styles';

const TheRouter = (props) => {
    const { signUp, login, associateEvisitor, deleteEVisitor, fetchAndSetData, fetchInitData, refreshCheckins, onEditGuest, onDeleteGuest, onUpdateGuests, onAddCheckin, onUpdateCheckin, onEditCheckin, onDeleteCheckin, onReCreateGuest } = useContext(DataContext);
    const { theme, evaccounts, checkins, guests, eVisitorStaticData, setLoggedUser, appStatus, setApiErrors, apiErrors, apiMessages } = props;

    return (
        <MuiThemeProvider theme={theme}>
            <BrowserRouter>
                <Switch>
                    <Route path="/login" render={
                        props => <Login {...props} fetchInitData={fetchInitData} login={login} setLoggedUser={setLoggedUser} appStatus={appStatus} apiErrors={apiErrors} apiMessages={apiMessages} />
                    } />
                    <Route path="/sign-up" render={
                        props => <SignUp {...props} fetchInitData={fetchInitData} signUp={signUp} setLoggedUser={setLoggedUser} appStatus={appStatus} apiErrors={apiErrors} apiMessages={apiMessages} />
                    } />
                    <Route path="/ev-accounts" render={props =>
                        <EvAccounts {...props} associateEvisitor={associateEvisitor} deleteEVisitor={deleteEVisitor} fetchAndSetData={fetchAndSetData} refreshCheckins={refreshCheckins} evaccounts={evaccounts} checkins={checkins} guests={guests} appStatus={appStatus} apiErrors={apiErrors} apiMessages={apiMessages} />
                    } />
                    <Route path="/checkin/:id" render={
                        props => <CheckIn {...props} refreshCheckins={refreshCheckins} onUpdateGuests={onUpdateGuests} onAddCheckin={onAddCheckin} onUpdateCheckin={onUpdateCheckin} onEditCheckin={onEditCheckin} onDeleteCheckin={onDeleteCheckin} onReCreateGuest={onReCreateGuest} eVisitorStaticData={eVisitorStaticData} checkins={checkins} guests={guests} evaccounts={evaccounts} onEditGuest={onEditGuest} appStatus={appStatus} setApiErrors={setApiErrors} apiErrors={apiErrors} apiMessages={apiMessages} />
                    } />
                    <Route path="/guest/:id" render={
                        props => <Guest {...props} refreshCheckins={refreshCheckins} onEditGuest={onEditGuest} onDeleteGuest={onDeleteGuest} checkins={checkins} guests={guests} eVisitorStaticData={eVisitorStaticData} appStatus={appStatus} apiErrors={apiErrors} apiMessages={apiMessages} />
                    } />
                    <Route path="/tourists" render={props =>
                        <Tourists {...props} fetchAndSetData={fetchAndSetData} refreshCheckins={refreshCheckins} evaccounts={evaccounts} checkins={checkins} guests={guests} eVisitorStaticData={eVisitorStaticData} appStatus={appStatus} apiErrors={apiErrors} apiMessages={apiMessages} />
                    } />
                    <Route path="/shared-guest/:id" render={
                        props => <SharedGuest {...props} refreshCheckins={refreshCheckins} onEditGuest={onEditGuest} onDeleteGuest={onDeleteGuest} checkins={checkins} guests={guests} eVisitorStaticData={eVisitorStaticData} appStatus={appStatus} apiErrors={apiErrors} apiMessages={apiMessages} />
                    } />
                    <Route path="/settings" render={props =>
                        <Settings {...props} />
                    } />
                    <Route path="/" render={props =>
                        <CheckIns {...props} fetchAndSetData={fetchAndSetData} refreshCheckins={refreshCheckins} evaccounts={evaccounts} checkins={checkins} guests={guests} eVisitorStaticData={eVisitorStaticData} appStatus={appStatus} apiErrors={apiErrors} apiMessages={apiMessages} />
                    } />
                </Switch>
            </BrowserRouter>
        </MuiThemeProvider>)
}

function MainAppPathRouter(props) {
    return <TheRouter {...props} />
}

export default MainAppPathRouter