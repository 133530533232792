import { DateTime } from "luxon";

export const DATE_FORMAT = "dd.MM.yyyy";

export const getDateFromSql = (sqlString) => DateTime.fromSQL(sqlString);

//"YYYYMMDD" format
export const getDate = (str) => {
    if (str) {
        const y = str.substr(0, 4),
            m = str.substr(4, 2) - 1,
            d = str.substr(6, 2);
        const D = new Date(y, m, d);
        return (D.getFullYear() == y && D.getMonth() == m && D.getDate() == d) ? D : null;
    }
    return null;
}

export const getCalendarDate = (date) => {
    let month = 'X',
        day = 'X';
    if (date) {
        month = date.toLocaleString('default', { month: 'short' });
        day = date.getDate();
    }
    return { month, day };
}

// 23:45 => Date()
export const getDateFromTime = (time) => {
    const timeParts = time.split(":");
    const d = new Date();
    d.setHours(timeParts[0]);
    d.setMinutes(timeParts[1]);
    return d;
}

// 20210311 => Date()
export const getDateFromDateString = (dateString) => DateTime.fromFormat(dateString, "yyyyMMdd").toJSDate();

// 20210311 => Date()
export const getFormattedDateFromDateString = (dateString) => getDateFromDateString(dateString).toLocaleDateString("hr-HR").replace(/\s/g, '');

// Date() => 20210311
export const getStringDate = (date) => DateTime.fromJSDate(date).toFormat("yyyyMMdd");

// Date() => 20210311
export const getStringTime = (date) => DateTime.fromJSDate(date).toFormat("HH:mm");

// now() => 20210311
export const getNowDate = () => DateTime.now().toFormat("yyyyMMdd");

// now() + 1 day => 20210411
export const getTomorrowDate = () => DateTime.now().plus({ days: 1 }).toFormat("yyyyMMdd");

// now() => 23:45
export const getNowTime = () => DateTime.now().toFormat("HH:mm");

// Date() diff now()
export const diffDateBirth = (birthDate) => DateTime.fromJSDate(birthDate).diff(DateTime.now(), ["years"]).toObject()

// Date() diff now()
export const diffDateBirthYears = (birthDate) => {
    const diff = diffDateBirth(birthDate);
    return Math.round(Math.abs(diff['years']));
}

// 12:00 / 17:00 => /Date(1596967200000+0200)/
export const getCheckInOutTime = (checkInOut) => {
    const regExp = /\d{2}/g;
    const matches = checkInOut.match(regExp);
    if (matches && matches.length === 4) {
        const checkinDate = new Date();
        checkinDate.setHours(matches[0], matches[1], 0);
        const checkoutDate = new Date();
        checkoutDate.setHours(matches[2], matches[3], 0);
        return { checkinTime: `/Date(${checkinDate.getTime()}+0200)/`, checkoutTime: `/Date(${checkoutDate.getTime()}+0200)/` };
    }
    return { checkinTime: null, checkoutTime: null };
}

// .NET /Date(1530144000000+0530)/ => JS Date
export const getDateFromAspNetFormat = (date) => {
    const re = /-?\d+/;
    const m = re.exec(date);
    return new Date(parseInt(m[0], 10));
}

// .NET /Date(1530144000000+0530)/ => 10.08.2021 14:54:00 
export const getDateFromAspNetFormatToFormatted = (date) =>
    date ? DateTime.fromJSDate(getDateFromAspNetFormat(date)).toFormat("dd.MM.yyyy HH:mm:ss") : null;

export const unixTimeToFormattedDate = (unixTime) => {
    const date = new Date(unixTime);
    // Hours part from the timestamp
    const hours = date.getHours();
    // Minutes part from the timestamp
    const minutes = "0" + date.getMinutes();
    // Seconds part from the timestamp
    const seconds = "0" + date.getSeconds();

    const year = date.getFullYear();
    const day = date.getDate();
    const month = date.getMonth();

    // Will display time in 12/02/2019 10:30:23 format
    const formattedTime = day + "/" + month + "/" + year + " " + hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    return formattedTime;
}

export const compareNames = (a, b) => {
    if (a.songName < b.songName)
        return -1;
    if (a.songName > b.songName)
        return 1;
    return 0;
}

export const compareDates = (a, b) => {
    if (a.dateCreated > b.dateCreated)
        return -1;
    if (a.dateCreated < b.dateCreated)
        return 1;
    return 0;
}
