import { makeStyles } from '@material-ui/core/styles';
import { isSafari } from '../../helpers/Utils';

export const useStyles = makeStyles(theme => ({
    root: props => ({
        flexGrow: 1,
        //height: 250,
        alignItems: 'center',
        '& .monthName': {
            color: props.monthNameColor,
            backgroundColor: props.monthBackgroundColor,
            boxShadow: `0 2px 0 ${props.monthBackgroundColor};`
        },
        '& .monthDay': {
            fontSize: props.monthDaySize
        },
        width: props.width,
        height: props.height,
        marginRight: '1.6em'
    }),
}));

export const prepareStyles = (size, secondary) => {
    const m8 = isSafari ? '5em' : '8em';
    const m5 = isSafari ? '3.5em' : '5em';
    const m9 = isSafari ? '6em' : '9em';
    const m7 = isSafari ? '5em' : '7em';
    const m4 = isSafari ? '4em' : '4em';
    const m3 = isSafari ? '4em' : '3.5em';

    const width = (size === 'big') ? m8 : m5;
    const height = (size === 'big') ? m9 : m7;
    const monthDaySize = (size === 'big') ? m4 : m3;
    const monthColor = secondary ? '#fff' : '#fff';
    const monthBackgroundColor = secondary ? 'rgba(0, 0, 0, 0.54)' : '#19aaaa';
    const styleProps = {
        monthNameColor: monthColor,
        monthBackgroundColor: monthBackgroundColor,
        monthDaySize: monthDaySize,
        width: width,
        height: height
    };
    return styleProps;
};