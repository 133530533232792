import React, { useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import Drawer from '../drawers/Drawer';
import useStyles from './PrimaryListNavBar.styles';
import { isLoggedIn } from '../../helpers/Utils';

const PrimarySearchAppBar = props => {
  const classes = useStyles();
  const { history, showMenu = true, headerTitle } = props;
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const handleDrawerOpen = () => setOpenDrawer(true)
  const handleDrawerClose = () => setOpenDrawer(false)
  const drawerTrigger = () => handleDrawerClose();

  const searchByFilter = (event) => {
    //const typedValue = event.target.value;
  };

  // https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
  useEffect(() => {
    let isMounted = true;
    isLoggedIn().then(loggedIn => {
      isMounted && loggedIn && setIsAdmin(true);
    })
    return () => { isMounted = false };
  }, []);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          {showMenu &&
            <IconButton className={classes.menuButton} onClick={handleDrawerOpen} color="inherit" aria-label="Open drawer">
              <MenuIcon />
            </IconButton>
          }
          <Typography className={classes.title} variant="h6" color="inherit" noWrap>
            {headerTitle}
          </Typography>
          <div className={classes.search} hidden>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              onChange={(e) => { searchByFilter(e) }}
            />
          </div>
          <div className={classes.grow} />
        </Toolbar>
      </AppBar>
      <Drawer opendrawer={openDrawer} history={history} drawertrigger={drawerTrigger} />
    </div>
  );
}

export default PrimarySearchAppBar;