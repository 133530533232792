import React, { useEffect } from 'react';
import HomeIcon from '@material-ui/icons/Home';
import HotelIcon from '@material-ui/icons/Hotel';
import PeopleIcon from '@material-ui/icons/People';
import useStyles from './CheckinHeader.styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { DATE_FORMAT, getDateFromDateString, getDateFromTime, getStringDate, getStringTime } from '../../helpers/DateHelper';
import { removeParanthesisText } from '../../helpers/Utils';
import Grid from '@material-ui/core/Grid';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    TimePicker,
    DatePicker,
} from '@material-ui/pickers';
import { Divider } from '@material-ui/core';
import { CustomizedDateTextField, CustomizedTimeTextField } from '../components/TextFields';
import {
    STAY_FROM_FIELD, TIME_STAY_FROM_FIELD,
    FORESEEN_STAY_UNTIL_FIELD, TIME_ESTIMATED_STAY_UNTIL_FIELD, ADDITIONAL_FIELDS,
    NAME, FACILITY_FIELD, NAME_CODE, EVISITOR, ARRIVAL_DATE_LABEL, ARRIVAL_TIME_LABEL,
    DEPARTURE_TIME_LABEL, DEPARTURE_DATE_LABEL
} from '../../constants/strings-and-fields';
import { Controller } from "react-hook-form";
import { useTranslation } from 'react-i18next';

const CheckinHeader = props => {
    const { checkin, register, control, setValue, errors, isAdmin } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    useEffect(() => {
        if (checkin) {
            Object.keys(checkin).map(key => {
                register(key);
                setValue(key, checkin[key]);
                return true;
            })
        }
    }, [checkin]); // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeDate = (date, field) => {
        setValue(field, getStringDate(date));
    }

    const onChangeTime = (date, field) => {
        setValue(field, getStringTime(date));
    }

    const facility = checkin?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD]?.[NAME];
    const facilityText = isAdmin ? facility : removeParanthesisText(facility);

    return (
        <div className={classes.root}>
            <div >
                <Box display="flex" flexDirection="column" >
                    <Box className={classes.headerBox} >
                        <Typography gutterBottom variant="body2">
                            <PeopleIcon className={classes.icon} /> {checkin?.[ADDITIONAL_FIELDS]?.[EVISITOR]?.[NAME]}
                        </Typography>
                        <Typography gutterBottom variant="body2">
                            <HomeIcon className={classes.icon} /> {facilityText}
                        </Typography>
                        <Typography gutterBottom variant="body2" >
                            <HotelIcon className={classes.icon} /> {checkin?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD]?.[NAME_CODE]}
                        </Typography>
                    </Box>
                    <Divider />
                    <Box className={classes.headerDateBox}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <form noValidate autoComplete="off" >
                                <Grid container >
                                    <Grid item xs={5}>
                                        <Controller
                                            name={STAY_FROM_FIELD}
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) =>
                                                <DatePicker
                                                    disabled
                                                    className={classes.datePicker}
                                                    margin="normal"
                                                    id={STAY_FROM_FIELD}
                                                    label={t(ARRIVAL_DATE_LABEL)}
                                                    format={DATE_FORMAT}
                                                    value={getDateFromDateString(field.value ?? "")}
                                                    onChange={(d) => onChangeDate(d, STAY_FROM_FIELD)}
                                                    TextFieldComponent={CustomizedDateTextField(classes)}
                                                    error={errors[STAY_FROM_FIELD] ? true : false}
                                                    helperText={errors[STAY_FROM_FIELD] ? errors[STAY_FROM_FIELD].message : null}
                                                />
                                            }
                                        />
                                    </Grid>
                                    {isAdmin &&
                                        <Grid item xs={4}>
                                            <Controller
                                                name={TIME_STAY_FROM_FIELD}
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) =>
                                                    <TimePicker
                                                        disabled
                                                        className={classes.datePicker}
                                                        margin="normal"
                                                        id={TIME_STAY_FROM_FIELD}
                                                        label={t(ARRIVAL_TIME_LABEL)}
                                                        value={getDateFromTime(field.value ?? "")}
                                                        ampm={false}
                                                        onChange={(d) => onChangeTime(d, TIME_STAY_FROM_FIELD)}
                                                        TextFieldComponent={CustomizedTimeTextField(classes)}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    }
                                    <Grid item xs={5}>
                                        <Controller
                                            name={FORESEEN_STAY_UNTIL_FIELD}
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) =>
                                                <DatePicker
                                                    disabled
                                                    className={classes.datePicker}
                                                    margin="normal"
                                                    id={FORESEEN_STAY_UNTIL_FIELD}
                                                    label={t(DEPARTURE_DATE_LABEL)}
                                                    format={DATE_FORMAT}
                                                    value={getDateFromDateString(field.value ?? "")}
                                                    onChange={(d) => onChangeDate(d, FORESEEN_STAY_UNTIL_FIELD)}
                                                    TextFieldComponent={CustomizedDateTextField(classes)}
                                                    error={errors[FORESEEN_STAY_UNTIL_FIELD] ? true : false}
                                                    helperText={errors[FORESEEN_STAY_UNTIL_FIELD] ? errors[FORESEEN_STAY_UNTIL_FIELD].message : null}
                                                />
                                            }
                                        />
                                    </Grid>
                                    {isAdmin &&
                                        <Grid item xs={4}>
                                            <Controller
                                                name={TIME_ESTIMATED_STAY_UNTIL_FIELD}
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) =>
                                                    <TimePicker
                                                        disabled
                                                        className={classes.datePicker}
                                                        margin="normal"
                                                        id={TIME_ESTIMATED_STAY_UNTIL_FIELD}
                                                        label={t(DEPARTURE_TIME_LABEL)}
                                                        value={getDateFromTime(field.value ?? "")}
                                                        ampm={false}
                                                        onChange={(d) => onChangeTime(d, TIME_ESTIMATED_STAY_UNTIL_FIELD)}
                                                        TextFieldComponent={CustomizedTimeTextField(classes)}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            </form>
                        </MuiPickersUtilsProvider>
                    </Box>
                </Box>
            </div>
        </div>
    );
}

export default CheckinHeader;