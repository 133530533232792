import React, { Suspense } from 'react'
//import logo from './logo.svg';
import { DataProvider } from './context/data-context';
import MainContext from './context/consumer-context';
import './i18n';

export default function App() {
  return (
    <>
      <Suspense fallback={null}>
        <DataProvider>
          <MainContext />
        </DataProvider>
      </Suspense>
    </>
  )
}