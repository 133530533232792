import React, { useEffect, useState } from 'react';
import useStyles from './CheckIn.styles';
import { useWindowDimensions } from '../../wrappers/withWindowDimensions';
import { VariableSizeList as List } from 'react-window';
import GuestItem from '../../lists/GuestItem';
import { BackAndSaveNavBar } from '../../navbars/BackAndSaveNavBar';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import ShareIcon from '@material-ui/icons/Share';
import AddIcon from '@material-ui/icons/Add';
import {
    STAY_FROM_FIELD, TIME_STAY_FROM_FIELD, ID,
    id, guests, content, status,
    FORESEEN_STAY_UNTIL_FIELD, TIME_ESTIMATED_STAY_UNTIL_FIELD, NOT_CHECKED_IN_STATUS,
    CHECKED_IN_STATUS, TOOLBAR_CHECKIN_TITLE, ADDITIONAL_FIELDS,
    CHECKIN_ID, DATE_CREATED, NAME, ACCOMODATION, NAME_CODE, PIN_ID,
    EVISITOR, FACILITY_FIELD, FACILITY, EV_ACCOUNT, DOWNLOAD_IN_PROGRESS,
    FACILITY_CODE, UPDATED_STATUS, TOURIST_NAME, TOURIST_SURNAME, CHECKED_OUT_STATUS,
    CANCELED_STATUS, CHECKIN_ACTION, CHECKOUT_ACTION, SETTLEMENTS_DATA,
    CANCEL_ACTION, DEFAULT_ARRIVAL_ORGANIZATION, DEFAULT_SERVICE_TYPE, ARRIVAL_ORGANIZATION,
    OFFERED_SERVICE_TYPE, PAYMENT_CATEGORY, CONFIRM_CANCEL_DIALOG, CONFIRM_CANCEL_TITLE,
    CHECKIN_UNKNOWN, ADD_GUEST, TITLE_NUMBER_OF_GUESTS, MAX_GUEST_NUMBER,
    COUNTRY_DATA, DOCUMENT_TYPE_DATA, STATUS, EXISTING_GUEST, NEW_GUEST, DATE_OF_BIRTH_FIELD,
    CHECKIN_BUTTON, CHECKIN_ALL, CANCEL_ALL, CHECKOUT_ALL, CONFIRM_GUEST_DELETE_TITLE,
    CONFIRM_GUEST_DELETE_DIALOG, CONFIRM_CHECKIN_DELETE_DIALOG, CONFIRM_CHECKIN_DELETE_TITLE,
    SELF_CHECKIN, CREATED_STATUS, CHECKED_IN_UPDATED_STATUS, RECORDS, CHECKOUT_OUT
} from '../../../constants/strings-and-fields';
import { getNowDate, getNowTime, getTomorrowDate } from '../../../helpers/DateHelper';
import { v4 as uuidv4 } from 'uuid';
import { findCheckin, sortDateTimes } from '../../../helpers/ArrayHelpers';
import CustomizedSnackbar from '../../components/Snackbar';
import { sleep } from '../../../helpers/PromiseHelpers';
import { cancelGuest, checkinGuest, checkoutGuest, deleteGuest, fetchTourist, updateGuest } from '../../../api/incheckinservice';
import { getPaymentCategory, isEmpty, isLoggedIn, mapTouristToGuest } from './../../../helpers/Utils';
import GuestNumberDialog from '../../components/GuestNumberDialog';
import CheckinHeader from '../../lists/CheckinHeader';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import schema from './Checkin.schema';
import LinkDialog from '../../components/LinkDialog';
import ConfirmDialog from '../../components/ConfirmDialog';
import PrimarySearchAppBar from '../../navbars/PrimaryListNavBar';
import CreateIcon from '@material-ui/icons/Create';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import CheckinActionsDialog from '../../components/CheckinActionsDialog';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import { useTranslation } from 'react-i18next';

const TOOLBAR_HEIGHT = 56;
const SLEEP_DURATION = 4000;
const API_MESSAGE_TIMEOUT = 5000;

const CheckIn = props => {
    const classes = useStyles();
    const { history, match, location, refreshCheckins, checkins, evaccounts, eVisitorStaticData, apiErrors, apiMessages, onUpdateGuests, onAddCheckin, onUpdateCheckin, onDeleteCheckin, onReCreateGuest } = props;
    const { t } = useTranslation();
    const [checkin, setCheckin] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [initalizing, setInitalizing] = useState(false);
    const { windowHeight, windowWidth } = useWindowDimensions();
    const [openGuestDialog, setOpenGuestDialog] = useState(false);
    const [openMessage, setOpenMessage] = useState(false);
    const [openCheckinErrorMessage, setOpenCheckinErrorMessage] = useState(false);
    const [openProgress, setOpenProgress] = useState(false);
    const [checkinErrors, setCheckinErrors] = useState(null);
    const [receivedTourist, setReceivedTourist] = useState(null);
    const [bottomNavigationValue, setBottomNavigationValue] = useState(-1);
    const [actionsCount, setActionsCount] = useState({ [CHECKIN_ACTION]: 0, [CHECKOUT_ACTION]: 0, [CANCEL_ACTION]: 0 });
    const [isOpenShare, setIsOpenShare] = useState(false);
    const [confirmCancelOpen, setConfirmCancelOpen] = useState(false);
    const [checkinError, setCheckinError] = useState(null);
    const [hiddenAddGuestFab, setHiddenAddGuestFab] = useState(false);
    const [openFab, setOpenFab] = useState(false);
    const [openCheckinActions, setOpenCheckinActions] = useState(false);
    const [openCheckoutActions, setOpenCheckoutActions] = useState(false);
    const [openCancelActions, setOpenCancelActions] = useState(false);
    const [selectedCheckinActions, setSelectedCheckinActions] = useState();
    const [isShareVisible, setIsShareVisible] = useState(false);
    const [isCancelVisible, setIsCancelVisible] = useState(false);
    const [isCheckoutVisible, setIsCheckoutVisible] = useState(false);
    const [isCheckinVisible, setIsCheckinVisible] = useState(false);
    const [confirmGuestDeleteOpen, setConfirmGuestDeleteOpen] = useState(false);
    const [deleteGuestData, setDeleteGuestData] = useState();
    const [confirmCheckinDeleteOpen, setConfirmCheckinDeleteOpen] = useState(false);
    const [runImport, setRunImport] = useState(true);

    const listWindow = windowHeight - TOOLBAR_HEIGHT;
    const listRef = React.createRef();
    let unlisten = null;

    const checkinId = match.params.id;
    if (checkinId === 'create') {
        // redirect to home because not all props are provided
        // eslint-disable-next-line no-unused-vars
        const _ = (location.isNewCheckin) ? true : history.replace('/');
        checkin && history.replace('/checkin/' + checkin[id]);
    }

    const countries = eVisitorStaticData && eVisitorStaticData?.[COUNTRY_DATA];
    const citiesSettlements = eVisitorStaticData && eVisitorStaticData?.[SETTLEMENTS_DATA];
    const documentTypes = eVisitorStaticData && eVisitorStaticData?.[DOCUMENT_TYPE_DATA];

    if (location.tourist && countries && documentTypes && checkin && !receivedTourist) {
        setReceivedTourist(location.tourist);
    }

    const { register, handleSubmit, control, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const getSize = i => 90;

    const onBack = () => history.goBack();

    const handleToggle = () => {
        setOpenProgress(!openProgress);
    };

    const handleOpenFab = () => {
        setOpenFab(true);
    };

    const handleCloseFab = () => {
        setOpenFab(false);
    };

    const handleCloseCheckoutActions = (value) => {
        setOpenCheckoutActions(false);
        setSelectedCheckinActions(value);
    };

    const handleCloseCheckinActions = (value) => {
        setOpenCheckinActions(false);
        setSelectedCheckinActions(value);
    };

    const handleCloseCancelActions = (value) => {
        setOpenCancelActions(false);
        setSelectedCheckinActions(value);
    };

    const onShareGuestClick = async () => {
        setIsOpenShare(true);
    }

    const onAddNewGuestClick = async () => {
        handleCloseFab();
        history.push({
            pathname: `/guest/create`,
            checkin: checkin,
            isNewGuest: true
        });
    }

    const onAddExistingGuestClick = async () => {
        handleCloseFab();
        const eVisitorPinId = checkin?.[ADDITIONAL_FIELDS]?.[EVISITOR]?.[PIN_ID];
        if (eVisitorPinId) {
            history.push({
                pathname: `/tourists`,
                checkinId: checkinId,
                eVisitorPinId: eVisitorPinId
            });
        }
    }

    const continueCancel = () => {
        const pin_id = checkin?.[ADDITIONAL_FIELDS]?.[EVISITOR]?.[PIN_ID];
        if (pin_id) {
            const executeComand = async () => {
                const filteredGuests = checkin?.[guests] ?? [];
                let currentGuest = null;
                let dataUpdated = false;
                setOpenProgress(true);
                for (let guest of filteredGuests) {
                    if (getForCancel(guest[status])) {
                        currentGuest = guest;
                        try {
                            dataUpdated = true;
                            await cancelGuest(pin_id, guest[content]);
                            await updateGuestStatus(guest, CANCELED_STATUS);
                        } catch (error) {
                            showErrors(error, guest);
                            break;
                        }
                    }
                }
                dataUpdated && await refreshCheckins(currentGuest);
                setOpenProgress(false);
            }
            executeComand().then(res => { })
        }
    }

    const getForCheckin = (guestStatus) =>
        [NOT_CHECKED_IN_STATUS, UPDATED_STATUS, CREATED_STATUS, CHECKED_IN_UPDATED_STATUS].includes(guestStatus);
    const getForCheckout = (guestStatus) => [CHECKED_IN_STATUS, CHECKED_IN_UPDATED_STATUS].includes(guestStatus);
    const getForCancel = (guestStatus) => [CHECKED_OUT_STATUS, CHECKED_IN_STATUS, CHECKED_IN_UPDATED_STATUS].includes(guestStatus);

    const setCheckinActionsCount = (checkin) => {
        const filteredGuests = checkin?.[guests] ?? [];
        const updatedActionsCount = { [CHECKIN_ACTION]: 0, [CHECKOUT_ACTION]: 0, [CANCEL_ACTION]: 0 };
        for (let guest of filteredGuests) {
            const guestStatus = guest[status];
            if (getForCheckout(guestStatus)) {
                updatedActionsCount[CHECKOUT_ACTION] += 1;
            }
            if (getForCheckin(guestStatus)) {
                updatedActionsCount[CHECKIN_ACTION] += 1;
            }
            if (getForCancel(guestStatus)) {
                updatedActionsCount[CANCEL_ACTION] += 1;
            }
        }
        setActionsCount(updatedActionsCount);
    }

    const onClickGuestNumber = () => {
        setOpenGuestDialog(true);
    }

    const updateGuestStatus = async (guest, updatedStatus) => {
        guest[status] = updatedStatus;
        guest[content] = {};
        await updateGuest(guest);
    }

    const updateGuestNumber = async (checkin, guestNumber) => {
        const filteredGuests = checkin?.[guests] ?? [];
        const currentGuestNumber = filteredGuests.length;
        if (currentGuestNumber !== 0 && (currentGuestNumber > guestNumber)) {
            filteredGuests.sort(sortDateTimes);
            const diff = currentGuestNumber - guestNumber;
            setOpenProgress(true);
            for (let i = 0; i < diff; i++) {
                await deleteGuest(filteredGuests.pop());
            }
            setOpenProgress(false);
        }
        checkin[ADDITIONAL_FIELDS][MAX_GUEST_NUMBER] = guestNumber;
        await onUpdateCheckin(checkin);
    }

    const onDeleteCheckinGuest = async (guest) => {
        setConfirmGuestDeleteOpen(true);
        setDeleteGuestData(guest);
    }

    const continueDeleteCheckinGuest = async () => {
        if (deleteGuestData) {
            setOpenProgress(true);
            try {
                await deleteGuest(deleteGuestData);
                await refreshCheckins(deleteGuestData);
            } catch (error) {
                setOpenProgress(false);
                showErrors(error, deleteGuestData);
            } finally {
                setOpenProgress(false);
                setDeleteGuestData(null);
            }
        }
    }

    const onDelete = () => {
        onDeleteCheckin(checkin);
        history.replace('/');
    }

    const onDeleteCheckinClick = () => {
        setConfirmCheckinDeleteOpen(true);
    }

    const generateMessage = () => {
        if (apiErrors) {
            return `${apiErrors?.signal}: ${apiErrors?.message}`;
        }
        if (apiMessages) {
            return `${apiMessages?.signal}: ${apiMessages?.message}`;
        }
        if (checkinErrors) {
            return `${checkinErrors?.type}: ${checkinErrors?.guest}  - ${checkinErrors?.description}`;
        }
    }

    const showErrors = (error, guest) => {
        if (error.response) {
            const { type, description } = error.response?.data;
            setCheckinErrors({ type: type, description: description, guest: `${guest[content][TOURIST_NAME]} ${guest[content][TOURIST_SURNAME]}` });
            setOpenCheckinErrorMessage(true);
        }
    }

    const syncTourists = async () => {
        const eVisitorPinId = checkin?.[ADDITIONAL_FIELDS]?.[EVISITOR]?.[PIN_ID];
        const checkinGuests = checkin?.[guests];
        if (eVisitorPinId && checkinGuests) {
            for (let guest of checkinGuests) {
                if (isEmpty(guest[content]) && guest[status] !== CANCELED_STATUS) {
                    setOpenProgress(true);
                    try {
                        const touristRes = await fetchTourist(eVisitorPinId, 1, 1, guest[id]);
                        if (touristRes) {
                            const touristData = touristRes[RECORDS][0];
                            if (touristData) {
                                const mappedGuest = mapTouristToGuest(touristData, countries, citiesSettlements, documentTypes);
                                await reCreateGuest({ ...mappedGuest, id: guest[id], [CHECKOUT_OUT]: touristData[CHECKOUT_OUT] }, checkin, onReCreateGuest);
                            }
                        }
                        setOpenProgress(false);
                    } catch (e) {
                        setOpenProgress(false);
                    }
                }
            }
        }
    }

    useEffect(() => {
        if (receivedTourist) {
            const uploadTourist = async (receivedTourist, countries, documentTypes, checkin, onUpdateGuests, setOpenProgress) => {
                setOpenProgress(true);
                const guest = mapTouristToGuest(receivedTourist, countries, citiesSettlements, documentTypes);
                try {
                    setOpenProgress(true);
                    await createGuest(guest, checkin, onUpdateGuests);
                } catch (e) {
                    console.log(e);
                } finally {
                    setOpenProgress(false);
                }
            }
            uploadTourist(receivedTourist, countries, documentTypes, checkin, onUpdateGuests, setOpenProgress);
        }
    }, [receivedTourist]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        isLoggedIn().then(loggedIn => {
            if (!loggedIn) history.replace('/login');
            loggedIn && setIsAdmin(true);
        })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        unlisten = history.listen((location, action) => {
            if (action === 'POP') {
                history.replace('/');
            }
        });
        return () => { unlisten(); };
    });

    useEffect(() => {
        if (checkins) {
            if (location.isNewCheckin && !initalizing && !checkin) {
                setInitalizing(true);
                initCheckin(location, setCheckin, onAddCheckin, onUpdateGuests).then(_ => {
                    console.log("New guest initalizied");
                }).catch(e => {
                    console.log("Could not initialize checkin");
                    console.log(e);
                }).finally(_ => { setInitalizing(false); })
            } else {
                const checkinData = findCheckin(checkins, checkin ? checkin[id] : checkinId);
                if (checkinData) {
                    setCheckin(checkinData);
                } else {
                    setCheckinError(t(CHECKIN_UNKNOWN));
                }
            }
        }
        setCheckinActionsCount(checkin);
    }, [checkins]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (checkin && runImport) {
            setRunImport(false);
            syncTourists();
        }
    }, [checkin]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (checkin) setCheckinActionsCount(checkin);
        if (!runImport) syncTourists();
    }, [JSON.stringify(checkin?.[guests])]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (checkin) {
            const guestNumber = checkin?.[guests]?.length;
            if (checkin?.[ADDITIONAL_FIELDS]?.[MAX_GUEST_NUMBER] === guestNumber) {
                setIsShareVisible(true)
                setHiddenAddGuestFab(true);
            } else {
                setIsShareVisible(false);
                setHiddenAddGuestFab(false);
            }
        }
    }, [checkin?.[ADDITIONAL_FIELDS]?.[MAX_GUEST_NUMBER]]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let _ = (actionsCount[CHECKIN_ACTION] > 0) ? setIsCheckinVisible(false) : setIsCheckinVisible(true);
        // eslint-disable-next-line no-unused-vars
        _ = (actionsCount[CHECKOUT_ACTION] > 0) ? setIsCheckoutVisible(false) : setIsCheckoutVisible(true);
        // eslint-disable-next-line no-unused-vars
        _ = (actionsCount[CANCEL_ACTION] > 0) ? setIsCancelVisible(false) : setIsCancelVisible(true);
        if (checkin) {
            const guestNumber = checkin?.[guests]?.length;
            if (checkin?.[ADDITIONAL_FIELDS]?.[MAX_GUEST_NUMBER] === guestNumber) {
                setIsShareVisible(true)
                setHiddenAddGuestFab(true);
            } else {
                setIsShareVisible(false);
                setHiddenAddGuestFab(false);
            }
        }
    }, [actionsCount]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedCheckinActions === CHECKIN_ALL) setBottomNavigationValue(1);
        if (selectedCheckinActions === CHECKOUT_ALL) setBottomNavigationValue(2);
        if (selectedCheckinActions === CANCEL_ALL) setBottomNavigationValue(3);
    }, [selectedCheckinActions])

    useEffect(() => {
        if (apiErrors) {
            console.log(apiErrors);
            setOpenMessage(true);
            sleep(SLEEP_DURATION).then(() => {
                refreshCheckins();
            })
        }
        apiMessages && setOpenMessage(true);
    }, [apiErrors, apiMessages]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const pin_id = checkin?.[ADDITIONAL_FIELDS]?.[EVISITOR]?.[PIN_ID];
        if (pin_id) {
            const executeComand = async () => {
                const filteredGuests = checkin?.[guests] ?? [];
                let currentGuest = null;
                let dataUpdated = false;
                // SHARE
                if (bottomNavigationValue === 0) {
                    onShareGuestClick();
                }
                // CHECKIN
                if (bottomNavigationValue === 1) {
                    if (actionsCount?.[CHECKIN_ACTION] > 0) {
                        setOpenProgress(true);
                        for (let guest of filteredGuests) {
                            if (getForCheckin(guest[status])) {
                                currentGuest = guest;
                                try {
                                    dataUpdated = true;
                                    await checkinGuest(pin_id, guest[content]);
                                    await updateGuestStatus(guest, CHECKED_IN_STATUS);
                                } catch (error) {
                                    showErrors(error, guest);
                                    break;
                                }
                            }
                        }
                        dataUpdated && await refreshCheckins(currentGuest);
                        setOpenProgress(false);
                    }
                }
                // CHECKOUT
                if (bottomNavigationValue === 2) {
                    if (actionsCount?.[CHECKOUT_ACTION] > 0) {
                        setOpenProgress(true);
                        for (let guest of filteredGuests) {
                            if (getForCheckout(guest[status])) {
                                currentGuest = guest;
                                try {
                                    dataUpdated = true;
                                    await checkoutGuest(pin_id, guest[content]);
                                    await updateGuestStatus(guest, CHECKED_OUT_STATUS);
                                } catch (error) {
                                    showErrors(error, guest);
                                    break;
                                }
                            }
                        }
                        dataUpdated && await refreshCheckins(currentGuest);
                        setOpenProgress(false);
                    }
                }
                // CANCEL
                if (bottomNavigationValue === 3) {
                    if (actionsCount?.[CANCEL_ACTION] > 0) {
                        setBottomNavigationValue(-1);
                        setConfirmCancelOpen(true);
                    }
                }
                // REFRESH
                if (bottomNavigationValue === 4) {
                    setOpenProgress(true);
                    await refreshCheckins();
                    setOpenProgress(false);
                }
                if (bottomNavigationValue !== -1) {
                    setBottomNavigationValue(-1);
                }
            }
            executeComand();
        }
    }, [bottomNavigationValue]); // eslint-disable-line react-hooks/exhaustive-deps

    if (checkin) {
        const filteredGuests = checkin?.[guests] ?? [];
        return (
            <div className={classes.root}>
                <BackAndSaveNavBar classes={classes} isAdmin={isAdmin} onBack={isAdmin ? onBack : null} onSave={null} onDelete={onDeleteCheckinClick} title={TOOLBAR_CHECKIN_TITLE} />
                <CheckinHeader checkin={checkin} history={history} isAdmin={isAdmin}
                    register={register} handleSubmit={handleSubmit} control={control} setValue={setValue} errors={errors} />
                <Divider />
                {isAdmin &&
                    <>
                        <Button
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className={classes.button}
                            onClick={onClickGuestNumber}
                            startIcon={<FormatListNumberedIcon />}
                        >{t(TITLE_NUMBER_OF_GUESTS)} : {checkin?.[ADDITIONAL_FIELDS]?.[MAX_GUEST_NUMBER]}</Button>
                        <GuestNumberDialog open={openGuestDialog} handleOpen={setOpenGuestDialog} updateGuestNumber={updateGuestNumber} checkin={checkin} />
                    </>
                }
                <List className="List"
                    ref={listRef}
                    height={listWindow}
                    itemCount={filteredGuests.length}
                    itemSize={getSize}
                    width={windowWidth}>
                    {({ index, style }) =>
                        <div style={style}>
                            <Divider />
                            <GuestItem
                                guest={filteredGuests[index]}
                                eVisitorPinId={checkin?.[ADDITIONAL_FIELDS]?.[EVISITOR]?.[PIN_ID]}
                                evaccounts={evaccounts}
                                history={history}
                                isAdmin={isAdmin}
                                onDeleteCheckinGuest={onDeleteCheckinGuest}
                            />
                            <Divider />
                        </div>
                    }
                </List>
                {isAdmin &&
                    <>
                        <AppBar position="fixed" color="primary" className={classes.appBar}>
                            <Toolbar>
                                <div hidden={isCheckinVisible}>
                                    <IconButton edge="start" color="inherit" aria-label="checkin-button" onClick={_ => setOpenCheckinActions(true)}>
                                        <Button startIcon={<GroupAddIcon />}
                                            variant="contained"
                                            color="secondary">
                                            {t(CHECKIN_BUTTON)} {actionsCount?.[CHECKIN_ACTION]}
                                        </Button>
                                    </IconButton>
                                </div>
                                <div hidden={isCheckoutVisible}>
                                    <IconButton className={classes.actionsButton}>
                                        <Avatar edge="start" className={classes.moreButton} onClick={_ => setOpenCheckoutActions(true)}>
                                            <FlightTakeoffIcon />
                                        </Avatar>
                                    </IconButton>
                                </div>
                                <div hidden={isCancelVisible}>
                                    <IconButton className={classes.actionsButton}>
                                        <Avatar edge="start" className={classes.moreButton} onClick={_ => setOpenCancelActions(true)}>
                                            <CancelPresentationIcon />
                                        </Avatar>
                                    </IconButton>
                                </div>
                                <div className={classes.grow} />
                                <div hidden={isShareVisible}>
                                    <IconButton edge="end" color="inherit" aria-label="forward-data-button" >
                                        <Button startIcon={<ShareIcon />}
                                            variant="contained"
                                            color="secondary"
                                            onClick={onShareGuestClick}>
                                            {SELF_CHECKIN}
                                        </Button>
                                    </IconButton>
                                </div>
                            </Toolbar>
                        </AppBar>
                        <SpeedDial
                            ariaLabel={t(ADD_GUEST)}
                            className={classes.speedDial}
                            hidden={hiddenAddGuestFab}
                            icon={<AddIcon />}
                            onClose={handleCloseFab}
                            onOpen={handleOpenFab}
                            open={openFab}
                        >
                            <SpeedDialAction
                                key={EXISTING_GUEST}
                                icon={<GroupAddIcon />}
                                tooltipTitle={t(EXISTING_GUEST)}
                                tooltipOpen
                                onClick={onAddExistingGuestClick}
                            />
                            <SpeedDialAction
                                key={NEW_GUEST}
                                icon={<CreateIcon />}
                                tooltipTitle={t(NEW_GUEST)}
                                tooltipOpen
                                onClick={onAddNewGuestClick}
                            />
                        </SpeedDial>

                        <LinkDialog isOpen={isOpenShare} setOpenShare={setIsOpenShare} checkin={checkin} />
                        <ConfirmDialog
                            title={t(CONFIRM_CANCEL_TITLE)}
                            open={confirmCancelOpen}
                            isDelete={true}
                            setOpen={setConfirmCancelOpen}
                            onConfirm={continueCancel}>
                            {t(CONFIRM_CANCEL_DIALOG)}
                        </ConfirmDialog>
                        <ConfirmDialog
                            title={t(CONFIRM_GUEST_DELETE_TITLE)}
                            open={confirmGuestDeleteOpen}
                            isDelete={true}
                            setOpen={setConfirmGuestDeleteOpen}
                            onConfirm={continueDeleteCheckinGuest}>
                            {t(CONFIRM_GUEST_DELETE_DIALOG)}
                        </ConfirmDialog>
                        <ConfirmDialog
                            title={CONFIRM_CHECKIN_DELETE_TITLE}
                            open={confirmCheckinDeleteOpen}
                            isDelete={true}
                            setOpen={setConfirmCheckinDeleteOpen}
                            onConfirm={onDelete}>
                            {CONFIRM_CHECKIN_DELETE_DIALOG}
                        </ConfirmDialog>
                        <CheckinActionsDialog
                            selectedCheckinActions={setSelectedCheckinActions}
                            open={openCheckinActions}
                            actionsCheckinCount={actionsCount?.[CHECKIN_ACTION]}
                            onClose={handleCloseCheckinActions} />
                        <CheckinActionsDialog
                            selectedCheckinActions={setSelectedCheckinActions}
                            open={openCheckoutActions}
                            actionsCheckoutCount={actionsCount?.[CHECKOUT_ACTION]}
                            onClose={handleCloseCheckoutActions} />
                        <CheckinActionsDialog
                            selectedCheckinActions={setSelectedCheckinActions}
                            open={openCancelActions}
                            actionsCancelCount={actionsCount?.[CANCEL_ACTION]}
                            onClose={handleCloseCancelActions} />
                    </>
                }
                <Backdrop className={classes.backdrop} open={openProgress} onClick={handleToggle}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <CustomizedSnackbar message={generateMessage()} isError={apiErrors} open={openMessage} handleOpen={setOpenMessage} timeout={API_MESSAGE_TIMEOUT} />
                <CustomizedSnackbar message={generateMessage()} isError={checkinErrors} open={openCheckinErrorMessage} handleOpen={setOpenCheckinErrorMessage} timeout={API_MESSAGE_TIMEOUT} />
            </div>
        );
    } else return (
        <>
            <PrimarySearchAppBar showMenu={false} isAdmin={isAdmin} {...props} />
            <div className={classes.root}>
                <Typography variant="h6" color="inherit" noWrap>
                    {checkinError ? checkinError : DOWNLOAD_IN_PROGRESS}
                </Typography>
                <CustomizedSnackbar message={generateMessage()} isError={apiErrors} open={openMessage} handleOpen={setOpenMessage} timeout={API_MESSAGE_TIMEOUT} />
            </div>
        </>
    );
}

const createGuest = async (guest, checkin, onUpdateGuests) => {
    const newGuests = [];
    const facilityCode = checkin?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD]?.[FACILITY_CODE];
    const newGuestId = uuidv4();
    newGuests.push({
        [id]: newGuestId,
        [CHECKIN_ID]: checkin[id],
        [STATUS]: UPDATED_STATUS,
        [content]: {
            ...guest,
            [FORESEEN_STAY_UNTIL_FIELD]: checkin[FORESEEN_STAY_UNTIL_FIELD] ?? getTomorrowDate(),
            [ID]: newGuestId,
            [STAY_FROM_FIELD]: checkin[STAY_FROM_FIELD] ?? getNowDate(),
            [PAYMENT_CATEGORY]: getPaymentCategory(guest[DATE_OF_BIRTH_FIELD]),
            [TIME_ESTIMATED_STAY_UNTIL_FIELD]: checkin[TIME_ESTIMATED_STAY_UNTIL_FIELD] ?? getNowTime(),
            [TIME_STAY_FROM_FIELD]: checkin[TIME_STAY_FROM_FIELD] ?? getNowTime(),
            [FACILITY]: facilityCode,
            [ARRIVAL_ORGANIZATION]: DEFAULT_ARRIVAL_ORGANIZATION,
            [OFFERED_SERVICE_TYPE]: DEFAULT_SERVICE_TYPE,
            [ADDITIONAL_FIELDS]: {
                [CHECKIN_ID]: checkin[id],
            }
        }
    });
    await onUpdateGuests(newGuests);
}

const createGuests = async (checkin, isNewCheckin, onAddCheckin) => {
    try {
        if (isNewCheckin) {
            await onAddCheckin(checkin);
        }
    } catch (e) {
        throw e;
    }
}

const initCheckin = async (location, setCheckin, onAddCheckin, onUpdateGuests) => {
    const newCheckinId = uuidv4();
    const facilityCode = location?.[FACILITY_FIELD]?.[FACILITY_CODE];
    const numberOfGuests = location?.numberOfGuests;
    const checkinTime = location?.checkinTime;
    const newCheckin = {
        [FORESEEN_STAY_UNTIL_FIELD]: checkinTime[FORESEEN_STAY_UNTIL_FIELD] ?? getTomorrowDate(),
        [id]: newCheckinId,
        [STAY_FROM_FIELD]: checkinTime[STAY_FROM_FIELD] ?? getNowDate(),
        [TIME_ESTIMATED_STAY_UNTIL_FIELD]: checkinTime[TIME_ESTIMATED_STAY_UNTIL_FIELD] ?? getNowDate(),
        [TIME_STAY_FROM_FIELD]: checkinTime[TIME_STAY_FROM_FIELD] ?? getNowDate(),
        [ADDITIONAL_FIELDS]: {
            [DATE_CREATED]: Date.now(),
            [EVISITOR]: {
                [NAME]: location?.[EV_ACCOUNT]?.[NAME],
                [PIN_ID]: location?.[EV_ACCOUNT]?.[PIN_ID]
            },
            [FACILITY_FIELD]: {
                [NAME]: location?.[FACILITY_FIELD]?.[NAME],
                [FACILITY_CODE]: facilityCode,
                [NAME_CODE]: location?.[FACILITY_FIELD]?.[NAME_CODE]
            },
            [ACCOMODATION]: {
                [NAME_CODE]: ""
            },
            [MAX_GUEST_NUMBER]: numberOfGuests
        }
    }
    setCheckin(newCheckin);
    await createGuests(newCheckin, true, onAddCheckin);

    return newCheckin;
}

const reCreateGuest = async (guest, checkin, onReCreateGuest) => {
    const status = guest[CHECKOUT_OUT] ? CHECKED_OUT_STATUS : CHECKED_IN_STATUS;
    const facilityCode = checkin?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD]?.[FACILITY_CODE];
    const recreatedGuest = {
        [id]: guest[id],
        [CHECKIN_ID]: checkin[id],
        [STATUS]: status,
        [content]: {
            ...guest,
            [FORESEEN_STAY_UNTIL_FIELD]: checkin[FORESEEN_STAY_UNTIL_FIELD] ?? getTomorrowDate(),
            [ID]: guest[id],
            [STAY_FROM_FIELD]: checkin[STAY_FROM_FIELD] ?? getNowDate(),
            [PAYMENT_CATEGORY]: getPaymentCategory(guest[DATE_OF_BIRTH_FIELD]),
            [TIME_ESTIMATED_STAY_UNTIL_FIELD]: checkin[TIME_ESTIMATED_STAY_UNTIL_FIELD] ?? getNowTime(),
            [TIME_STAY_FROM_FIELD]: checkin[TIME_STAY_FROM_FIELD] ?? getNowTime(),
            [FACILITY]: facilityCode,
            [ARRIVAL_ORGANIZATION]: DEFAULT_ARRIVAL_ORGANIZATION,
            [OFFERED_SERVICE_TYPE]: DEFAULT_SERVICE_TYPE,
            [ADDITIONAL_FIELDS]: {
                [CHECKIN_ID]: checkin[id],
            }
        }
    };
    await onReCreateGuest(recreatedGuest);
}

export default CheckIn;