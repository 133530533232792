import {
    COUNTRY_CODE, CODE, CODE_MI, id, RECORDS, guests,
    CREATED_AT, ACTIVE, SEQUENCE, DOCUMENT_TYPE_DATA, PAYMENT_CATEGORY_DATA
} from '../constants/strings-and-fields';

export const isNotEmpty = (list) => list && list.length > 0;

export const findCheckin = (checkins, checkinId) => (checkins) ? checkins.find(checkin => checkin[id] === checkinId) : null;

export const filterGuests = (guests, checkinId) => guests.filter(guest => guest?.additional_info?.checkin_id === checkinId);

export const getNumberOfGuests = (checkin) => checkin?.[guests]?.length;

export const findGuest = (guests, id) => (guests) ? guests.find(guest => guest.ID === id) : false;

export const findGuestInCheckin = (checkins, guestId) => {
    for (let checkin of checkins) {
        let guest = checkin?.[guests].find(guest => guest[id] === guestId);
        if (guest) return guest;
    }
    return false;
}

export const mapCountryCodeToCountry = (code, countries) => countries.find(country => country[COUNTRY_CODE] === code) ?? {};

export const mapTypeCodeToObject = (code, docs) => docs.find(doc => doc[CODE] === code) ?? {};

export const mapTypeCodeMIToObject = (code, docs) => docs.find(doc => doc[CODE_MI] === code) ?? {};

export const parseStaticData = (eVisitorStaticData) => {
    const staticData = {};
    if (eVisitorStaticData) {
        for (const data of eVisitorStaticData) {
            const DATA_KEY = Object.keys(data)[0];
            if (DATA_KEY) {
                staticData[DATA_KEY] = data[DATA_KEY];
            }
        }
    }
    staticData[DOCUMENT_TYPE_DATA] = staticData[DOCUMENT_TYPE_DATA].filter(documentType => documentType[ACTIVE] === true);
    staticData[DOCUMENT_TYPE_DATA].sort((a, b) => (a[SEQUENCE] > b[SEQUENCE]) ? 1 : -1);
    staticData[PAYMENT_CATEGORY_DATA] = staticData[PAYMENT_CATEGORY_DATA].filter(documentType => documentType[ACTIVE] === true);
    return staticData;
}

export const parseFacilityRecords = (data) => data?.[RECORDS];

export const sortDateTimes = (a, b) => (a[CREATED_AT] < b[CREATED_AT]) ? -1 : (a[CREATED_AT] > b[CREATED_AT]) ? 1 : 0;

export const range = (start, end) => Array(end - start + 1).fill().map((_, idx) => start + idx)

