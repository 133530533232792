import * as yup from 'yup';
import { getDateFromDateString, getNowDate, getNowTime } from '../../../helpers/DateHelper';
import {
    TOURIST_NAME, TOURIST_SURNAME, DOCUMENT_NUMBER, GENDER_FIELD, DATE_OF_BIRTH_FIELD,
    CITIZENSHIP, COUNTRY_OF_RESIDENCE, COUNTRY_OF_BIRTH, CITY_OF_RESIDENCE, DOCUMENT_TYPE,
    PAYMENT_CATEGORY, OFFERED_SERVICE_TYPE, ARRIVAL_ORGANIZATION, TIME_ESTIMATED_STAY_UNTIL_FIELD,
    TIME_STAY_FROM_FIELD, STAY_FROM_FIELD, FORESEEN_STAY_UNTIL_FIELD, GENDERS, FACILITY, ID,
    TOURIST_MIDDLE_NAME, FACILITY_CODE, FACILITY_FIELD,
    CHECKIN_ID,
    ADDITIONAL_FIELDS,
    content, id, DEFAULT_SERVICE_TYPE, DEFAULT_ARRIVAL_ORGANIZATION, STATUS, UPDATED_STATUS
} from '../../../constants/strings-and-fields';

export const schema = yup.object().shape({
    [TOURIST_NAME]: yup.string().required(),
    [TOURIST_SURNAME]: yup.string().required(),
    [DOCUMENT_NUMBER]: yup.string().required(),
    [GENDER_FIELD]: yup.string().required(),
    [DATE_OF_BIRTH_FIELD]: yup.string().required(),
    [CITIZENSHIP]: yup.string().required(),
    [COUNTRY_OF_RESIDENCE]: yup.string().required(),
    [COUNTRY_OF_BIRTH]: yup.string().required(),
    [CITY_OF_RESIDENCE]: yup.string().required(),
    [DOCUMENT_TYPE]: yup.string().required(),
    [PAYMENT_CATEGORY]: yup.string().required(),
    [OFFERED_SERVICE_TYPE]: yup.string().required(),
    [ARRIVAL_ORGANIZATION]: yup.string().required(),
    [TIME_ESTIMATED_STAY_UNTIL_FIELD]: yup.string().required(),
    [TIME_STAY_FROM_FIELD]: yup.string().required(),
    [STAY_FROM_FIELD]: yup.string()
        .test({
            name: 'dateBefore',
            exclusive: false,
            params: {},
            /* eslint-disable no-template-curly-in-string */
            message: '${path} must be before the stay until date',
            test: function (value) {
                const fromDate = getDateFromDateString(value);
                const toDate = getDateFromDateString(this.parent[FORESEEN_STAY_UNTIL_FIELD]);
                return (fromDate < toDate) ? true : false;
            },
        }),
    [FORESEEN_STAY_UNTIL_FIELD]: yup.string()
        .test({
            name: 'dateAfter',
            exclusive: false,
            params: {},
            message: '${path} must be after the stay from date',
            test: function (value) {
                const fromDate = getDateFromDateString(this.parent[STAY_FROM_FIELD]);
                const toDate = getDateFromDateString(value);
                return (toDate > fromDate) ? true : false;
            },
        }),
}, [STAY_FROM_FIELD, FORESEEN_STAY_UNTIL_FIELD]);

// Muško -> Male
export const mapGender = (gender) => {
    const keys = Object.keys(GENDERS);
    for (const key of keys) {
        if (GENDERS[key] === gender) return key;
    }
    // default
    return "";
}

export const initNewGuest = (guestId, checkin) => {
    const facilityCode = checkin?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD]?.[FACILITY_CODE];
    return {
        [CHECKIN_ID]: checkin[id],
        [id]: guestId,
        [STATUS]: UPDATED_STATUS,
        [content]: {
            [ARRIVAL_ORGANIZATION]: DEFAULT_ARRIVAL_ORGANIZATION,
            [CITIZENSHIP]: "",
            [CITY_OF_RESIDENCE]: "",
            [COUNTRY_OF_BIRTH]: "",
            [COUNTRY_OF_RESIDENCE]: "",
            [DATE_OF_BIRTH_FIELD]: "",
            [DOCUMENT_NUMBER]: "",
            [DOCUMENT_TYPE]: "",
            [FACILITY]: facilityCode,
            [FORESEEN_STAY_UNTIL_FIELD]: checkin[FORESEEN_STAY_UNTIL_FIELD] || getNowDate(),
            [GENDER_FIELD]: "",
            [ID]: guestId,
            [OFFERED_SERVICE_TYPE]: DEFAULT_SERVICE_TYPE,
            [STAY_FROM_FIELD]: checkin[STAY_FROM_FIELD] || getNowDate(),
            [PAYMENT_CATEGORY]: "",
            [TIME_ESTIMATED_STAY_UNTIL_FIELD]: checkin[TIME_ESTIMATED_STAY_UNTIL_FIELD] || getNowTime(),
            [TIME_STAY_FROM_FIELD]: checkin[TIME_STAY_FROM_FIELD] || getNowTime(),
            [TOURIST_MIDDLE_NAME]: "",
            [TOURIST_NAME]: "",
            [TOURIST_SURNAME]: "",
            [ADDITIONAL_FIELDS]: {
                [CHECKIN_ID]: checkin[ID],
            }
        }
    }
}

/*
    "ArrivalOrganisation": "I",
    "Citizenship": "HRV",
    "CityOfResidence": "Osijek",
    "CountryOfBirth": "HRV",
    "CountryOfResidence": "HRV",
    "DateOfBirth": "19910130",
    "DocumentNumber": "1235615649",
    "DocumentType": "051",
    "Facility": "0033302",
    "ForeseenStayUntil": "20210520",
    "Gender": "Muški",
    "ID": "6ac7fe96-f3f6-46b7-a85c-ff6f3e642655",
    "OfferedServiceType": "Noćenje",
    "StayFrom": "20210514",
    "TTPaymentCategory": "14",
    "TimeEstimatedStayUntil": "10:00",
    "TimeStayFrom": "23:45",
    "TouristMiddleName": "",
    "TouristName": "Mina",
    "TouristSurname": "Minić",
*/

export default schema;
