import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        //maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    icon: {
        position: "relative",
        top: theme.spacing(0.3),
        width: theme.typography.body2.fontSize,
        height: theme.typography.body2.fontSize
    },
    fromDateIcon: {
        width: 24,
        height: 24
    },
    toDateIcon: {
        width: 48,
        height: 48
    },
    flexJustifyEnd: {
        marginLeft: 'auto',
    },
    switch: {
        paddingTop: theme.spacing(1),
    }
}));

export default useStyles;