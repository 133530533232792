import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        flexGrow: 1,
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '30ch',
        },
        '& .MuiTextField-root .datePicker': {
            width: 100,
        },
        '& .MuiSelect-select': {
            margin: theme.spacing(1),
            width: '25ch',
            minWidth: 120,
            maxWidth: 300,
        },
    },
    datePicker: {
        width: '38vw!important',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    appbar: {
        flexGrow: 1,
    },
    speedDial: {
        position: 'fixed',
        bottom: theme.spacing(1),
        right: theme.spacing(3),
        left: 'auto',
        top: 'auto',
        transform: 'scale(0.8)'
    },
    button: {
        //margin: theme.spacing(),
    },
    label: {
        //margin: theme.spacing(),
        //marginTop: theme.spacing(0.5),
        paddingTop: 14,
        fontFamily: 'Menlo,Monaco,Monospace',
        fontWeight: 'bold',
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    paper: {
        paddingBottom: 100,
    },
}));

export default useStyles;