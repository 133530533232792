import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useStyles from './GuestNumberDialog.styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { TITLE_NUMBER_OF_GUESTS, OK, ADDITIONAL_FIELDS, MAX_GUEST_NUMBER, guests } from '../../constants/strings-and-fields';
import { range } from '../../helpers/ArrayHelpers';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { useTranslation } from 'react-i18next';

const maxGuestValue = 30;

const GuestNumberDialog = (props) => {
    const { open, handleOpen, checkin, updateGuestNumber } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const [numberOfGuests, setNumberOfGuests] = useState(1);

    const minGuestValue = checkin?.[guests]?.length ?? 1;

    const resetAllData = () => {
        handleOpen(false);
        setNumberOfGuests(checkin[ADDITIONAL_FIELDS][MAX_GUEST_NUMBER]);
    };

    const onClickConfirmGuestsNumber = () => {
        updateGuestNumber(checkin, numberOfGuests).then(_ => {
        }).catch(e => {
            console.log("Guest number dialog error");
            console.log(e);
        }).finally(_ => {
            resetAllData();
        })
    }

    useEffect(() => {
        setNumberOfGuests(checkin[ADDITIONAL_FIELDS][MAX_GUEST_NUMBER]);
    }, [checkin]);

    return (
        <div>
            <Dialog
                open={open}
                onClose={resetAllData}
                aria-labelledby="form-dialog-title"
                aria-describedby="form-dialog-description"
            >
                <DialogTitle id="form-dialog-title" >{t(TITLE_NUMBER_OF_GUESTS)}</DialogTitle>
                <DialogContent>
                    <div className={classes.root}>
                        <InputLabel id="number-of-guests-select-label" className={classes.guestNumberLabel}>{t(TITLE_NUMBER_OF_GUESTS)}</InputLabel>
                        <Select
                            className={classes.guestNumberInput}
                            autoFocus
                            margin="dense"
                            id="number-of-guests"
                            fullWidth
                            value={numberOfGuests}
                            onChange={e => setNumberOfGuests(Number(e.target.value))}
                        >
                            {range(minGuestValue, maxGuestValue).map(val => <MenuItem value={val} key={val}>{val}</MenuItem>)}
                        </Select>
                    </div>
                </DialogContent>
                <DialogActions >
                    <Button onClick={onClickConfirmGuestsNumber} color="primary">
                        {OK}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default GuestNumberDialog;