import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControlLabelInline: {
        margin: 0,
        paddingTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
    },
    selectLangInline: {
        margin: 0,
        paddingTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        width: '15ch',
    },
}));

export default useStyles;