import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import HomeIcon from '@material-ui/icons/Home';
import useStyles from './TwoStepChooserDialog.styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
    ACCOMODATION_OBJECT, CHOOSE_EVACCOUNT_TITLE, CODE, NAME,
    FACILITY_CODE, NAME_CODE, CHOOSE_FACILITY_TITLE, ERROR_ENTER_NUMBER_OF_GUESTS,
    ID, TITLE_NUMBER_OF_GUESTS, ACCOMODATION_UNIT, OK
} from '../../constants/strings-and-fields';
import DateTimePickers from './DateTimePickers';
import { range } from '../../helpers/ArrayHelpers';
import { useTranslation } from 'react-i18next';

const minGuestValue = 1;
const maxGuestValue = 30;

const TwoStepChooserDialog = (props) => {
    const { open, handleOpen, history, firstListData } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const [isFirstStep, setIsFirstStep] = useState(true);
    const [chosenEvAccount, setChosenEvAccount] = useState(null);
    const [chosenFacility, setChosenFacility] = useState(null);
    const [dialogHasError, setDialogHasError] = useState(false);
    const [openGuestNumberDialog, setOpenGuestNumberDialog] = useState(true);
    const [checkinDateTimes, setCheckinDateTimes] = useState({});
    const [numberOfGuests, setNumberOfGuests] = useState(minGuestValue);

    const filteredFacilities = chosenEvAccount ? chosenEvAccount?.[ACCOMODATION_OBJECT] : [];
    for (let facility of filteredFacilities) {
        const parentObject = chosenEvAccount[ACCOMODATION_UNIT].find(unit => facility[FACILITY_CODE] === unit[CODE]);
        if (parentObject) {
            facility[NAME] = parentObject[NAME];
        }
    }

    const onClickEvAccountItem = (evaccount) => {
        setChosenEvAccount(evaccount);
        setIsFirstStep(false);
    }

    const onClickFacilityItem = (facility) => {
        if (facility) {
            setChosenFacility(facility);
            handleGuestDialogOpen();
        }
    }

    const resetAllData = () => {
        handleOpen();
        setIsFirstStep(true);
        setChosenEvAccount(null);
        setChosenFacility(null);
        setNumberOfGuests(minGuestValue);
        handleGuestDialogClose();
        setDialogHasError(false);
        setCheckinDateTimes({});
    };

    const handleGuestDialogOpen = () => {
        setOpenGuestNumberDialog(false);
    };

    const handleGuestDialogClose = () => {
        setOpenGuestNumberDialog(true);
    };

    const onClickConfirmGuestsNumber = () => {
        if (validateGuests(numberOfGuests) && checkinDateTimes?.valid) {
            history.push({
                pathname: `/checkin/create`,
                facility: chosenFacility,
                evAccount: chosenEvAccount,
                numberOfGuests: numberOfGuests,
                isNewCheckin: true,
                checkinTime: checkinDateTimes
            });
        }
    }

    const validateGuests = (guests) => {
        if (Number(guests) >= minGuestValue && Number(guests) <= maxGuestValue) {
            setDialogHasError(false);
            return true;
        } else {
            setDialogHasError(true);
            return false;
        }
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={resetAllData}
                aria-labelledby="form-dialog-title"
                aria-describedby="form-dialog-description"
            >
                <DialogTitle id="form-dialog-title" hidden={!openGuestNumberDialog}>{isFirstStep ? t(CHOOSE_EVACCOUNT_TITLE) : t(CHOOSE_FACILITY_TITLE)}</DialogTitle>
                <DialogContent hidden={!openGuestNumberDialog}>
                    <div className={classes.root}>
                        <List component="nav" aria-label="choose">
                            {isFirstStep ? firstListData.map(data =>
                                <ListItem key={data?.[ID]} button onClick={_ => onClickEvAccountItem(data)}>
                                    <ListItemIcon>
                                        <HomeIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={data?.[NAME]} />
                                </ListItem>)
                                : filteredFacilities.map(data =>
                                    <ListItem key={data?.[ID]} button onClick={_ => onClickFacilityItem(data)}>
                                        <ListItemIcon>
                                            <HomeIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={data?.[NAME] + " " + data?.[NAME_CODE]} />
                                    </ListItem>)
                            }
                        </List>
                        <Divider />
                    </div>
                </DialogContent>

                <DialogContent hidden={openGuestNumberDialog}>
                    <div className={classes.root}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="number-of-guests-select-label" className={classes.guestNumberLabel}>{t(TITLE_NUMBER_OF_GUESTS)}</InputLabel>
                            <Select
                                className={classes.guestNumberInput}
                                autoFocus
                                margin="dense"
                                id="number-of-guests"
                                fullWidth
                                value={numberOfGuests}
                                onChange={e => setNumberOfGuests(Number(e.target.value))}
                            >
                                {range(minGuestValue, maxGuestValue).map(val => <MenuItem value={val} key={val}>{val}</MenuItem>)}
                            </Select>
                            <FormHelperText id={'guest-number-error'}
                                error={dialogHasError}>
                                {dialogHasError ? t(ERROR_ENTER_NUMBER_OF_GUESTS) : ""}
                            </FormHelperText>
                        </FormControl>
                        <DateTimePickers setCheckinDateTimes={setCheckinDateTimes} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClickConfirmGuestsNumber} color="primary" disabled={openGuestNumberDialog}>
                        {OK}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default TwoStepChooserDialog;