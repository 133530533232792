import {
    PIN_ID, RECORDS, ACCOMODATION_OBJECT, ACCOMODATION_UNIT, id,
    ADDITIONAL_FIELDS, ID, CHECKOUT_DATE, CHECKOUT_TIME,
    AUTO_TOURIST_CHECKOUT, CHECKIN_CHECKOUT, EMAIL_FIELD, CODE,
    FACILITY_ID, FACILITY_CODE, CHECKIN_FIELD, CHECKOUT_FIELD
} from '../constants/strings-and-fields';
import restClient, { API_VER, BASE_URL, storeResponseData } from './restClient';
import { clearUserData } from '../helpers/Utils';
import { getCheckInOutTime, getNowDate, getNowTime } from '../helpers/DateHelper';

export async function signUpUser(user) {
    const signUpRes = await restClient.post(`${BASE_URL}/${API_VER}/users`, user);
    storeResponseData(signUpRes, user);
    return signUpRes;
}

export async function loginUser(user) {
    const loginRes = await restClient.post(`${BASE_URL}/${API_VER}/auth`, user);
    storeResponseData(loginRes, user);
    return loginRes;
}

export async function logoutUser() {
    // maybe revoke tokens?
    clearUserData();
}

export async function addEVisitor(user) {
    const evisitorUsersRes = await restClient.post(`${BASE_URL}/${API_VER}/users/me/evisitor`, user);
    return evisitorUsersRes;
}

export async function deleteEVisitorAcc(eVisitorAccount) {
    return restClient.delete(`${BASE_URL}/${API_VER}/users/me/evisitor/${eVisitorAccount[PIN_ID]}`)
}

export async function fetchStaticData() {
    return restClient.get(`${BASE_URL}/${API_VER}/all-settlements`);
}

export async function fetchEvisitorAccounts() {
    const pinsRes = await restClient.get(`${BASE_URL}/${API_VER}/users/me/evisitor?pin_id=all`);
    const eVisitorsAccounts = [];
    for (let pin of pinsRes) {
        const pinId = pin?.[PIN_ID];
        const evisitorAccount = await restClient.get(`${BASE_URL}/${API_VER}/users/me/evisitor/${pinId}`);
        const accomodationObjects = await restClient.get(`${BASE_URL}/${API_VER}/users/me/evisitor/${pinId}/Rest/Htz/AccommodationUnitFacilityType`);
        const accomodationUnits = await restClient.get(`${BASE_URL}/${API_VER}/users/me/evisitor/${pinId}/Rest/Htz/FacilityTouristCheckInLookup`);

        for (let accomodationObject of accomodationObjects?.[RECORDS]) {
            try {
                const accomodationObjectCharacteristics = await fetchAccomodationObjectCharacetistics(pinId, accomodationObject[FACILITY_ID]);
                let idx = 0;
                for (let accUnit of accomodationUnits[RECORDS]) {
                    if (accUnit[CODE] === accomodationObject[FACILITY_CODE]) {
                        accomodationUnits[RECORDS][idx][AUTO_TOURIST_CHECKOUT] = accomodationObjectCharacteristics[RECORDS][0][AUTO_TOURIST_CHECKOUT];
                        accomodationUnits[RECORDS][idx][CHECKIN_CHECKOUT] = accomodationObjectCharacteristics[RECORDS][0][CHECKIN_CHECKOUT];
                        accomodationUnits[RECORDS][idx][EMAIL_FIELD] = accomodationObjectCharacteristics[RECORDS][0][EMAIL_FIELD];
                        break;
                    }
                    idx++;
                }
            } catch (e) {
                console.log(e);
            }
        }

        const dbEvisitor = evisitorAccount[RECORDS][0];
        dbEvisitor[PIN_ID] = pinId;
        dbEvisitor[ACCOMODATION_OBJECT] = accomodationObjects[RECORDS];
        dbEvisitor[ACCOMODATION_UNIT] = accomodationUnits[RECORDS];
        eVisitorsAccounts.push(dbEvisitor);
    }
    return eVisitorsAccounts;
}

export async function fetchCheckins(filter = 'all') {
    return restClient.get(`${BASE_URL}/${API_VER}/users/me/self-checkin/checkin/${filter}`)
}

export async function fetchGuests(filter = 'all') {
    return restClient.get(`${BASE_URL}/${API_VER}/users/me/self-checkin/guest/${filter}`)
}

export async function saveCheckin(checkin) {
    return restClient.post(`${BASE_URL}/${API_VER}/users/me/self-checkin/checkin`, checkin)
}

export async function updateCheckin(checkin) {
    return restClient.put(`${BASE_URL}/${API_VER}/users/me/self-checkin/checkin/${checkin[id]}`, checkin)
}

export async function deleteCheckin(checkin) {
    return restClient.delete(`${BASE_URL}/${API_VER}/users/me/self-checkin/checkin/${checkin[id]}`)
}

export async function saveGuest(guest) {
    return restClient.post(`${BASE_URL}/${API_VER}/users/me/self-checkin/guest`, guest)
}

export async function updateGuest(guest) {
    return restClient.put(`${BASE_URL}/${API_VER}/users/me/self-checkin/guest/${guest[id]}`, guest)
}

export async function createOrUpdateGuest(guest, create) {
    return (create) ? saveGuest(guest) : restClient.put(`${BASE_URL}/${API_VER}/users/me/self-checkin/guest/${guest[id]}`, guest);
}

export async function deleteGuest(guest) {
    return restClient.delete(`${BASE_URL}/${API_VER}/users/me/self-checkin/guest/${guest[id]}`)
}

export async function sendMail(email) {
    return restClient.post(`${BASE_URL}/${API_VER}/users/me/self-checkin/mail`, email)
}

// HTZ Checkin routes
export async function checkinGuest(pinId, guest) {
    delete guest[ADDITIONAL_FIELDS];
    return restClient.post(`${BASE_URL}/${API_VER}/users/me/evisitor/${pinId}/Rest/Htz/CheckInTourist/`, guest)
}

export async function checkoutGuest(pinId, guest) {
    const checkoutData = {
        [ID]: guest[ID],
        [CHECKOUT_DATE]: getNowDate(),
        [CHECKOUT_TIME]: getNowTime(),
    }
    return restClient.post(`${BASE_URL}/${API_VER}/users/me/evisitor/${pinId}/Rest/Htz/CheckOutTourist/`, checkoutData)
}

export async function cancelGuest(pinId, guest) {
    const cancelData = { [ID]: guest[ID] };
    return restClient.post(`${BASE_URL}/${API_VER}/users/me/evisitor/${pinId}/Rest/Htz/CancelTouristCheckIn/`, cancelData)
}

// filter: contains, startswith
export async function fetchTourists(pinId, pageSize = 40, page = 1, nameFilter = null) {
    const filter = nameFilter ? `&filters=[{"Property":"SurnameAndName","Operation":"contains","Value":"${nameFilter}"}]` : '';
    return restClient.get(`${BASE_URL}/${API_VER}/users/me/evisitor/${pinId}/Rest/Htz/ListOfTouristsExtended?psize=${pageSize}&page=${page}&sort=DateTimeOfArrival%20desc${filter}`)
}

export async function fetchTourist(pinId, pageSize = 1, page = 1, touristId = "b99fffed-d66e-45d4-a292-a42f8ce13ffd") {
    const filter = `&filters=[{"Property":"ID","Operation":"equal","Value":"${touristId}"}]`;
    return restClient.get(`${BASE_URL}/${API_VER}/users/me/evisitor/${pinId}/Rest/Htz/ListOfTouristsExtended?psize=${pageSize}&page=${page}&sort=DateTimeOfArrival%20desc${filter}`)
}

export async function fetchAccomodationObjectCharacetistics(pinId, propertyId) {
    const filter = `filters=[{"Property":"ID","Operation":"equal","Value":"${propertyId}"}]`;
    return restClient.get(`${BASE_URL}/${API_VER}/users/me/evisitor/${pinId}/Rest/Htz/FacilityCharacteristicsBrowse?${filter}`)
}

export async function updateAccomodationObjectCharacetistics(eVisitorUser, isAutoCheckoutActive) {
    for (let accUnit of eVisitorUser[ACCOMODATION_UNIT]) {
        const { checkinTime = null, checkoutTime = null } = getCheckInOutTime(accUnit?.[CHECKIN_CHECKOUT] ?? "10:00 / 17:00");
        const accomodationCharacteristics = {
            [ID]: accUnit[ID],
            [AUTO_TOURIST_CHECKOUT]: isAutoCheckoutActive,
            [CHECKIN_FIELD]: checkinTime ?? null,
            [CHECKOUT_FIELD]: checkoutTime ?? null,
            [EMAIL_FIELD]: accUnit[EMAIL_FIELD] ?? null
        };
        await postAccomodationObjectCharacetistics(eVisitorUser[PIN_ID], accomodationCharacteristics);
        const idx = eVisitorUser[ACCOMODATION_UNIT].findIndex(u => u[ID] === accUnit[ID]);
        if (idx !== -1) {
            eVisitorUser[ACCOMODATION_UNIT][idx][AUTO_TOURIST_CHECKOUT] = isAutoCheckoutActive;
        }
    }
}

export async function postAccomodationObjectCharacetistics(pinId, accomodationCharacteristics) {
    return restClient.post(`${BASE_URL}/${API_VER}/users/me/evisitor/${pinId}/Rest/Htz/SaveFacilityCharacteristics/`, accomodationCharacteristics)
}

// NOT USED
export async function fetchFacilities(pinId) {
    return restClient.get(`${BASE_URL}/${API_VER}/users/me/evisitor/${pinId}/Rest/Htz/AccommodationUnitFacilityType`)
}
// NOT USED
export async function fetchAccomodations(pinId) {
    return restClient.get(`${BASE_URL}/${API_VER}/users/me/evisitor/${pinId}/Rest/Htz/FacilityTouristCheckInLookup`);
}