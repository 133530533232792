import React, { useEffect } from 'react';
import useStyles from './DateTimePickers.styles';
import Box from '@material-ui/core/Box';
import { DATE_FORMAT, getDateFromDateString, getDateFromTime, getNowDate, getNowTime, getStringDate, getStringTime, getTomorrowDate } from '../../helpers/DateHelper';
import Grid from '@material-ui/core/Grid';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    TimePicker,
    DatePicker,
} from '@material-ui/pickers';
import { Divider } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from "react-hook-form";
import { CustomizedDateTextField, CustomizedTimeTextField } from './TextFields';
import {
    STAY_FROM_FIELD, TIME_STAY_FROM_FIELD,
    FORESEEN_STAY_UNTIL_FIELD, TIME_ESTIMATED_STAY_UNTIL_FIELD,
    ARRIVAL_DATE_LABEL, ARRIVAL_TIME_LABEL, DEPARTURE_TIME_LABEL,
    DEPARTURE_DATE_LABEL
} from '../../constants/strings-and-fields';
import schema from './DateTimePickers.schema';
import { useTranslation } from 'react-i18next';

const DateTimePickers = props => {
    const { setCheckinDateTimes } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    const { register, handleSubmit, control, setValue, formState: { errors }, getValues, trigger } = useForm({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        const checkin = {
            [FORESEEN_STAY_UNTIL_FIELD]: getTomorrowDate(),
            [STAY_FROM_FIELD]: getNowDate(),
            [TIME_ESTIMATED_STAY_UNTIL_FIELD]: getNowTime(),
            [TIME_STAY_FROM_FIELD]: getNowTime()
        }
        Object.keys(checkin).map(key => {
            register(key);
            setValue(key, checkin[key]);
            return true;
        });
        triggerAndUpdate();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const onChangeDate = (date, field) => {
        setValue(field, getStringDate(date));
        triggerAndUpdate();
    }

    const onChangeTime = (date, field) => {
        setValue(field, getStringTime(date));
        triggerAndUpdate();
    }

    const triggerAndUpdate = () => {
        trigger().then(validation => {
            if (validation) {
                const values = getValues();
                setCheckinDateTimes({ valid: true, ...values });
            } else {
                setCheckinDateTimes({ valid: false })
            }
        }).catch(e => {
            console.log(e);
        })
    }

    const onSubmit = (data, e) => {
        console.log(data);
    };

    const onError = (errors, e) => {
        console.log(errors, e)
    };

    return (
        <div className={classes.root}>
            <div>
                <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit, onError)}>
                    <Box display="flex" flexDirection="column" >
                        <Divider />
                        <Box className={classes.headerBox}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container >
                                    <Grid item xs={5}>
                                        <Controller
                                            name={STAY_FROM_FIELD}
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) =>
                                                <DatePicker
                                                    className={classes.datePicker}
                                                    margin="normal"
                                                    id={STAY_FROM_FIELD}
                                                    label={t(ARRIVAL_DATE_LABEL)}
                                                    format={DATE_FORMAT}
                                                    value={getDateFromDateString(field.value ?? "")}
                                                    onChange={(d) => onChangeDate(d, STAY_FROM_FIELD)}
                                                    TextFieldComponent={CustomizedDateTextField(classes)}
                                                    error={errors[STAY_FROM_FIELD]? true : false}
                                                    helperText={errors[STAY_FROM_FIELD] ? errors[STAY_FROM_FIELD].message : null}
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Controller
                                            name={TIME_STAY_FROM_FIELD}
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) =>
                                                <TimePicker
                                                    className={classes.timePicker}
                                                    margin="normal"
                                                    id={TIME_STAY_FROM_FIELD}
                                                    label={t(ARRIVAL_TIME_LABEL)}
                                                    value={getDateFromTime(field.value ?? "")}
                                                    ampm={false}
                                                    onChange={(d) => onChangeTime(d, TIME_STAY_FROM_FIELD)}
                                                    TextFieldComponent={CustomizedTimeTextField(classes)}
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Controller
                                            name={FORESEEN_STAY_UNTIL_FIELD}
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) =>
                                                <DatePicker
                                                    className={classes.datePicker}
                                                    margin="normal"
                                                    id={FORESEEN_STAY_UNTIL_FIELD}
                                                    label={t(DEPARTURE_DATE_LABEL)}
                                                    format={DATE_FORMAT}
                                                    value={getDateFromDateString(field.value ?? "")}
                                                    onChange={(d) => onChangeDate(d, FORESEEN_STAY_UNTIL_FIELD)}
                                                    TextFieldComponent={CustomizedDateTextField(classes)}
                                                    error={errors[FORESEEN_STAY_UNTIL_FIELD]? true : false}
                                                    helperText={errors[FORESEEN_STAY_UNTIL_FIELD] ? errors[FORESEEN_STAY_UNTIL_FIELD].message : null}
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Controller
                                            name={TIME_ESTIMATED_STAY_UNTIL_FIELD}
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) =>
                                                <TimePicker
                                                    className={classes.timePicker}
                                                    margin="normal"
                                                    id={TIME_ESTIMATED_STAY_UNTIL_FIELD}
                                                    label={t(DEPARTURE_TIME_LABEL)}
                                                    value={getDateFromTime(field.value ?? "")}
                                                    ampm={false}
                                                    onChange={(d) => onChangeTime(d, TIME_ESTIMATED_STAY_UNTIL_FIELD)}
                                                    TextFieldComponent={CustomizedTimeTextField(classes)}
                                                />
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </Box>
                    </Box>
                </form>
            </div>
        </div>
    );
}

export default DateTimePickers;