import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    PlayLists: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        //borderBottom: '1px solid #EEE'
    },
    root: {
        width: '100%',
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    speedDial: {
        position: 'fixed !important',
        bottom: theme.spacing(8),
        right: theme.spacing(3),
        left: 'auto',
        top: 'auto',
        transform: 'scale(0.8)',
        marginRight: theme.spacing(-2),
    },
    bottomNavigation: {
        position: 'fixed !important',
        bottom: theme.spacing(0.1),
        marginRight: theme.spacing(1),
        width: '100%',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    button: {
        margin: theme.spacing(1),
    },
    text: {
        padding: theme.spacing(2, 2, 0),
    },
    appBar: {
        top: 'auto',
        bottom: 0,
    },
    fabButton: {
        position: 'absolute',
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: '0 auto',
    },
    actionsButton: {
        padding: '2px'
    },
    moreButton: {
        color: theme.palette.getContrastText(theme.palette.primary.main),
        backgroundColor: theme.palette.secondary.main,
    },
}));

export default useStyles;
