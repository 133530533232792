import React, { useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import Drawer from '../drawers/Drawer';
import useStyles from './PrimaryListSearchNavBar.styles';
import { isLoggedIn } from '../../helpers/Utils';
import { EVISITOR_LABEL, NAME, PIN_ID, SEARCH_TOURISTS_LABEL, ALL } from '../../constants/strings-and-fields';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';

const PrimaryListSearchNavBar = props => {
  const classes = useStyles();
  const { history, showMenu = true, showSearch = true, triggerSearch, evaccounts, evAccount, setEvAccount } = props;
  const { t } = useTranslation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [searchText, setSearchText] = useState('');

  const handleChange = (event) => {
    setEvAccount(event.target.value);
  };

  const handleDrawerOpen = () => setOpenDrawer(true)
  const handleDrawerClose = () => setOpenDrawer(false)
  const drawerTrigger = () => handleDrawerClose();

  const searchByFilter = (event) => {
    const typedValue = event.target.value;
    setSearchText(typedValue);
  };

  // https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
  useEffect(() => {
    let isMounted = true;
    isLoggedIn().then(loggedIn => {
      isMounted && loggedIn && setIsAdmin(true);
    })
    return () => { isMounted = false };
  }, []);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          {showMenu &&
            <IconButton className={classes.menuButton} onClick={handleDrawerOpen} color="inherit" aria-label="Open drawer">
              <MenuIcon />
            </IconButton>
          }
          <div className={classes.chooser}>
            <FormControl className={showSearch ? classes.formControl : classes.formControlFullWidth}>
              <InputLabel className={classes.chooser} id={"chooser-label"}>{EVISITOR_LABEL}</InputLabel>
              <Select
                value={evAccount || ALL}
                onChange={handleChange}
                label={EVISITOR_LABEL}
                className={classes.chooser}
              >
                {!showSearch && <MenuItem key={ALL} value={ALL}>{ALL}</MenuItem>}
                {evaccounts.map((evaccount) => (
                  <MenuItem key={evaccount[PIN_ID]} value={evaccount}>{evaccount[NAME]}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {showSearch &&
            <div className={classes.search} >
              <div className={classes.searchIcon}>
                <IconButton onClick={e => triggerSearch(searchText, evAccount)} className={classes.searchIconToolbar}>
                  <SearchIcon className={classes.searchButton} />
                </IconButton>
              </div>
              <InputBase
                placeholder={t(SEARCH_TOURISTS_LABEL)}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                onChange={(e) => { searchByFilter(e) }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    triggerSearch(searchText, evAccount);
                  }
                }}
              />
            </div>
          }
        </Toolbar>
      </AppBar>
      <Drawer opendrawer={openDrawer} history={history} drawertrigger={drawerTrigger} />
    </div>
  );
}

export default PrimaryListSearchNavBar;