import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 400,
        backgroundColor: theme.palette.background.paper,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    guestNumberLabel: {
        width: 180,
    },
    guestNumberInput: {
        width: 64,
    },
}));

export default useStyles;