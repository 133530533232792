import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        //maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        '& .MuiTextField-root .datePicker': {
            width: 100,
        },
        '& .MuiBox-root': {
            width: '100%',
        },
        '& .MuiTypography-body2': {
            paddingLeft: 8,
        },
        '& .MuiGrid-item': {
            paddingLeft: 8,
        },
        
    },
    datePicker: {
        width: '32vw!important',
        marginRight: 16
    },
    timePicker: {
        width: '30vw!important',
        marginLeft: 24
    },
    headerBox: {
        paddingTop: 8,
    },
    fromDateIcon: {
        width: 24,
        height: 24
    },
    toDateIcon: {
        width: 48,
        height: 48
    },
}));

export default useStyles;