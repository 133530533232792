import React, { useState } from 'react';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import PeopleIcon from '@material-ui/icons/People';
import useStyles from './GuestItem.styles';
import Typography from '@material-ui/core/Typography';
import CalendarIcon from '../components/CalendarIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import Box from '@material-ui/core/Box';
import { getDate } from '../../helpers/DateHelper';
import {
    id, content, status, TOURIST_NAME, TOURIST_SURNAME, FORESEEN_STAY_UNTIL_FIELD,
    NOT_CHECKED_IN_STATUS, UPDATED_STATUS, CREATED_STATUS, CHECKED_IN_STATUS,
    CHECKED_OUT_STATUS,
    CANCELED_STATUS
} from '../../constants/strings-and-fields';
import { ReactComponent as QRLogo } from "./qr.svg";
import SvgIcon from "@material-ui/core/SvgIcon";
import TouristDetailsDialog from '../components/TouristDetailsDialog';

const GuestItem = props => {
    const { guest, eVisitorPinId, history, isAdmin, onDeleteCheckinGuest, evaccounts } = props;
    const classes = useStyles();
    const [openDetails, setOpenDetails] = useState(false);
    const stayToDate = getDate(guest[content][FORESEEN_STAY_UNTIL_FIELD]);

    const handleClick = (clickedGuest) => {
        if (clickedGuest[status] !== CANCELED_STATUS) {
            history.push(`/guest/${clickedGuest[id]}`);
        }
    }

    const mapColorStatus = (status) => {
        if (status === CHECKED_IN_STATUS) return 'statusTextGreen';
        if ([CHECKED_OUT_STATUS].includes(status)) return 'statusTextBlue';
        if ([NOT_CHECKED_IN_STATUS, CREATED_STATUS, UPDATED_STATUS].includes(status)) return 'statusTextRed';
        return 'statusTextBlue';
    }

    const guestTitle = (status, name, surname) => `${name} ${surname}`;

    const guestStatus = guest[status];
    const isCreatedGuest = (guestStatus === CREATED_STATUS) ? true : false;
    const disableGuest = (!isAdmin && !isCreatedGuest) ? true : false;

    const showQRCode = (![NOT_CHECKED_IN_STATUS, CREATED_STATUS, UPDATED_STATUS].includes(guestStatus))

    const handleDeleteClick = async (e) => {
        e.stopPropagation();
        await onDeleteCheckinGuest(guest);
    }

    const handleQRClick = (e) => {
        e.stopPropagation();
        setOpenDetails(true);
    }

    return (<>
        <List
            component="nav"
            className={classes.root}
            key={guest[id]}>
            <ListItem button onClick={() => handleClick(guest)} disabled={disableGuest}>
                <div style={{ width: '100%' }}>
                    <Box display="flex" flexDirection="row" >
                        <Box display="flex" flexDirection="column">
                            <CalendarIcon size="big" date={stayToDate} />
                        </Box>
                        <Box display="flex" flexDirection="column">
                            <Typography gutterBottom variant="body2" >
                                <PeopleIcon className={classes.icon} /> {guestTitle(guestStatus, guest[content][TOURIST_NAME] ?? "", guest[content][TOURIST_SURNAME] ?? "")}
                            </Typography>
                            <Typography gutterBottom variant="body2" className={classes[mapColorStatus(guestStatus)]}>
                                {guestStatus}
                            </Typography>

                        </Box>
                        {showQRCode &&
                            <Box display="flex" alignSelf="flex-end" className={classes.qrcode}>
                                <IconButton onClick={handleQRClick}>
                                    <SvgIcon>
                                        <QRLogo />
                                    </SvgIcon>
                                </IconButton>
                            </Box>
                        }
                        <Box display="flex" alignSelf="flex-end" className={classes.flexJustifyEnd} >
                            <IconButton onClick={handleDeleteClick}>
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </div>
            </ListItem>
        </List>
        <TouristDetailsDialog tourist={guest[content]} open={openDetails} setOpen={setOpenDetails} eVisitorPinId={eVisitorPinId} evaccounts={evaccounts}/>
    </>);
}

export default GuestItem;