import React, { createContext, useEffect, useState } from 'react';
import {
  deleteCheckin, fetchGuests, deleteEVisitorAcc, fetchCheckins, fetchEvisitorAccounts,
  fetchStaticData, loginUser, saveCheckin, saveGuest, updateCheckin, signUpUser, addEVisitor
} from '../api/incheckinservice';
import { createTheme } from '@material-ui/core/styles';
import LZString from 'lz-string';
import '../App.css';
import lightTheme from '../theme/light';
import darkTheme from '../theme/dark';
import {
  CHECKIN_ID, id, EV_STATIC_DATA, STATUS_GET_EVISITOR,
  STATUS_GET_CHECKINS, STATUS_UPDATE_GUEST,
  STATUS_GET_STATIC, STATUS_DELETE_GUEST, STATUS_SAVE_CHECKIN,
  STATUS_UPDATE_CHECKIN_GUESTS, STATUS_EDIT_CHECKIN,
  STATUS_DELETE_CHECKIN, STATUS_UPDATE_CHECKIN,
  guests as guests_field
} from '../constants/strings-and-fields';
import { parseStaticData } from '../helpers/ArrayHelpers';
import { isLoggedIn } from '../helpers/Utils';
import { sleep } from '../helpers/PromiseHelpers';

const themeLight = createTheme(lightTheme);
const themeDark = createTheme(darkTheme);

const AUTO_CLEAN_ERRORS_SLEEP_DURATION = 7000;
const AUTO_CLEAN_MESSAGES_SLEEP_DURATION = 6000;

const DataContext = createContext({
  onThemeChange: () => { },
  onSongFilter: () => { },
  onDeletePlaylist: () => { },
  onAddPlaylist: () => { }
});

export function DataProvider(props) {
  const [evaccounts, setEvaccounts] = useState([]);
  const [checkins, setCheckins] = useState([]);
  const [guests, setGuests] = useState([]);
  const [eVisitorStaticData, setEVisitorStaticData] = useState();
  const [appStatus, setAppStatus] = useState({ user: null, loggedIn: false, apiDataFetched: false });
  const [apiErrors, setApiErrors] = useState(null);
  const [apiMessages, setApiMessages] = useState(null);
  const [isThemeLight, setIsThemeLight] = useState(false);
  const [theme, setTheme] = useState(themeLight);

  const onThemeChange = () => {
    setIsThemeLight((isThemeLight) ? false : true)
    setTheme((isThemeLight) ? themeLight : themeDark)
  }

  const _onAppStartup = async () => {
    console.log("_onAppStartup");
    const currentUrl = window.location.pathname;
    const urlId = currentUrl.split("/")?.pop();
    if (await isLoggedIn()) {
      setLoggedIn();
      await fetchInitData();
    } else {
      if (currentUrl.includes("/checkin/")) {
        if (urlId) {
          await initCheckin(urlId);
          await initStaticData();
          setAppStatus({ ...appStatus, apiDataFetched: true })
        }
      } else if (currentUrl.includes("/guest/")) {
        if (urlId) {
          const guest = await fetchGuests(urlId);
          await initCheckin(guest[CHECKIN_ID]);
          await initStaticData();
          setAppStatus({ ...appStatus, apiDataFetched: true })
        }
      }
      else if (currentUrl.includes("/shared-guest/")) {
        if (urlId) {
          await initCheckin(urlId);
          await initStaticData();
          setAppStatus({ ...appStatus, apiDataFetched: true })
        }
      }
    }
  }

  const initCheckin = async (checkinId) => {
    fetchCheckins(checkinId).then(fetchedCheckin => {
      fetchAndSetCheckins([fetchedCheckin]);
    }).catch(e => {
      console.log(e?.message);
      setApiErrors({ signal: "OnFetchCheckin", message: e?.message });
    })
  }

  const fetchInitData = async () => {
    try {
      try {
        await initEvAccounts();
      } catch (e) {
        console.log("Caught startup error, could not fetch evisitor accouns");
      }
      await initCheckins();
      await initStaticData();
      setAppStatus({ ...appStatus, apiDataFetched: true })
    } catch (e) {
      console.log("Caught startup error");
      console.log(e);
      throw e;
    }
  }

  const setLoggedUser = (user) => {
    setAppStatus({ ...appStatus, loggedIn: true, user: user })
  }

  const setLoggedIn = () => {
    setAppStatus({ ...appStatus, loggedIn: true })
  }

  const initEvAccounts = async () => {
    const signal = "InitEvAccounts";
    setApiMessages({ signal: signal, message: STATUS_GET_EVISITOR });
    try {
      const fetchedEvAccounts = await fetchEvisitorAccounts();
      fetchAndSetEvAccounts(fetchedEvAccounts);
    } catch (error) {
      errorHandler(signal, error)
    }
  }

  const initCheckins = async () => {
    const signal = "InitCheckins";
    setApiMessages({ signal: signal, message: STATUS_GET_CHECKINS });
    try {
      const fetchedCheckins = await fetchCheckins();
      fetchAndSetCheckins(fetchedCheckins);
    } catch (error) {
      errorHandler(signal, error)
    }
  }

  const initStaticData = async () => {
    const signal = "InitStaticData";
    const storedStaticData = localStorage.getItem(EV_STATIC_DATA);
    if (storedStaticData) {
      fetchAndSetStaticData(JSON.parse(LZString.decompressFromUTF16(storedStaticData)));
    } else {
      setApiMessages({ signal: signal, message: STATUS_GET_STATIC });
      try {
        const fetchedStaticData = await fetchStaticData();
        const staticData = parseStaticData(fetchedStaticData);
        fetchAndSetStaticData(staticData);
        localStorage.setItem(EV_STATIC_DATA, LZString.compressToUTF16(JSON.stringify(staticData)));
      } catch (error) {
        errorHandler(signal, error)
      }
    }
  }

  const refreshCheckins = async (guest = null) => {
    if (guest) {
      try {
        const fetchedCheckinRes = await fetchCheckins(guest[CHECKIN_ID]);
        const checkinIndex = checkins.findIndex((checkin => checkin[id] === fetchedCheckinRes[id]));
        checkins[checkinIndex] = fetchedCheckinRes;
        fetchAndSetCheckins([...checkins]);
      } catch (e) {
        console.log(e?.message);
        setApiErrors({ signal: "OnFetchCheckin", message: e?.message });
      }
    } else await initCheckins();
  }

  const fetchAndSetStaticData = (data) => {
    if (data) setEVisitorStaticData(data);
  }

  const fetchAndSetCheckins = (data) => {
    if (data) setCheckins(data);
  }

  const fetchAndSetGuests = (data) => {
    if (data) setGuests(data);
  }

  const fetchAndSetEvAccounts = (data) => {
    if (data) setEvaccounts(data);
  }

  const fetchAndSetData = {
    fetchAndSetStaticData,
    fetchAndSetCheckins,
    fetchAndSetGuests,
    fetchAndSetEvAccounts,
  }

  const onEditGuest = (editGuest) => {
    setApiMessages({ signal: "OnEditGuest", message: STATUS_UPDATE_GUEST });
    fetchCheckins(editGuest[CHECKIN_ID]).then(fetchedCheckin => {
      const checkinIndex = checkins.findIndex((checkin => checkin[id] === fetchedCheckin[id]));
      checkins[checkinIndex] = fetchedCheckin;
      fetchAndSetCheckins(checkins);
    }).catch(e => {
      console.log(e?.message);
      setApiErrors({ signal: "OnFetchCheckin", message: e?.message });
    })
  }

  const onDeleteGuest = (removeGuest) => {
    setApiMessages({ signal: "OnDeleteGuest", message: STATUS_DELETE_GUEST });
    fetchCheckins(removeGuest[CHECKIN_ID]).then(fetchedCheckin => {
      const checkinIndex = checkins.findIndex((checkin => checkin[id] === fetchedCheckin[id]));
      checkins[checkinIndex] = fetchedCheckin;
      fetchAndSetCheckins(checkins);
    }).catch(e => {
      console.log(e?.message);
      setApiErrors({ signal: "OnFetchCheckin", message: e?.message });
    })
  }

  const onAddCheckin = async (checkin) => {
    setApiMessages({ signal: "OnAddCheckin", message: STATUS_SAVE_CHECKIN });
    try {
      await saveCheckin(checkin);
      const fetchedCheckin = await fetchCheckins();
      fetchAndSetCheckins(fetchedCheckin);
    } catch (e) {
      console.log(e?.message);
      setApiErrors({ signal: "onAddCheckin", message: e?.message });
    }
  }

  const onUpdateCheckin = async (checkin) => {
    setApiMessages({ signal: "OnUpdateCheckin", message: STATUS_UPDATE_CHECKIN });
    try {
      await updateCheckin(checkin);
      const fetchedCheckin = await fetchCheckins();
      fetchAndSetCheckins(fetchedCheckin);
    } catch (e) {
      console.log(e?.message);
      setApiErrors({ signal: "onAddCheckin", message: e?.message });
    }
  }

  const onUpdateGuests = async (guests) => {
    setApiMessages({ signal: "OnAddCheckin", message: STATUS_UPDATE_CHECKIN_GUESTS });
    try {
      let checkinId = "";
      for (let guest of guests) {
        await saveGuest(guest);
        checkinId = guest[CHECKIN_ID];
      }
      const fetchedCheckin = await fetchCheckins(checkinId);
      const checkinIndex = checkins.findIndex((checkin => checkin[id] === fetchedCheckin[id]));
      checkins[checkinIndex] = fetchedCheckin;
      fetchAndSetCheckins([...checkins]);
    } catch (e) {
      console.log(e?.message);
      setApiErrors({ signal: "onAddCheckin", message: e?.message });
    }
  }

  const onEditCheckin = (editCheckin) => {
    setApiMessages({ signal: "OnEditCheckin", message: STATUS_EDIT_CHECKIN });
    updateCheckin(editCheckin).then(_ => {
      fetchCheckins(editCheckin[id]).then(fetchedCheckin => {
        const checkinIndex = checkins.findIndex((checkin => checkin[id] === fetchedCheckin[id]));
        checkins[checkinIndex] = fetchedCheckin;
        fetchAndSetCheckins([...checkins]);
      }).catch(e => {
        console.log(e?.message);
        setApiErrors({ signal: "OnFetchCheckin", message: e?.message });
      })
    })
      .catch(e => {
        console.log(e?.message);
        setApiErrors({ signal: "OnEditCheckin", message: e?.message });
      })
  }

  const onDeleteCheckin = (removeCheckin) => {
    setApiMessages({ signal: "OnDeleteCheckin", message: STATUS_DELETE_CHECKIN });
    deleteCheckin(removeCheckin).then(_ => {
      fetchCheckins().then(fetchedCheckin => {
        fetchAndSetCheckins(fetchedCheckin);
      }).catch(e => {
        console.log(e?.message);
        setApiErrors({ signal: "OnFetchCheckin", message: e?.message });
      })
    })
      .catch(e => {
        console.log(e?.message);
        setApiErrors({ signal: "OnDeleteCheckin", message: e?.message });
      })
  }

  const signUp = async (user) => {
    try {
      return await signUpUser(user);
    }
    catch (e) {
      setApiErrors({ signal: "SignUp", message: e?.message });
      throw e;
    }
  }

  const login = async (user) => {
    try {
      return await loginUser(user);
    }
    catch (e) {
      setApiErrors({ signal: "Login", message: e?.message });
      throw e;
    }
  }

  const associateEvisitor = async (user) => {
    try {
      await addEVisitor(user);
      await initEvAccounts();
    }
    catch (e) {
      setApiErrors({ signal: "AddEVisitor", message: e?.message });
      throw e;
    }
  }

  const deleteEVisitor = async (eVisitorAccount) => {
    try {
      await deleteEVisitorAcc(eVisitorAccount);
      await initEvAccounts();
    }
    catch (e) {
      setApiErrors({ signal: "DeleteEVisitor", message: e?.message });
      throw e;
    }
  }

  const onReCreateGuest = async (guest) => {
    //setApiMessages({ signal: "OnRecreateGuest", message: STATUS_RECREATE_CHECKIN_GUESTS });
    try {
      const checkinIndex = checkins.findIndex((checkin => checkin[id] === guest[CHECKIN_ID]));
      if (checkinIndex !== -1) {
        const checkin = checkins[checkinIndex];
        const checkinGuests = checkin[guests_field];
        const checkinGuestIndex = checkinGuests.findIndex((checkinGuest => checkinGuest[id] === guest[id]));
        if (checkinGuestIndex !== -1) {
          checkins[checkinIndex][guests_field][checkinGuestIndex] = guest;
          fetchAndSetCheckins([...checkins]);
        }
      }
    } catch (e) {
      console.log(e?.message);
      setApiErrors({ signal: "OnRecreateGuest", message: e?.message });
    }
  }

  useEffect(() => {
    _onAppStartup();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (apiErrors) {
      // errors cleanup
      sleep(AUTO_CLEAN_ERRORS_SLEEP_DURATION).then(() => {
        console.log("Automatically clearing apiErrors");
        setApiErrors(null);
      })
    }
  }, [apiErrors]);


  useEffect(() => {
    if (apiMessages) {
      // errors cleanup
      sleep(AUTO_CLEAN_MESSAGES_SLEEP_DURATION).then(() => {
        if (apiMessages) {
          setApiMessages(null);
        }
      })
    }
  }, [apiMessages]);

  const errorHandler = (signal, error) => {
    console.log(error);
    const err = new RestApiException(signal, error?.message);
    setApiErrors({ ...err });
    throw err;
  }

  return (
    <DataContext.Provider value={{
      fetchAndSetData, fetchInitData, evaccounts, checkins, guests,
      eVisitorStaticData, signUp, login, deleteEVisitor, associateEvisitor,
      setLoggedUser, appStatus, setApiErrors,
      apiErrors, apiMessages, refreshCheckins, onEditGuest, onDeleteGuest,
      onAddCheckin, onUpdateCheckin, onUpdateGuests,
      onEditCheckin, onDeleteCheckin, onReCreateGuest, theme, onThemeChange
    }}>
      {props.children}
    </DataContext.Provider>
  );
}

function RestApiException(signal, message) {
  this.signal = signal;
  this.message = message;
}

export const DataConsumer = DataContext.Consumer;
export default DataContext;
