import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    DatePicker,
    TimePicker
} from '@material-ui/pickers';
import useStyles from './SharedGuest.styles';
import { CustomizedDateTextField, CustomizedTimeTextField } from '../../components/TextFields';
import {
    DATE_FORMAT,
    diffDateBirthYears,
    getDateFromDateString, getDateFromTime,
    getStringDate, getStringTime
} from '../../../helpers/DateHelper';
import {
    GENDERS, GENDER_FIELD,
    STAY_FROM_FIELD, TIME_STAY_FROM_FIELD,
    FORESEEN_STAY_UNTIL_FIELD, TIME_ESTIMATED_STAY_UNTIL_FIELD,
    DATE_OF_BIRTH_FIELD, COUNTRY_OF_RESIDENCE, COUNTRY_OF_BIRTH,
    CITIZENSHIP, CITY_OF_RESIDENCE, DOCUMENT_TYPE,
    PAYMENT_CATEGORY, OFFERED_SERVICE_TYPE, ARRIVAL_ORGANIZATION,
    TOURIST_NAME, TOURIST_SURNAME, DOCUMENT_NUMBER,
    COUNTRY_DATA, SETTLEMENTS_DATA, NAME, DOCUMENT_TYPE_DATA,
    SERVICE_TYPE_DATA, PAYMENT_CATEGORY_DATA, ARRIVAL_ORGANIZATION_DATA,
    COUNTRY_CODE, COUNTRY_NAME, CROATIA_COUNTRY_CODE, CODE, CODE_MI, DOWNLOAD_IN_PROGRESS,
    content, CHECKIN_ID, id, ALERT_DIALOG_TITLE, ARRIVAL_DATE_LABEL, ARRIVAL_TIME_LABEL,
    DEPARTURE_DATE_LABEL, DEPARTURE_TIME_LABEL, FIRST_NAME_LABEL, LAST_NAME_LABEL, DATE_OF_BIRTH_LABEL,
    COUNTRY_OF_BIRTH_LABEL, CITIZENSHIP_LABEL, RESIDENCE_COUNTRY_LABEL, RESIDENCE_CITY_LABEL,
    DOCUMENT_CODE_LABEL, PAYMENT_CATEGORY_LABEL, SERVICE_TYPE_LABEL, TRAVEL_ARRANGEMENTS_LABEL,
    DOCUMENT_TYPE_LABEL, NEPOZNATO, CONFIRM_GUEST_TITLE,
    GUEST_EDIT_WARNING, STATUS, CREATED_STATUS, ENTER_DATA, CURRENT_GUEST_COUNT,
    ADDITIONAL_FIELDS, CURRENT_GUESTS_COUNT, MAX_GUEST_NUMBER, MAX_GUEST_NUMBER_REACHED, GUEST_SAVED,
    FORWARD_DATA, SAVE_DATA, LANGUAGE_EN_CODE, GENDER_LABEL, INPUT_NEXT_DATA,
} from '../../../constants/strings-and-fields';
import schema, { initNewGuest, mapGender } from './SharedGuest.schema';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import AlertDialog from '../../components/AlertDialog';
import CustomizedSnackbar from '../../components/Snackbar';
import Box from '@material-ui/core/Box';
import { v4 as uuidv4 } from 'uuid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { findCheckin, mapCountryCodeToCountry, mapTypeCodeMIToObject, mapTypeCodeToObject } from '../../../helpers/ArrayHelpers';
import { sleep } from '../../../helpers/PromiseHelpers';
import { createOrUpdateGuest } from '../../../api/incheckinservice';
import ConfirmWithCheckboxDialog from '../../components/ConfirmWithCheckboxDialog';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import ShareIcon from '@material-ui/icons/Share';
import Button from '@material-ui/core/Button';
import SharedGuestHeader from '../../lists/SharedGuestHeader';
import LinkDialog from '../../components/LinkDialog';
import SaveIcon from '@material-ui/icons/Save';
import ForwardIcon from '@material-ui/icons/Forward';
import PeopleIcon from '@material-ui/icons/People';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import LangChanger from '../../components/LangChanger';
import { Divider } from '@material-ui/core';
import { BackAndSaveNavBar } from '../../navbars/BackAndSaveNavBar';

const SLEEP_DURATION = 1000;
const API_MESSAGE_TIMEOUT = 5000;

const SharedGuest = props => {
    const classes = useStyles();
    const { checkins, eVisitorStaticData, match, location, onEditGuest, apiErrors, apiMessages } = props;
    const { t, i18n } = useTranslation();
    const [isAdmin, setIsAdmin] = useState(false);
    // Validation messages
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [openMessage, setOpenMessage] = useState(false);
    const [openProgress, setOpenProgress] = useState(false);
    const [confirmGuestOpen, setConfirmGuestOpen] = useState(false);
    const [isCroatianCity, setIsCroatianCity] = useState(false);
    const [guest, setGuest] = useState({});
    const [checkin, setCheckin] = useState();
    const [showConsentHeader, setShowConsentHeader] = useState(false);
    const [isConsentAccepted, setIsConsentAccepted] = useState(false);
    const [isOpenShare, setIsOpenShare] = useState(false);
    const [showSaved, setShowSaved] = useState(false);
    const [lang, setLang] = useState(LANGUAGE_EN_CODE);

    const checkinId = match.params.id;
    const currentGuestsCount = checkin?.[ADDITIONAL_FIELDS]?.[CURRENT_GUESTS_COUNT] ?? 0;
    const maxGuestsCount = checkin?.[ADDITIONAL_FIELDS]?.[MAX_GUEST_NUMBER] ?? 0;

    // Select options
    const countries = eVisitorStaticData && eVisitorStaticData?.[COUNTRY_DATA];
    const cities = eVisitorStaticData && eVisitorStaticData?.[SETTLEMENTS_DATA].map((city) => city[NAME]);
    const documentTypes = eVisitorStaticData && eVisitorStaticData?.[DOCUMENT_TYPE_DATA];
    const serviceTypes = eVisitorStaticData && eVisitorStaticData?.[SERVICE_TYPE_DATA];
    const paymentCategories = eVisitorStaticData && eVisitorStaticData?.[PAYMENT_CATEGORY_DATA];
    const arrivalOrganisations = eVisitorStaticData && eVisitorStaticData?.[ARRIVAL_ORGANIZATION_DATA];

    const { register, handleSubmit, control, setValue, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (data, e) => {
        setConfirmGuestOpen(false);
        continueSubmit(data);
    };

    const openUpConfirmDialog = () => {
        setConfirmGuestOpen(true);
    }

    const continueSubmit = (data) => {
        setOpenProgress(true);
        const guestSubmit = {
            [id]: guest[id],
            [CHECKIN_ID]: guest[CHECKIN_ID],
            [STATUS]: CREATED_STATUS,
            [content]: data
        }
        createOrUpdateGuest(guestSubmit, true).then(_ => {
            onEditGuest(guestSubmit);
            sleep(SLEEP_DURATION).then(() => {
                setOpenProgress(false);
                savedGuestThankYou();
            })
        }).catch(e => {
            setOpenProgress(false);
        })
    }

    const onError = (errors, e) => {
        console.log(errors, e)
    };

    const handleProgressToggle = () => {
        setOpenProgress(!openProgress);
    };

    // Data mutation
    const onChangeField = (data, field) => {
        setValue(field, data);
    }

    const onChangeDate = (date, field) => {
        setValue(field, getStringDate(date));
        if (field === DATE_OF_BIRTH_FIELD) {
            const age = diffDateBirthYears(date);
            if (age < 12) {
                setValue(PAYMENT_CATEGORY, "1")
            }
            else if (age > 12 && age < 18) {
                setValue(PAYMENT_CATEGORY, "2")
            }
            else {
                setValue(PAYMENT_CATEGORY, "14")
            };
        }
    }

    const onChangeTime = (date, field) => {
        setValue(field, getStringTime(date));
    }

    // Male -> Muško
    const handleGenderChange = (evt) => {
        setValue(GENDER_FIELD, GENDERS[evt?.target?.value]);
    }

    const onChangeCountry = (country, field) => {
        if (country) {
            if (field === COUNTRY_OF_RESIDENCE && country[COUNTRY_CODE] === CROATIA_COUNTRY_CODE) {
                setIsCroatianCity(true);
            } else {
                setIsCroatianCity(false);
            }
            setValue(field, country[COUNTRY_CODE]);

            const citizenship = getValues(CITIZENSHIP);
            if (citizenship === '') {
                setValue(CITIZENSHIP, country[COUNTRY_CODE]);
            }
            const countryOfResidence = getValues(COUNTRY_OF_RESIDENCE);
            if (countryOfResidence === '') {
                setValue(COUNTRY_OF_RESIDENCE, country[COUNTRY_CODE]);
                if (country[COUNTRY_CODE] === CROATIA_COUNTRY_CODE) setIsCroatianCity(true);
            }
        }
    }

    const generateMessage = () => {
        if (apiErrors) {
            return `${apiErrors?.signal}: ${apiErrors?.message}`
        }
        else if (apiMessages) {
            return `${apiMessages?.signal}: ${apiMessages?.message}`
        };
    }

    const preCommitHook = () => {
        const cityOfResidence = getValues(CITY_OF_RESIDENCE);
        if (!isCroatianCity && (cityOfResidence === '' || cityOfResidence === null)) {
            setValue(CITY_OF_RESIDENCE, NEPOZNATO);
        }
        continueGuest();
    }

    const continueGuest = () => {
        handleSubmit(onSubmit, onError)();
    }

    const onShareGuestClick = async () => {
        setIsOpenShare(true);
    }

    const onEnterDataClick = async () => {
        hideConsentHeader();
        window.scrollTo(0, 0);
    }

    const hideConsentHeader = () => {
        setShowConsentHeader(!showConsentHeader);
    }

    const consentAccepted = (isAccepted) => {
        setIsConsentAccepted(isAccepted);
    }

    const nextGuest = () => {
        const query = new URLSearchParams(location.search);
        const consent = query.get('consent');
        const params = { consent: true, lang: lang };
        const queryString = new URLSearchParams(params).toString();
        if (!consent) {
            window.location = window.location.href.split('?')[0] + '?' + queryString;
        } else {
            window.location.reload();
        }
    }

    const savedGuestThankYou = () => {
        const query = new URLSearchParams(location.search);
        const saved = query.get('saved');
        const params = { saved: true, lang: lang };
        const queryString = new URLSearchParams(params).toString();
        if (!saved) {
            window.location = window.location.href.split('?')[0] + '?' + queryString;
        } else {
            window.location.reload();
        }
    }

    useEffect(() => {
        if (checkins) {
            // 92e3ad93-494c-4ba2-82b3-d79185124838
            const foundCheckin = findCheckin(checkins, checkinId);
            if (foundCheckin) {
                setCheckin(foundCheckin);
                const preparedGuest = initNewGuest(uuidv4(), foundCheckin);
                setGuest(preparedGuest);
                Object.keys(preparedGuest[content]).map(key => {
                    register(key);
                    setValue(key, preparedGuest[content][key]);
                    return true;
                })

                const countryOfResidence = getValues(COUNTRY_OF_RESIDENCE);
                if (countryOfResidence === CROATIA_COUNTRY_CODE) {
                    setIsCroatianCity(true);
                }
            }
        }
    }, [checkins]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        apiErrors && setOpenMessage(true);
        apiMessages && setOpenMessage(true);
    }, [apiErrors, apiMessages]);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const consent = query.get('consent');
        const saved = query.get('saved');
        const qLang = query.get('lang');
        if (consent) setShowConsentHeader(true);
        if (saved) setShowSaved(true);
        if (qLang) setLang(qLang);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (lang) i18n.changeLanguage(lang);
    }, [lang]); // eslint-disable-line react-hooks/exhaustive-deps

    const ConsentBottomBar = () => (<>
        <AppBar position="fixed" color="primary" className={classes.appBar}>
            <Toolbar>
                <IconButton edge="start" color="inherit" aria-label="enter-data-button" >
                    <Button startIcon={<GroupAddIcon />}
                        variant="contained"
                        color="secondary"
                        disabled={!isConsentAccepted}
                        onClick={onEnterDataClick}>
                        {t(ENTER_DATA)}
                    </Button>
                </IconButton>
                <div className={classes.grow} />
                <IconButton edge="end" color="inherit" aria-label="forward-data-button" >
                    <Button startIcon={<ShareIcon />}
                        variant="contained"
                        color="secondary"
                        onClick={onShareGuestClick}>
                        {t(FORWARD_DATA)}
                    </Button>
                </IconButton>
            </Toolbar>
        </AppBar>
        <LinkDialog isOpen={isOpenShare} setOpenShare={setIsOpenShare} checkin={checkin} />
    </>)

    const GuestBottomBar = () => (<>
        <AppBar position="fixed" color="primary" className={classes.appBar}>
            <Toolbar>
                <IconButton edge="start" color="inherit" aria-label="enter-data-button" >
                    <Button startIcon={<SaveIcon />}
                        variant="contained"
                        color="secondary"
                        onClick={openUpConfirmDialog}>
                        {t(SAVE_DATA)}
                    </Button>
                </IconButton>
                <div className={classes.grow} />
                <Avatar edge="end" className={classes.moreButton} onClick={onShareGuestClick}>
                    <ShareIcon />
                </Avatar>
            </Toolbar>
        </AppBar>
        <LinkDialog isOpen={isOpenShare} setOpenShare={setIsOpenShare} checkin={checkin} />
    </>)

    const GuestSavedBottomBar = () => (<>
        <AppBar position="fixed" color="primary" className={classes.appBar}>
            <Toolbar>
                <IconButton edge="start" color="inherit" aria-label="enter-data-button" >
                    <Button startIcon={<ForwardIcon />}
                        variant="contained"
                        color="secondary"
                        onClick={nextGuest}>
                        {t(INPUT_NEXT_DATA)}
                    </Button>
                </IconButton>
                <div className={classes.grow} />
                <IconButton edge="end" color="inherit" aria-label="forward-data-button" >
                    <Button startIcon={<ShareIcon />}
                        variant="contained"
                        color="secondary"
                        onClick={onShareGuestClick}>
                        {t(FORWARD_DATA)}
                    </Button>
                </IconButton>
            </Toolbar>
        </AppBar>
        <LinkDialog isOpen={isOpenShare} setOpenShare={setIsOpenShare} checkin={checkin} />
    </>)

    if (showSaved) {
        return (
            <div className={classes.root}>
                <Typography gutterBottom variant="body1" className={classes.guestCount} >
                    {t(GUEST_SAVED)}
                </Typography>
                <Typography gutterBottom variant="body2" className={classes.guestCount} >
                    <PeopleIcon className={classes.icon} /> {t(CURRENT_GUEST_COUNT)} {currentGuestsCount}/{maxGuestsCount}
                </Typography>
                <GuestSavedBottomBar />
            </div>)
    }

    if (maxGuestsCount !== 0 && (currentGuestsCount === maxGuestsCount)) {
        return (
            <div className={classes.root}>
                <Typography gutterBottom variant="body1" className={classes.guestCount} >
                    {t(MAX_GUEST_NUMBER_REACHED)}
                </Typography>
                <Typography gutterBottom variant="body2" className={classes.guestCount} >
                    <PeopleIcon className={classes.icon} /> {t(CURRENT_GUEST_COUNT)} {currentGuestsCount}/{maxGuestsCount}
                </Typography>
            </div>)
    }

    if (!showConsentHeader) {
        return (<div className={classes.root}>
            <BackAndSaveNavBar classes={classes} children={<LangChanger styleClasses={classes} />}/>
            <CssBaseline />
            <Paper square className={classes.paper}>
                <SharedGuestHeader consentAccepted={consentAccepted} checkin={checkin} confirmGuestOpen={confirmGuestOpen} setConfirmGuestOpen={setConfirmGuestOpen} continueGuestAccepted={preCommitHook} />
                <ConsentBottomBar />
                <Divider />
            </Paper>
        </div>)
    }

    if (guest && eVisitorStaticData) {
        return (
            <div className={classes.root}>
                <BackAndSaveNavBar classes={classes} children={<LangChanger styleClasses={classes} />}/>
                <CssBaseline />
                <Paper square className={classes.paper}>
                    <Typography gutterBottom variant="body2" className={classes.guestCount} hidden>
                        <PeopleIcon className={classes.icon} /> {t(CURRENT_GUEST_COUNT)} {currentGuestsCount}/{maxGuestsCount}
                    </Typography>
                    <div className={classes.guestForm}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <form noValidate autoComplete="off" onSubmit={preCommitHook}>
                                <div>
                                    {isAdmin &&
                                        <Grid container >
                                            <Grid item xs={5}>
                                                <Controller
                                                    name={STAY_FROM_FIELD}
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) =>
                                                        <DatePicker
                                                            className={classes.datePicker}
                                                            margin="normal"
                                                            id={STAY_FROM_FIELD}
                                                            label={t(ARRIVAL_DATE_LABEL)}
                                                            format={DATE_FORMAT}
                                                            value={getDateFromDateString(field.value ?? "")}
                                                            onChange={(d) => onChangeDate(d, STAY_FROM_FIELD)}
                                                            TextFieldComponent={CustomizedDateTextField(classes)}
                                                            error={errors[STAY_FROM_FIELD] ? true : false}
                                                            helperText={errors[STAY_FROM_FIELD] ? errors[STAY_FROM_FIELD].message : null}
                                                        />
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Controller
                                                    name={TIME_STAY_FROM_FIELD}
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) =>
                                                        <TimePicker
                                                            className={classes.datePicker}
                                                            margin="normal"
                                                            id={TIME_STAY_FROM_FIELD}
                                                            label={t(ARRIVAL_TIME_LABEL)}
                                                            value={getDateFromTime(field.value ?? "")}
                                                            ampm={false}
                                                            onChange={(d) => onChangeTime(d, TIME_STAY_FROM_FIELD)}
                                                            TextFieldComponent={CustomizedTimeTextField(classes)}
                                                        />
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Controller
                                                    name={FORESEEN_STAY_UNTIL_FIELD}
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) =>
                                                        <DatePicker
                                                            className={classes.datePicker}
                                                            margin="normal"
                                                            id={FORESEEN_STAY_UNTIL_FIELD}
                                                            label={t(DEPARTURE_DATE_LABEL)}
                                                            format={DATE_FORMAT}
                                                            value={getDateFromDateString(field.value ?? "")}
                                                            onChange={(d) => onChangeDate(d, FORESEEN_STAY_UNTIL_FIELD)}
                                                            TextFieldComponent={CustomizedDateTextField(classes)}
                                                            error={errors[FORESEEN_STAY_UNTIL_FIELD] ? true : false}
                                                            helperText={errors[FORESEEN_STAY_UNTIL_FIELD] ? errors[FORESEEN_STAY_UNTIL_FIELD].message : null}
                                                        />
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Controller
                                                    name={TIME_ESTIMATED_STAY_UNTIL_FIELD}
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) =>
                                                        <TimePicker
                                                            className={classes.datePicker}
                                                            margin="normal"
                                                            id={TIME_ESTIMATED_STAY_UNTIL_FIELD}
                                                            label={t(DEPARTURE_TIME_LABEL)}
                                                            value={getDateFromTime(field.value ?? "")}
                                                            ampm={false}
                                                            onChange={(d) => onChangeTime(d, TIME_ESTIMATED_STAY_UNTIL_FIELD)}
                                                            TextFieldComponent={CustomizedTimeTextField(classes)}
                                                        />
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    }

                                    <Box>
                                        <Controller
                                            name={TOURIST_NAME}
                                            render={({ field }) =>
                                                <TextField
                                                    id={TOURIST_NAME}
                                                    label={t(FIRST_NAME_LABEL)}
                                                    value={field.value}
                                                    onChange={(e) => onChangeField(e.target.value, TOURIST_NAME)}
                                                    error={errors[TOURIST_NAME] ? true : false}
                                                    helperText={errors[TOURIST_NAME] ? errors[TOURIST_NAME].message : null} />
                                            }
                                            control={control}
                                            defaultValue=""
                                        />
                                    </Box>

                                    <Box>
                                        <Controller
                                            name={TOURIST_SURNAME}
                                            render={({ field }) =>
                                                <TextField
                                                    id={TOURIST_SURNAME}
                                                    label={t(LAST_NAME_LABEL)}
                                                    value={field.value}
                                                    onChange={(e) => onChangeField(e.target.value, TOURIST_SURNAME)}
                                                    error={errors[TOURIST_SURNAME] ? true : false}
                                                    helperText={errors[TOURIST_SURNAME] ? errors[TOURIST_SURNAME].message : null} />
                                            }
                                            control={control}
                                            defaultValue=""
                                        />
                                    </Box>

                                    <Box>
                                        <Controller
                                            name={GENDER_FIELD}
                                            control={control}
                                            defaultValue={false}
                                            render={({ field }) =>
                                                <TextField
                                                    id={GENDER_FIELD}
                                                    select
                                                    label={t(GENDER_LABEL)}
                                                    value={mapGender(field.value)}
                                                    onChange={handleGenderChange}
                                                    error={errors[GENDER_FIELD] ? true : false}
                                                    helperText={errors[GENDER_FIELD] ? errors[GENDER_FIELD].message : null}
                                                >
                                                    {Object.keys(GENDERS).map((option) => (
                                                        <MenuItem key={option} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            }
                                        />
                                    </Box>

                                    <Box>
                                        <Controller
                                            name={DATE_OF_BIRTH_FIELD}
                                            control={control}
                                            defaultValue={null}
                                            render={({ field }) =>
                                                <DatePicker
                                                    margin="normal"
                                                    id={DATE_OF_BIRTH_FIELD}
                                                    label={t(DATE_OF_BIRTH_LABEL)}
                                                    format={DATE_FORMAT}
                                                    value={(field.value) ? getDateFromDateString(field.value) : null}
                                                    onChange={(d) => onChangeDate(d, DATE_OF_BIRTH_FIELD)}
                                                    TextFieldComponent={CustomizedDateTextField(classes)}
                                                    error={errors[DATE_OF_BIRTH_FIELD] ? true : false}
                                                    helperText={errors[DATE_OF_BIRTH_FIELD] ? errors[DATE_OF_BIRTH_FIELD].message : null}
                                                />}
                                        />
                                    </Box>

                                    <Box>
                                        <Controller
                                            name={COUNTRY_OF_BIRTH}
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) =>
                                                <Autocomplete
                                                    id={COUNTRY_OF_BIRTH}
                                                    options={countries}
                                                    getOptionLabel={(option) => option[COUNTRY_NAME] ?? ""}
                                                    value={mapCountryCodeToCountry(field.value, countries)}
                                                    onChange={(e, c) => onChangeCountry(c, COUNTRY_OF_BIRTH, countries)}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            label={t(COUNTRY_OF_BIRTH_LABEL)}
                                                            autoComplete="new-password"
                                                            error={errors[COUNTRY_OF_BIRTH] ? true : false}
                                                            helperText={errors[COUNTRY_OF_BIRTH] ? errors[COUNTRY_OF_BIRTH].message : null}
                                                        />
                                                    }
                                                />
                                            }
                                        />
                                    </Box>

                                    <Box>
                                        <Controller
                                            name={CITIZENSHIP}
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) =>
                                                <Autocomplete
                                                    id={CITIZENSHIP}
                                                    options={countries}
                                                    getOptionLabel={(option) => option[COUNTRY_NAME] ?? ""}
                                                    value={mapCountryCodeToCountry(field.value, countries)}
                                                    onChange={(e, c) => onChangeCountry(c, CITIZENSHIP, countries)}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            label={t(CITIZENSHIP_LABEL)}
                                                            autoComplete="new-password"
                                                            error={errors[CITIZENSHIP] ? true : false}
                                                            helperText={errors[CITIZENSHIP] ? errors[CITIZENSHIP].message : null}
                                                        />
                                                    }
                                                />
                                            }
                                        />
                                    </Box>

                                    <Box>
                                        <Controller
                                            name={COUNTRY_OF_RESIDENCE}
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) =>
                                                <Autocomplete
                                                    id={COUNTRY_OF_RESIDENCE}
                                                    options={countries}
                                                    getOptionLabel={(option) => option[COUNTRY_NAME] ?? ""}
                                                    value={mapCountryCodeToCountry(field.value, countries)}
                                                    onChange={(e, c) => onChangeCountry(c, COUNTRY_OF_RESIDENCE, countries)}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            label={t(RESIDENCE_COUNTRY_LABEL)}
                                                            autoComplete="new-password"
                                                            error={errors[COUNTRY_OF_RESIDENCE] ? true : false}
                                                            helperText={errors[COUNTRY_OF_RESIDENCE] ? errors[COUNTRY_OF_RESIDENCE].message : null}
                                                        />
                                                    }
                                                />
                                            }
                                        />
                                    </Box>

                                    <Box>
                                        <Controller
                                            name={CITY_OF_RESIDENCE}
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) =>
                                                <Autocomplete
                                                    id={CITY_OF_RESIDENCE}
                                                    options={(isCroatianCity) ? cities : []}
                                                    freeSolo={!isCroatianCity}
                                                    value={(isCroatianCity) ? cities.find((city) => city === field.value) : field.value}
                                                    onChange={(e, c) => {
                                                        setValue(CITY_OF_RESIDENCE, c);
                                                    }}
                                                    onInputChange={(e, c) => {
                                                        setValue(CITY_OF_RESIDENCE, c);
                                                    }}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            autoComplete="new-password"
                                                            label={t(RESIDENCE_CITY_LABEL)}
                                                            error={errors[CITY_OF_RESIDENCE] ? true : false}
                                                            helperText={errors[CITY_OF_RESIDENCE] ? errors[CITY_OF_RESIDENCE].message : null}
                                                        />
                                                    }
                                                />
                                            }
                                        />
                                    </Box>

                                    <Box>
                                        <Controller
                                            name={DOCUMENT_TYPE}
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) =>
                                                <FormControl className={classes.formControl}>
                                                    <InputLabel id={DOCUMENT_TYPE + '-label'}>{t(DOCUMENT_TYPE_LABEL)}e</InputLabel>
                                                    <Select
                                                        id={DOCUMENT_TYPE}
                                                        label={t(DOCUMENT_TYPE_LABEL)}
                                                        value={mapTypeCodeToObject(field.value, documentTypes)}
                                                        onChange={(e) => { setValue(DOCUMENT_TYPE, e.target.value[CODE]); }}
                                                    >
                                                        {documentTypes.map((docType) => (
                                                            <MenuItem key={docType[CODE]} value={docType}>
                                                                {docType[NAME]}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <FormHelperText id={DOCUMENT_TYPE + '-helper'}
                                                        error={errors[DOCUMENT_TYPE] ? true : false}>
                                                        {errors[DOCUMENT_TYPE] ? errors[DOCUMENT_TYPE].message : null}
                                                    </FormHelperText>
                                                </FormControl>
                                            }
                                        />
                                    </Box>

                                    <Box>
                                        <Controller
                                            name={DOCUMENT_NUMBER}
                                            render={({ field }) =>
                                                <TextField
                                                    id={DOCUMENT_NUMBER}
                                                    label={t(DOCUMENT_CODE_LABEL)}
                                                    value={field.value}
                                                    onChange={(e) => onChangeField(e.target.value, DOCUMENT_NUMBER)}
                                                    error={errors[DOCUMENT_NUMBER] ? true : false}
                                                    helperText={errors[DOCUMENT_NUMBER] ? errors[DOCUMENT_NUMBER].message : null} />
                                            }
                                            control={control}
                                            defaultValue=""
                                        />
                                    </Box>
                                    {isAdmin &&
                                        <Box>
                                            <Controller
                                                name={PAYMENT_CATEGORY}
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) =>
                                                    <FormControl className={classes.formControl}>
                                                        <InputLabel id={PAYMENT_CATEGORY + '-label'}>{t(PAYMENT_CATEGORY_LABEL)}</InputLabel>
                                                        <Select
                                                            id={PAYMENT_CATEGORY}
                                                            label={t(PAYMENT_CATEGORY_LABEL)}
                                                            value={mapTypeCodeToObject(field.value, paymentCategories)}
                                                            onChange={(e) => { setValue(PAYMENT_CATEGORY, e.target.value[CODE]); }}
                                                        >
                                                            {paymentCategories.map((paymentCategory) => (
                                                                <MenuItem key={paymentCategory[CODE]} value={paymentCategory}>
                                                                    {paymentCategory[NAME]}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        <FormHelperText id={PAYMENT_CATEGORY + '-helper'}
                                                            error={errors[PAYMENT_CATEGORY] ? true : false}>
                                                            {errors[PAYMENT_CATEGORY] ? errors[PAYMENT_CATEGORY].message : null}
                                                        </FormHelperText>
                                                    </FormControl>
                                                }
                                            />
                                        </Box>
                                    }
                                    {isAdmin &&
                                        <Box>
                                            <Controller
                                                name={OFFERED_SERVICE_TYPE}
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) =>
                                                    <FormControl className={classes.formControl}>
                                                        <InputLabel id={OFFERED_SERVICE_TYPE + '-label'}>{t(SERVICE_TYPE_LABEL)}</InputLabel>
                                                        <Select
                                                            id={OFFERED_SERVICE_TYPE}
                                                            label={t(SERVICE_TYPE_LABEL)}
                                                            value={field.value ?? ""}
                                                            onChange={(e) => { setValue(OFFERED_SERVICE_TYPE, e.target.value); }}
                                                            error={errors[OFFERED_SERVICE_TYPE] ? true : false}
                                                        >
                                                            {serviceTypes.map((serviceType) => serviceType[NAME]).map(item => (
                                                                <MenuItem key={item} value={item}>
                                                                    {item}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        <FormHelperText id={OFFERED_SERVICE_TYPE + '-helper'}
                                                            error={errors[OFFERED_SERVICE_TYPE] ? true : false}>
                                                            {errors[OFFERED_SERVICE_TYPE] ? errors[OFFERED_SERVICE_TYPE].message : null}
                                                        </FormHelperText>
                                                    </FormControl>
                                                }
                                            />
                                        </Box>
                                    }
                                    {isAdmin &&
                                        <Box>
                                            <Controller
                                                name={ARRIVAL_ORGANIZATION}
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) =>
                                                    <FormControl className={classes.formControl}>
                                                        <InputLabel id={ARRIVAL_ORGANIZATION + '-label'}>{t(TRAVEL_ARRANGEMENTS_LABEL)}</InputLabel>
                                                        <Select
                                                            id={ARRIVAL_ORGANIZATION}
                                                            label={t(TRAVEL_ARRANGEMENTS_LABEL)}
                                                            value={mapTypeCodeMIToObject(field.value, arrivalOrganisations)}
                                                            onChange={(e) => { setValue(ARRIVAL_ORGANIZATION, e.target.value[CODE_MI], true); }}
                                                            error={errors[ARRIVAL_ORGANIZATION] ? true : false}
                                                        >
                                                            {arrivalOrganisations.map((arrivalOrganisation) => (
                                                                <MenuItem key={arrivalOrganisation[CODE_MI]} value={arrivalOrganisation}>
                                                                    {arrivalOrganisation[NAME]}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        <FormHelperText id={ARRIVAL_ORGANIZATION + '-helper'}
                                                            error={errors[ARRIVAL_ORGANIZATION] ? true : false}>
                                                            {errors[ARRIVAL_ORGANIZATION] ? errors[ARRIVAL_ORGANIZATION].message : null}
                                                        </FormHelperText>
                                                    </FormControl>
                                                }
                                            />
                                        </Box>
                                    }
                                    <Backdrop className={classes.backdrop} open={openProgress} onClick={handleProgressToggle}>
                                        <CircularProgress color="inherit" />
                                    </Backdrop>
                                    <AlertDialog title={t(ALERT_DIALOG_TITLE)} message="" open={openErrorDialog} handleOpen={setOpenErrorDialog} />
                                    <CustomizedSnackbar message={generateMessage()} isError={apiErrors} open={openMessage} handleOpen={setOpenMessage} timeout={API_MESSAGE_TIMEOUT} />
                                </div>
                            </form>
                        </MuiPickersUtilsProvider>
                    </div>
                </Paper>
                <ConfirmWithCheckboxDialog
                    title={t(CONFIRM_GUEST_TITLE)}
                    open={confirmGuestOpen}
                    setOpen={setConfirmGuestOpen}
                    onConfirm={preCommitHook}>
                    {t(GUEST_EDIT_WARNING)}
                </ConfirmWithCheckboxDialog>
                <GuestBottomBar />
            </div>
        );
    }
    else return (
        <div className={classes.root}>
            <Typography variant="h6" color="inherit" noWrap>
                {DOWNLOAD_IN_PROGRESS}
            </Typography>
            <CustomizedSnackbar message={generateMessage()} isError={apiErrors} open={openMessage} handleOpen={setOpenMessage} timeout={API_MESSAGE_TIMEOUT} />
        </div>
    );
}

export default SharedGuest;