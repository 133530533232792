import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import GroupIcon from '@material-ui/icons/Group';
import Divider from '@material-ui/core/Divider';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SettingsIcon from '@material-ui/icons/Settings';
import { Link } from 'react-router-dom';
import { clearUserData } from '../../helpers/Utils';
import {
    STORAGE_USER, CHECKINS, TOURISTS,
    SETTINGS, HELP, EVACCOUNTS, LOGOUT
} from '../../constants/strings-and-fields';

export const sideList = props => {
    const { classes, history, t } = props;
    const handleLogout = () => {
        clearUserData();
        history.replace('/login');
    }

    const userEmail = localStorage.getItem(STORAGE_USER);

    return <div className={classes.list}>
        <List>
            <ListItem button key={'Checkins'} to={'/'} component={Link}>
                <ListItemIcon><HomeWorkIcon /></ListItemIcon>
                <ListItemText primary={t(CHECKINS)} />
            </ListItem>
            <ListItem button key={'Tourists'} to={'/tourists'} component={Link} >
                <ListItemIcon><GroupIcon /></ListItemIcon>
                <ListItemText primary={t(TOURISTS)} />
            </ListItem>
            <ListItem button key={'eVaccounts'} to={'/ev-accounts'} component={Link} >
                <ListItemIcon><AccountBoxIcon /></ListItemIcon>
                <ListItemText primary={t(EVACCOUNTS)} />
            </ListItem>
            <Divider />
            <ListItem button key={'Settings'} to={'/settings'} component={Link} >
                <ListItemIcon><SettingsIcon /></ListItemIcon>
                <ListItemText primary={t(SETTINGS)} />
            </ListItem>
            <ListItem button key={'Help'}>
                <ListItemIcon><ContactSupportIcon /></ListItemIcon>
                <ListItemText primary={t(HELP)} />
            </ListItem>
            <ListItem button key={'Logout'} onClick={handleLogout}>
                <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                <ListItemText primary={t(LOGOUT)} />
            </ListItem>
            {userEmail &&
                <ListItem button key={'User'}>
                    <ListItemText primary={userEmail} />
                </ListItem>
            }
        </List>
    </div>
};