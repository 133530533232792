import { TextField, InputAdornment } from "@material-ui/core";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DateRangeIcon from '@material-ui/icons/DateRange';

export const CustomizedTimeTextField = (classes) => props =>
(
    <TextField
        className={classes.datePicker}
        {...props}
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    {<AccessTimeIcon />}
                </InputAdornment>
            )
        }}
    />
);

export const CustomizedDateTextField = () => props =>
(
    <TextField
        {...props}
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    {<DateRangeIcon />}
                </InputAdornment>
            )
        }}
    />
);