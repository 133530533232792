import React, { useEffect, useState } from 'react';
import useStyles from './CheckIns.styles';
import { useWindowDimensions } from '../../wrappers/withWindowDimensions';
import { VariableSizeList as List } from 'react-window';
import CheckinItem from '../../lists/CheckinItem';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import TwoStepChooserDialog from '../../components/TwoStepChooserDialog';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {
    ADDITIONAL_FIELDS, ADD_CHECKIN, CHECKINS_LABEL,
    DOWNLOAD_IN_PROGRESS, EVISITOR, PIN_ID, ALL, STORAGE_CHOSEN_EV_ACCOUNT, FACILITY_FIELD, NAME
} from '../../../constants/strings-and-fields';
import { getNumberOfGuests } from '../../../helpers/ArrayHelpers';
import CustomizedSnackbar from '../../components/Snackbar';
import { isLoggedIn } from './../../../helpers/Utils';
import PrimaryListSearchNavBar from '../../navbars/PrimaryListSearchNavBar';
import { useTranslation } from 'react-i18next';

const TOOLBAR_HEIGHT = 56;
const API_MESSAGE_TIMEOUT = 5000;

const CheckIns = props => {
    const classes = useStyles();
    const { history, evaccounts, checkins, apiErrors, apiMessages, eVisitorStaticData } = props;
    const { t } = useTranslation();
    const { windowHeight, windowWidth } = useWindowDimensions();
    const [openMessage, setOpenMessage] = useState(false);
    const [evAccount, setEvAccount] = useState(ALL);
    const [openFab, setOpenFab] = useState(false);
    const listWindow = windowHeight - TOOLBAR_HEIGHT;
    const listRef = React.createRef();
    let unlisten = null;

    const handleOpenFab = () => {
        setOpenFab(!openFab);
    };

    const generateMessage = () => {
        if (apiErrors) {
            return `${apiErrors?.signal}: ${apiErrors?.message}`
        }
        else if (apiMessages) {
            return `${apiMessages?.signal}: ${apiMessages?.message}`
        };
    }

    const triggerSearch = (text, evAccount) => {
        console.log("triggerSearch");
    }

    useEffect(() => {
        isLoggedIn().then(loggedIn => {
            if (!loggedIn) history.replace('/login');
        })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        unlisten = history.listen((location, action) => {
            if (action === 'POP') {
                history.replace('/');
            }
        });
        return () => { unlisten(); };
    });

    useEffect(() => {
        apiErrors && setOpenMessage(true);
        apiMessages && setOpenMessage(true);
    }, [apiMessages, apiErrors]);

    useEffect(() => {
        const chosenEvAccount = localStorage.getItem(STORAGE_CHOSEN_EV_ACCOUNT);
        if (chosenEvAccount) {
            const evAccountIndex = chosenEvAccount ? Number(chosenEvAccount) : 0;
            if (evAccountIndex !== -1) {
                evaccounts && setEvAccount(evaccounts[evAccountIndex]);
            }
        }
    }, [evaccounts]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (evaccounts && evAccount && evAccount !== ALL) {
            const evAccountIndex = evaccounts.findIndex((ev => ev[PIN_ID] === evAccount[PIN_ID]));
            localStorage.setItem(STORAGE_CHOSEN_EV_ACCOUNT, evAccountIndex);
        } else {
            localStorage.setItem(STORAGE_CHOSEN_EV_ACCOUNT, -1);
        }
    }, [evAccount]); // eslint-disable-line react-hooks/exhaustive-deps

    if (evaccounts && eVisitorStaticData) {
        const filteredCheckins = (evAccount === ALL || !evAccount) ? checkins : checkins.filter(checkin => checkin?.[ADDITIONAL_FIELDS]?.[EVISITOR]?.[PIN_ID] === evAccount?.[PIN_ID]);
        const getSize = i => {
            const name = filteredCheckins[i]?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD]?.[NAME];
            return (name && name.length > 40) ? 120 : 90;
        }
        return (
            <div className={classes.root}>
                <PrimaryListSearchNavBar history={history} headerTitle={CHECKINS_LABEL} evaccounts={evaccounts} setEvAccount={setEvAccount} evAccount={evAccount} triggerSearch={triggerSearch} showSearch={false} />
                <List className="List"
                    ref={listRef}
                    height={listWindow}
                    itemCount={filteredCheckins.length}
                    itemSize={getSize}
                    width={windowWidth}>
                    {({ index, style }) =>
                        <div style={style}>
                            <Divider />
                            <CheckinItem
                                checkin={filteredCheckins[index]}
                                checkinIndex={index}
                                history={history}
                                numberOfGuests={getNumberOfGuests(filteredCheckins[index])}
                            />
                            <Divider />
                        </div>
                    }
                </List>
                <Fab color="primary" variant="extended" className={classes.speedDial} aria-label="add" onClick={handleOpenFab}>
                    <AddIcon /> {t(ADD_CHECKIN)}
                </Fab>
                <TwoStepChooserDialog
                    history={history}
                    open={openFab}
                    handleOpen={handleOpenFab}
                    firstListData={evaccounts} />
                <CustomizedSnackbar message={generateMessage()} isError={apiErrors} open={openMessage} handleOpen={setOpenMessage} timeout={API_MESSAGE_TIMEOUT} />
            </div>
        );
    } else return (
        <div className={classes.root}>
            <PrimaryListSearchNavBar history={history} headerTitle={CHECKINS_LABEL} evaccounts={evaccounts} setEvAccount={setEvAccount} evAccount={evAccount} triggerSearch={triggerSearch} showSearch={false} />
            <Typography variant="h6" color="inherit" noWrap>
                {DOWNLOAD_IN_PROGRESS}
            </Typography>
            <Fab color="primary" variant="extended" className={classes.speedDial} aria-label="add" onClick={handleOpenFab}>
                <AddIcon /> {t(ADD_CHECKIN)}
            </Fab>
            <TwoStepChooserDialog
                history={history}
                open={openFab}
                handleOpen={handleOpenFab}
                firstListData={evaccounts} />
            <CustomizedSnackbar message={generateMessage()} isError={apiErrors} open={openMessage} handleOpen={setOpenMessage} timeout={API_MESSAGE_TIMEOUT} />
        </div>
    );
}

export default CheckIns;
