import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    formText: {
        margin: theme.spacing(0),
    },
    formControlFirstLabel: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    formControlFirstLabelDialog: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
}));

export default useStyles;