import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    iconButton: {
        marginLeft: '-16px',
    },
    formControl: {
        marginBottom: theme.spacing(1),
        minWidth: 120,
    },
    contentFrame: {
        maxWidth: '900px',
        margin: '0 auto',
        padding: '20px'
    },
    dlHorizontal: {
        fontSize: 0,
        textAlign: 'center',
        margin: '10px 0'
    },
    qrcode: {
        padding: '40px 0 20px',
        margin: '0 auto'
    },
    root: {
        '& h1': {
            color: '#e87722',
            margin: 0,
            fontSize: '30px',
            fontWeight: 'bold',
            paddingBottom: '10px'
        },
        '& h2': {
            margin: 0,
            backgroundColor: '#fbead9',
            lineHeight: '40px',
            fontSize: '18px',
            fontWeight: 'normal',
            paddingLeft: '15px'
        },
        '& dt, dd': {
            fontSize: '1rem',
            display: 'inline-block',
            margin: 0,
            verticalAlign: 'top'
        },
        '& dt': {
            textAlign: 'right',
            paddingRight: '5px',
            fontWeight: 'normal',
            width: 'calc(30% - 5px)'
        },
        '& dd': {
            textAlign: 'left',
            paddingLeft: '5px',
            fontWeight: 'bold',
            width: 'calc(70% - 5px)'
        },
        '& #qrcode': {
            padding: '40px 0 20px'
        },
        '& #qrcode img': {
            margin: '0 auto'
        }
    },
}));

export default useStyles;