import React from 'react';
import useStyles from './CheckinActionsDialog.styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import { CANCEL_ALL, CHECKIN_ACTIONS, CHECKOUT_ALL, CHECKIN_ALL, CANCEL_TEXT } from '../../constants/strings-and-fields';
import { useTranslation } from 'react-i18next';

const CheckinActionsDialog = (props) => {
    const classes = useStyles();
    const { onClose, selectedCheckinActions, actionsCheckinCount = null, actionsCheckoutCount = null, actionsCancelCount = null, open } = props;
    const { t } = useTranslation();
    const handleClose = () => {
        onClose();
    };

    const handleListItemClick = (value) => {
        onClose();
        selectedCheckinActions(value);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle >{t(CHECKIN_ACTIONS)}</DialogTitle>
            <List>
                {actionsCheckinCount &&
                    <ListItem button onClick={() => handleListItemClick(CHECKIN_ALL)} key={CHECKIN_ALL}>
                        <ListItemAvatar>
                            <Avatar className={classes.avatar}>
                                <LibraryAddCheckIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={`${t(CHECKIN_ALL)} (${actionsCheckinCount})`} />
                    </ListItem>
                }
                {actionsCheckoutCount &&
                    <ListItem button onClick={() => handleListItemClick(CHECKOUT_ALL)} key={CHECKOUT_ALL}>
                        <ListItemAvatar>
                            <Avatar className={classes.avatar}>
                                <FlightTakeoffIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={`${t(CHECKOUT_ALL)} (${actionsCheckoutCount})`} />
                    </ListItem>
                }
                {actionsCancelCount &&
                    <ListItem button onClick={() => handleListItemClick(CANCEL_ALL)} key={CANCEL_ALL}>
                        <ListItemAvatar>
                            <Avatar className={classes.avatar}>
                                <CancelPresentationIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={`${t(CANCEL_ALL)} (${actionsCancelCount})`} secondary={t(CANCEL_TEXT)}/>
                    </ListItem>
                }
            </List>
        </Dialog>
    );
}

export default CheckinActionsDialog;