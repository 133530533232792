import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    iconButton: {
        marginLeft: '-16px',
    },
    formControl: {
        marginBottom: theme.spacing(1),
        minWidth: 120,
    },
}));

export default useStyles;